import React, { useEffect, useState } from 'react';

import { FormSwitch, FormTextArea, FormInput, FormCheck, FormRadio } from './';

interface IProps {
  inputRef: any;
  info?: string;
  control: any;
  name: string;
  refGenerator?: any;
  placeholder?: string;
  setValue: any;
  getValues: any;
  defaultValue: any;
  fields?: { name: string; placeholder: string; label: string; type: string }[];
  errors?: any;
  constraints?: any;
  [key: string]: any;
}

export function FormMultiRowInput(props: IProps) {
  const [inputBoxes, setInputBoxes] = useState<any[]>([]);

  const { control, constraints, fields, name, refGenerator, defaultValue } = props;

  useEffect(() => {
    const refGenerated = refGenerator(constraints);
    setInputBoxes([refGenerated]);
  }, []);

  const addRow = () => {
    const refGenerated = refGenerator(constraints);
    setInputBoxes([...inputBoxes, refGenerated]);
  };

  const removeRow = (i: number) => {
    const fewerInputBoxes = inputBoxes.filter((_, idx) => i !== idx);
    setInputBoxes(fewerInputBoxes);
  };

  if (!fields) return null;

  return (
    <div className="w-full">
      <hr className="mb-2" />
      {inputBoxes.map((inputRef: any, i: number) => (
        <React.Fragment>
          <div className="flex flex-row mt-2">
            {fields ? (
              <div className="flex flex-col w-full">
                {fields.map(({ name: subname, placeholder, type, label }) => {
                  const multiProps = {
                    inputRef,
                    name: `${name}-${i}-${subname}`,
                    placeholder: placeholder,
                    defaultValue: defaultValue && defaultValue[i],
                    label,
                    refGenerator,
                  };
                  return (
                    <React.Fragment>
                      <h5 className="font-semibold mb-2 mt-4">{label}</h5>
                      {(() => {
                        if (type === 'input') return <FormInput {...props} {...multiProps} />;
                        if (type === 'textarea') return <FormTextArea {...props} {...multiProps} />;
                        if (type === 'radio') return <FormRadio {...props} {...multiProps} />;
                        if (type === 'check') return <FormCheck {...props} {...multiProps} />;
                        if (type === 'switch') return <FormSwitch {...props} {...multiProps} control={control} />;
                        if (type === 'multiinput') return <FormMultiRowInput {...props} {...multiProps} />;
                        return null;
                      })()}
                    </React.Fragment>
                  );
                })}
                {inputBoxes.length > 1 && (
                  <div className="flex justify-between items-center mt-4">
                    <div />
                    <button
                      type="button"
                      tabIndex={-1}
                      onClick={() => {
                        removeRow(i);
                      }}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <hr className="mb-2" />
        </React.Fragment>
      ))}
      <div className="mt-2 flex justify-between items-center">
        <div />
        <button type="button" className="button mt-2" onClick={addRow}>
          Add another
        </button>
      </div>
    </div>
  );
}

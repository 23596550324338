import { SimpleTable } from 'components/Table/simple-table';
import { FilterByProps, TitleMenu } from 'components/UI';
import { TableColumn } from 'react-data-table-component';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { requestJSON } from 'utils/request';
import { SUBMISSIONS_CREATE_PATH, TA_BACKEND_URL } from 'constants/app';
import { PRIMARY_BACKGROUND } from 'constants/colors';
import Modal from 'react-modal';
import Spinner from 'fragments/Spinner';
import Error from 'components/Error';
import moment from 'moment';
import { Action } from 'components/Table/Actions';
import { FaCopy, FaEdit, FaUsers } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { EModalTypes, TSubmission } from 'types';
import SubmissionForm from './SubmissionForm';

type RouteParams = {
    courseId: string;
};

const Submissions = () => {
    const [submissionList, setSubmissionList] = useState<{ title: string; description: string; total_marks: number; discussion_url: string; start_date: Date | string; end_date: Date | string }[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageNum, setPageNum] = useState<number>(0);
    const [editedRow, setEditedRow] = useState<TSubmission>();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [modalTypeOpen, setModalTypeOpen] = useState<EModalTypes | null>(null);
    const [error, setError] = useState<string>('');
    const [courseList, setCourseList] = useState<{ id: string; shownValue: string }[]>([]);
    const [selectedCourse, setSelectedCourse] = useState<string>('');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const { courseId } = useParams<RouteParams>();

    const history = useHistory();
    const headerLinks = [{ linkName: 'Create New Assignment', linkUrl: SUBMISSIONS_CREATE_PATH }];
    const getSubmission = async () => {
        const response = await requestJSON(`${TA_BACKEND_URL}/submissions?pageNum=${pageNum}&course=${selectedCourse}&courseId=${courseId || ''}`, {
            credentials: 'header',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
        });
        if (!response.success) {
            setLoading(false);
            setSubmissionList([]);
            toast.error('Unable to fetch Topics list.');
            return setError(response.error);
        }
        if (response.data) {
            setSubmissionList((response.data as any).data.data);
            setTotalCount((response.data as any).data.count);
        } else {
            toast.error('Unable to fetch Topics list.');
        }
        setLoading(false);
    };
    const getCourses = async () => {
        const response = await requestJSON(`${TA_BACKEND_URL}/courses-list`, {
            credentials: 'header',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
        });
        if (!response.success) {
            setLoading(false);
            toast.error('Unable to fetch Topics list.');
            return setError(response.error);
        }
        if (response.data) {
            const courses = (response.data.data as {id:string;name:string}[]).map(
            (course:{id:string;name:string})=>{return{id:course.id,shownValue:course.name};});
            setCourseList(courses);
            setSubmissionList((response.data as any).data.data);
            setTotalCount((response.data as any).data.count);
        } else {
            toast.error('Unable to fetch Topics list.');
        }
        setLoading(false);
    };
    useEffect(() => { getCourses(); }, []);
    useEffect(() => { getSubmission(); }, [pageNum,selectedCourse]);
    const columns: TableColumn<any>[] = [
        {
            name: 'Title',
            selector: 'title',
            sortable: true,
        },
        {
            name: 'Total Marks',
            selector: 'total_marks',
            sortable: true,
        },
        {
            name: 'Discussion Url',
            selector: 'discussion_url',
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: 'start_date',
            sortable: true,
            cell: row => {
                return <div>{row.start_date == null ? '-' : moment(row.start_date).format('DD-MM-YY')}</div>;
            }
        },
        {
            name: 'End Date',
            selector: 'end_date',
            sortable: true,
            cell: row => {
                return <div>{row.end_date == null ? '-' : moment(row.end_date).format('DD-MM-YY')}</div>;
            }
        },
        {
            name: 'Action',
            cell: row => {
                return (
                    <div className="flex flex-row space-x-2">
                        <Action iconType={FaEdit} onClick={() => toggleModal(row, EModalTypes.Edit)} tooltip="Edit Assignment" />
                        <Action iconType={FaCopy} onClick={() => toggleModal(row, EModalTypes.Duplicate)} tooltip="Duplicate Assignment" />
                        <Action
                            iconType={FaUsers}
                            onClick={() => history.push(`/student-assignment/${row.id}`)}
                            tooltip="View Student Assignments"
                        />
                    </div>
                );
            },
        }
    ];
    const onPageChange = (page: number) => {
        setPageNum(page);
    };
    const handleRowUpdate = async (data: TSubmission) => {
        setLoading(true);

        const body = JSON.stringify({
            data: data,
            submission_id: editedRow?.id,
        });

        const response = await requestJSON(`${TA_BACKEND_URL}/submissions`, {
            credentials: 'header',
            body: body,
            method: 'PATCH',
            headers: { 'content-type': 'application/json' },
        });
        setLoading(false);

        if (!response.success) {
            toast.error('Unable to Update Assignment');
            return setError(response.error);
        }

        toast.success('Assignment Updated!');
        getSubmission();
    };
    const closeAllModals = () => {
        setModalTypeOpen(null);
    };
    const createDuplicateHandler = async (formData: TSubmission) => {
        setSubmitting(true);
        const sendData = { data: { ...formData } };
        const body = JSON.stringify({ ...sendData });

        const response = await requestJSON(`${TA_BACKEND_URL}/submissions`, {
            credentials: 'header',
            body: body,
            method: 'POST',
            headers: { 'content-type': 'application/json' },
        });

        if (!response || !response.success) {
            setSubmitting(false);
            toast.error('Error: Unable to create Assignment');
            return setError('Unable to create Assignment');
        }
        setSubmitting(false);
        toast.success('Assignment Created!');
        getSubmission();
        closeAllModals();
    };
    const toggleModal = (row: TSubmission, action: string) => {
        setEditedRow(row);
        switch (action) {
            case EModalTypes.Edit:
                setModalTypeOpen(EModalTypes.Edit);
                break;
            case EModalTypes.Duplicate:
                setModalTypeOpen(EModalTypes.Duplicate);
                break;
            case EModalTypes.Activate:
                setModalTypeOpen(EModalTypes.Activate);
                break;
            case EModalTypes.Deactivate:
                setModalTypeOpen(EModalTypes.Deactivate);
                break;
            default:
                break;
        }
    };
    const onFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPageNum(1);
        setSelectedCourse(e.target.value);
    };
    if (error) return <Error embed preset="nothing" text={error} />;
    return (
        <div className="content">
            <div>
                <TitleMenu title="Assignments" links={headerLinks} />
            </div>
           {!courseId && <FilterByProps label={'course'} data={courseList} filterHandler={onFilterChange}/>}
            <SimpleTable
                columns={columns}
                data={submissionList}
                progressPending={loading}
                pagination={true}
                paginationServer={true}
                paginationTotalRows={totalCount}
                paginationPerPage={20}
                noRowsPerPage={true}
                onChangePage={onPageChange}
            />
            <Modal
                isOpen={modalTypeOpen == EModalTypes.Edit}
                onRequestClose={closeAllModals}
                style={{
                    overlay: {
                        zIndex: 99999,
                        backgroundColor: 'rgba(5, 10, 15, 0.9)',
                    },
                    content: {
                        zIndex: 32,
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        overflow: 'scroll',
                        backgroundColor: PRIMARY_BACKGROUND,
                        height: '70%',
                        width: '90%',
                    },
                }}
            >
                <div>
                    <p className="text-xl">Edit Assignment</p>
                    <SubmissionForm editedRow={editedRow} onUpdate={handleRowUpdate} closeModal={closeAllModals} type="update"></SubmissionForm>
                </div>
            </Modal>
            <Modal
                isOpen={modalTypeOpen == EModalTypes.Duplicate}
                onRequestClose={closeAllModals}
                style={{
                    overlay: {
                        zIndex: 2000,
                        backgroundColor: 'rgba(5, 10, 15, 0.9)',
                    },
                    content: {
                        zIndex: 32,
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        overflow: 'scroll',
                        backgroundColor: PRIMARY_BACKGROUND,
                        height: '70%',
                        width: '90%',
                    },
                }}
            >
                <div>
                    <p className="text-xl">Duplicate Assignment</p>
                    <SubmissionForm
                        editedRow={editedRow}
                        onUpdate={createDuplicateHandler}
                        closeModal={closeAllModals}
                        type="duplicate"
                    ></SubmissionForm>
                </div>
            </Modal>
        </div>
    );
};
export default Submissions;
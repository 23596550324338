import { TFormFields } from 'types';

const getError = (key: string, value: number | { value: number; message: string }) => {
  // if (key === 'required') return 'Required';
  // if (key === 'maxLength') return `Maximum of ${value} characters`;
  // if (key === 'minLength') return `Minimum of ${value} characters`;
  switch (key) {
    case 'required':
      return 'Required';
    case 'maxLength':
      return `Maximum of ${value} characters`;
    case 'minLength':
      return `Minimum of ${value} characters`;
    case 'min':
      return `Value cannot be less than ${value}`;
    case 'max':
      return `Value cannot exceed ${value}`;
    default:
      break;
  }
};

const processConstraints = (key: string, value: number | { value: number; message: string }) => {
  // bound with message { value: number; message: string; }
  if (typeof value === 'object' || typeof value === 'function') return value;

  // no message - generate
  return { value, message: getError(key, value) };
};

export function preProcessFormField(f: any) {
  if (f.constraints)
    for (const constraintKey in f.constraints)
      f.constraints[`${constraintKey}`] = processConstraints(constraintKey, f.constraints[constraintKey]);
  return f;
}

export function preProcessForm(formFields: TFormFields[]) {
  return formFields.map(preProcessFormField);
}

export function postProcessForm(formFields: TFormFields[], data: { [key: string]: any }) {
  const dataKeys = Object.keys(data);

  for (const formField of formFields)
    if (formField.type === 'multiinput') {
      // example: questionname-arrayindex-value
      const multiInputRegex = /(?:.*)-(.*)-(.*)/;
      if (dataKeys.some(k => k.startsWith(formField.name))) {
        const questionVals = dataKeys.filter(k => k.startsWith(formField.name));
        data[`${formField.name}`] = [];
        for (const qv of questionVals) {
          const maybeMatch = qv.match(multiInputRegex);
          if (!maybeMatch) continue;

          const [_, index, subfield] = maybeMatch;
          if (!data[`${formField.name}`][index]) data[`${formField.name}`][index] = {};
          data[`${formField.name}`][index][subfield] = data[`${qv}`];

          delete data[`${qv}`];
        }
      }
    }

  return data;
}

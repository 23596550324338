import React from 'react';
import { AtomicBlockUtils, EditorState } from 'draft-js';
import { Map } from 'immutable';

interface IProps {
  onChange: Function;
  editorState: EditorState;
  setliveTeXEdits: Function;
}

export default function AddMath(props: IProps) {
  const { editorState, onChange, setliveTeXEdits } = props;

  const addMath = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('TEX', 'IMMUTABLE', { content: ' ' });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    onChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
    setliveTeXEdits(Map());
  };

  return (
    <div onClick={addMath} className="p-2 cursor-pointer">
      Σ
    </div>
  );
}

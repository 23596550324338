import React from 'react';

import { ERROR } from 'constants/colors';

type IProps = {
  children?: React.ReactNode;
  Icon?: any;
  label: string | React.ReactNode;
  info?: string;
  error?: string;
  className?: string;
  FormComponent?: any;
  placeholder?: string;
};

type ErrorProps = {
  error?: string;
  className?: string;
  Icon?: any;
};

export function ErrorLine(props: ErrorProps) {
  const { className, error, Icon } = props;

  return (
    <div className={`text-red-500 text-sm ${className}`}>
      {error && Icon && <Icon color={ERROR} className="inline mr-2" />}
      {error}
    </div>
  );
}

export function FormComponentHolder(props: IProps) {
  const { FormComponent, className, error, info, label, children, Icon } = props;
  let showImageUpload;
  if (
    props.placeholder ===
    'KNN regression | The Essence of Machine Learning |Data Visualization | Tabular Data | Free courses | QuizBooks and more'
  )
    showImageUpload = false;
  return (
    <div className={`px-4 py-2 border-gray-100 w-full ${className}`}>
      <h5 className="flex items-center">
        {Icon && <Icon size="1.35rem" />}
        {label}
      </h5>
      {info && <div className="text-sm leading-5 text-gray-600" dangerouslySetInnerHTML={{ __html: info }}></div>}
      <div className="mt-2">
        {FormComponent ? <FormComponent {...props} showImageUpload={showImageUpload} /> : children}
      </div>
      <ErrorLine error={error} />
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { TProcessedFormField } from 'types';
import Spinner from 'fragments/Spinner';
import { postProcessForm, FormTextArea, FormInput, FormCheck, FormRadio, FormMultiRowInput, Date } from './';
import { FormComponentHolder } from './FormComponentHolder';
import { FormDropdown } from './Dropdown';
import { FormFile } from './File';
import { normalizeValues } from 'utils/helper';
import { FormRichText } from './RichText';

interface IProps {
  cancel?: () => void;
  postFn?: (data: any) => void;
  dataFn?: (data: any) => void;
  processedFields: TProcessedFormField[];
  defaultValues?: { [key: string]: any };
}

export default function (props: IProps) {
  const { processedFields, dataFn, postFn, defaultValues, cancel } = props;
  const [submitting, setsubmitting] = useState<boolean>(false);
  const { control, register, handleSubmit, getValues, setValue, errors, watch } = useForm();
  const formValues = watch();
  const history = useHistory();

  const processForm = async () => {
    if (!postFn) return;
    setsubmitting(true);
    const processedData = postProcessForm(processedFields, formValues);
    await postFn(processedData);
    setsubmitting(false);
  };

  useEffect(() => {
    if (!dataFn) return;
    const processedData = postProcessForm(processedFields, formValues);
    dataFn(processedData);
  }, [formValues]);

  const inputParams = processedFields.map(f => ({
    ...f,
    errors,
    error: errors[`${f.name}`] && errors[`${f.name}`].message,
    inputRef: f.constraints ? register(f.constraints) : register(),
    refGenerator: register,
    setValue,
    control,
    rules: f.constraints,
    getValues,
    formValues,
    defaultValue: defaultValues && defaultValues[`${f.name}`],
  }));

  return (
    <form onSubmit={postFn && handleSubmit(processForm)} className="w-full max-w-screen-md">
      <div className="flex flex-col flex-wrap">
        {inputParams.map(ip => {
          const holderProps = ip;

          if (ip.dependentOn) {
            for (const dependency of ip.dependentOn) {
              if (defaultValues)
                if (
                  ip.dependentOn &&
                  formValues[dependency.field] &&
                  formValues[dependency.field] != dependency.value &&
                  !(Array.isArray(dependency.value) && dependency.value.includes(formValues[dependency.field]))
                ) {
                  return null;
                } else if (
                  !formValues[dependency.field] &&
                  defaultValues &&
                  normalizeValues(defaultValues[dependency.field], ip.type) != dependency.value
                ) {
                  return null;
                }
            }
          }
          return (
            <div key={ip.name} className="w-full">
              <FormComponentHolder {...holderProps}>
                {(() => {
                  if (ip.type === 'input') return <FormInput {...ip} />;
                  if (ip.type === 'textarea') return <FormTextArea {...ip} />;
                  if (ip.type === 'radio') return <FormRadio {...ip} />;
                  if (ip.type === 'check') return <FormCheck {...ip} />;
                  if (ip.type === 'dropdown') return <FormDropdown {...ip} />;
                  if (ip.type === 'file') return <FormFile {...ip} />;
                  if (ip.type === 'richtext')
                    return <FormRichText {...ip} setMarkdownValue={(a: string) => a} value={ip.defaultValue} />;
                  if (ip.type === 'date') return <Date {...ip} control={control} />;
                  if (ip.type === 'multiinput') return <FormMultiRowInput {...ip} control={control} />;

                  return null;
                })()}
              </FormComponentHolder>
            </div>
          );
        })}
      </div>
      {postFn && (
        <div className="flex items-center justify-between mt-8">
          <button disabled={submitting} type="button" className="button secondary" onClick={cancel || history.goBack}>
            Cancel
          </button>
          <button type="submit" className="button">
            {submitting ? <Spinner size="tiny" /> : null} Done
          </button>
        </div>
      )}
    </form>
  );
}

import React, { ChangeEventHandler } from 'react';

type Props = {
  inputRef: React.Ref<HTMLInputElement>;
  name: string;
  placeholder?: string;
  defaultValue: string;
  error?: string;
  disabled?: boolean;
  changeHandler?: ChangeEventHandler<HTMLInputElement>;
};

export function FormInput(props: Props) {
  const { defaultValue, error, name, placeholder, inputRef, disabled, changeHandler } = props;
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeHandler && changeHandler(e);
  };
  return (
    <input
      className={`input ${error ? 'is-danger' : ''}`}
      name={name}
      ref={inputRef}
      placeholder={placeholder}
      type="text"
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={e => onChange(e)}
    />
  );
}

/** @jsxImportSource @emotion/react */
import React, { ChangeEventHandler, useState } from 'react';
import { css } from '@emotion/react';

type TProps = {
  Icon?: any;
  inputRef: any;
  refGenerator: any;
  label: string;
  defaultValue?: string | number;
  info?: string;
  name: string;
  options?: { id: number | string; shownValue: string }[];
  // options?: { [key: string]: any }[];
  placeholder?: string;
  error?: string;
  errors?: { [key: string]: string };
  defaultOtherValue?: string;
  onChangeHandler?: ChangeEventHandler;
  shownValue?: string;
};
export function FormDropdown(props: TProps) {
  // const { onChangeHandler, shownValue = 'shownValue', defaultValue, errors, name, options, inputRef } = props;
  const { onChangeHandler, defaultValue, errors, name, options, inputRef } = props;
  if (!options) return null;
  const [selectedOption, setSelectedOption] = useState<string | number>(defaultValue as string | number);
  const dropdownError = errors !== undefined && errors[`${name}`];
  const error = dropdownError;
  const opts = [...options];

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selected_class = e.target.value;
    // if (onChangeHandler) onChangeHandler(selected_class);
    if (onChangeHandler) onChangeHandler(e);
    setSelectedOption(selected_class);
  };

  return (
    <div className="control column is-6">
      <div className="select">
        <label className={`radio ${error && 'is-danger'}`}>
          <select
            onChange={onChange}
            ref={inputRef}
            name={name}
            css={css`min-width:20em;`}
            defaultValue={selectedOption}
          >
            {opts.map((option: { id: number | string; shownValue: string }) => {
              return (
                <option key={option.id} value={option.id}>
                  {option['shownValue']}
                </option>
              );
            })}
          </select>
        </label>
      </div>
    </div>
  );
}

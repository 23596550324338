import React from 'react';
import { ContentBlock, ContentState, EditorState } from 'draft-js';

import TexBlock from './Tex';

interface IProps {
  block: ContentBlock;
  editorState: EditorState;
  removeBlock: any;
  liveTeXEdits: any;
  setliveTeXEdits: any;
  setEditorState: any;
}

export default function blockRenderer(props: IProps) {
  const { block, removeBlock, editorState, setEditorState, liveTeXEdits, setliveTeXEdits } = props;

  if (block.getType() === 'atomic') {
    const contentState = editorState.getCurrentContent();
    const entity = contentState.getEntity(block.getEntityAt(0));
    const entityType = entity.getType();
    const entityData = entity.getData();

    if (entityType === 'IMAGE')
      return {
        component: (props: { blockProps: { src: string; height: string; width: string } }) => (
          <img {...props.blockProps} />
        ),
        props: entityData,
        editable: false,
      };

    if (entityType === 'TEX')
      return {
        component: TexBlock,
        editable: false,
        props: {
          onStartEdit: (blockKey: string) => {
            setliveTeXEdits(liveTeXEdits.set(blockKey, true));
          },
          onFinishEdit: (blockKey: string, newContentState: ContentState) => {
            setEditorState(EditorState.createWithContent(newContentState));
            setliveTeXEdits(liveTeXEdits.remove(blockKey));
          },
          onRemoveBlock: (blockKey: string) => {
            removeBlock(blockKey);
            setliveTeXEdits(liveTeXEdits.remove(blockKey));
          },
        },
      };
  }

  return block;
}

import React from 'react';
import { Controller } from 'react-hook-form';

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-javascript';
import '../../styles/prism.css';

interface IProps {
  control: any;
  inputRef: any;
  rules: any;
  language: string;
  name: string;
  placeholder?: string;
  getValues: any;
  error?: string;
  value: string;
  setValue: (value: string) => void;
}

export function FormCode(props: IProps) {
  const { control, rules, name, language = 'python', setValue } = props;

  if (!setValue) return null;
  if (!language) return null;

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ onChange, value = '' }) => {
        return (
          <Editor
            highlight={code => highlight(code, languages[language], language)}
            padding={20}
            placeholder=">"
            preClassName={`language-${language} code-box-display`}
            textareaClassName={`language-${language} code-box-input`}
            value={value}
            onValueChange={onChange}
          />
        );
      }}
    />
  );
}

/** @jsxImportSource @emotion/react */
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { ActionIcon } from './actions-style';
type TProps = {
  onClick: Function;
  tooltip: string;
};
export function ActionCancel(props: TProps) {
  return (
    <div data-tooltip={props.tooltip}>
      <ActionIcon color="red">
        <FaTimes
          onClick={() => {
            props.onClick();
          }}
        ></FaTimes>
      </ActionIcon>
    </div>
  );
}

import React, { ChangeEventHandler, useState } from 'react';
import { FilterLookUpValues, FilterModuleDataType } from './types';

type FilterByModuleProps<T> = {
  label?: string;
  showAllData: boolean;
  filterLookUpValue: FilterLookUpValues;
  filterHandler: ChangeEventHandler<HTMLSelectElement>;
  data: FilterModuleDataType<T>;
};

export const FilterByModule = <T,>(props: FilterByModuleProps<T>) => {
  const { label, data, filterHandler, showAllData, filterLookUpValue } = props;
  const [selectedValue, setSelectedValue] = useState<string>();
  const changeEventHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
    filterHandler(e);
  };
  return (
    <div className="flex flex-col flex-grow mr-2">
      {label !== undefined && <p className="p-2">Filter by {label}</p>}
      <div>
        <select onChange={changeEventHandler} value={selectedValue}>
          {showAllData ? <option value="all">All</option> : <option value="none">None</option>}
          {data.map(d => (
            <option key={d.id} value={d.id}>
              {d[filterLookUpValue]}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { HELP_URL, getLoginURL } from 'constants/app';

export type IError = {
  emotext?: string;
  emoji?: string;
  text?: string | ReactElement;
  extrablock?: string | ReactElement;
  preset?: string;
  allowBack?: boolean;
  embed?: boolean;
};

const presets: { [key: string]: IError } = {
  broken: {
    emotext: 'Something is broken',
    emoji: '(╯°□°)╯︵ ┻━┻',
    text: (
      <p>
        Please let us know if this problem persists via our <Link to={HELP_URL}>Help page</Link>
      </p>
    ),
  },
  nothing: {
    emotext: 'No results found',
    emoji: '（>﹏< )彡',
  },
  login: {
    emotext: 'Login to see things here',
    emoji: '┃(・_┃',
    text: 'Content on this page is based on what you do. Log in to do more!',
    allowBack: true,
    extrablock: (
      <div className="mt-4 flex justify-center">
        <a className="button is-white is-medium" href={getLoginURL(window.location.href)}>
          Login / Register
        </a>
      </div>
    ),
  },
  dependentData: {
    emotext: 'Some data required to create this module is unavailable',
    emoji: '(╯°□°)╯︵ ┻━┻',
  },
};

export default function (props: IError) {
  const { preset, embed = false } = props;
  let { emotext, emoji, text, extrablock, allowBack = false } = props;

  const history = useHistory();

  if (preset) {
    const maybePreset = presets[preset];
    if (maybePreset) {
      if (!emotext && maybePreset.emotext !== undefined) emotext = maybePreset.emotext;
      if (!emoji && maybePreset.emoji !== undefined) emoji = maybePreset.emoji;
      if (!text && maybePreset.text !== undefined) text = maybePreset.text;
      if (maybePreset.extrablock !== undefined) extrablock = maybePreset.extrablock;
      if (maybePreset.allowBack !== undefined) allowBack = maybePreset.allowBack;
    }
  }

  return (
    <div className="w-full flex flex-col items-center justify-center" style={{ height: '65vh' }}>
      {/* <div className={`${embed ? 'm-4 md:w-3/4 px-4 py-1' : 'p-2'} w-full`}> */}
      <div className="flex flex-col items-center" style={{ gap: '1rem' }}>
        {/* <div className={`font-space ${embed ? 'mt-20' : 'ml-4'} w-1/5 md:w-auto`}> */}
        <h3 className="text-right font-lg">{emoji}</h3>
        <h3 className="text-brand">{emotext}</h3>
        <div className="text-xl">{text}</div>
        {extrablock}
        {!embed && allowBack && (
          <div className="pt-8 flex justify-center items-center w-full">
            <div onClick={history.goBack} className="button clear">
              <FaChevronLeft size={10} />
              <span className="ml-1">Go Back</span>
            </div>
          </div>
        )}
        {/* </div> */}
      </div>
    </div>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Editor } from 'react-draft-wysiwyg';
import imageCompression from 'browser-image-compression';
import { Map } from 'immutable';
import { ContentState, ContentBlock, EditorState, SelectionState, Modifier, RichUtils, convertToRaw } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import blockRenderer from './blockRenderer';
import removeBlock from './removeBlock';
import AddTex from './Tex/AddTex';
import { requestJSON } from 'utils/request';
import { TA_GET_STATIC_URL, SPACES_IMAGE_PATH, DOWNLOAD_PATH } from 'constants/app';
interface IProps {
  placeholder?: string;
  defaultValue?: string;
  setValue: (markdown: string) => void;
  error?: string;
}

type TUpFields =
  | 'key'
  | 'bucket'
  | 'X-Amz-Algorithm'
  | 'X-Amz-Credential'
  | 'X-Amz-Date'
  | 'Policy'
  | 'X-Amz-Signature';

export default function HookFormWrappedRichText(props: IProps) {
  const { defaultValue = '', error, placeholder, setValue } = props;
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(ContentState.createFromText(defaultValue.replace('\n\n', '\n')))
  );
  const [asyncRemoveBlock, setasyncRemoveBlock] = useState<string | null>(null);
  const [liveTeXEdits, setliveTeXEdits] = useState(Map());

  const uploadFile = async (file: File) => {
    if (!file) return;

    const spacesFilePath = `${SPACES_IMAGE_PATH}/${file.name}`;
    const options = { maxWidthOrHeight: 600 }; // scale image
    const compressedFile = (await imageCompression(file, options)) as File;

    const fileName = encodeURIComponent(spacesFilePath);
    const fileType = encodeURIComponent(file.type);
    const uploadURLResp = await requestJSON(`${TA_GET_STATIC_URL}?name=${fileName}&type=${fileType}`, {
      method: 'GET',
      credentials: 'header',
    });
    if (!uploadURLResp.success) return toast.error(uploadURLResp.error);

    const uploadParams: { url: { fields: { [key: string]: string }; url: string }; filePath: [] } = uploadURLResp.data
      .data as { url: { fields: { [key: string]: string }; url: string }; filePath: [] };
    if (!uploadParams) return toast.error('Upload failed');

    const { url, filePath } = uploadParams;
    if (!url || !filePath) return toast.error('Upload failed');

    const form = new FormData();
    (Object.keys(url.fields) as TUpFields[]).forEach(key => form.append(key, url.fields[key]));
    form.append('file', compressedFile);

    const resp = await fetch(url.url, { method: 'POST', body: form });
    if (resp.status !== 200 && resp.status !== 204) toast.error('Upload failed');
    const privateFilePath = `${DOWNLOAD_PATH}?path=${filePath}`;
    // format expected to render file
    return { data: { link: privateFilePath } };
  };

  const onBlur = () => {
    const contentState = editorState.getCurrentContent();
    const rawEditorState = EditorState.createWithContent(contentState);
    let rawContentState = rawEditorState.getCurrentContent();

    for (const block of rawContentState.getBlocksAsArray()) {
      const blockType = block.getType();
      if (blockType === 'unstyled') continue;

      const blockKey = block.getKey();
      const targetRange = new SelectionState({
        anchorKey: blockKey,
        anchorOffset: 0,
        focusKey: blockKey,
        focusOffset: block.getLength(),
      });

      const entityKey = block.getEntityAt(0);
      if (!entityKey) continue;
      const entity = contentState.getEntity(entityKey);
      const entityType = entity.getType();
      const entityData = entity.getData();
      if (entityType === 'TEX') {
        rawContentState = Modifier.replaceText(rawContentState, targetRange, entityData.content);
        rawContentState = Modifier.setBlockType(rawContentState, targetRange, 'unstyled');
      }
      if (entityType === 'IMAGE') {
        const mdImgTag = `![${entityData.alt || ''}](${entityData.src})`;
        rawContentState = Modifier.replaceText(rawContentState, targetRange, mdImgTag);
        rawContentState = Modifier.setBlockType(rawContentState, targetRange, 'unstyled');
      }
    }

    const rawObject = convertToRaw(rawContentState);
    //const markdownString = draftToMarkdown(rawObject, { preserveNewlines: true, escapeMarkdownCharacters: false }); //no-ts
    const markdownString = draftToMarkdown(rawObject, { preserveNewlines: true }); //no-ts
    setValue(markdownString);
  };

  const customBlockRenderFunc = useCallback(
    (block: ContentBlock) =>
      blockRenderer({
        block,
        editorState,
        liveTeXEdits,
        setEditorState,
        setliveTeXEdits,
        removeBlock: setasyncRemoveBlock,
      }),
    [editorState]
  );

  const handleReturn = (e: React.KeyboardEvent) => {
    if (e.shiftKey) return 'not-handled'; // classic new line (paragraph)

    // soft new line
    setEditorState(RichUtils.insertSoftNewline(editorState));
    return 'handled';
  };

  // unable to pass editorState to blockRenderer. find a way!
  useEffect(() => {
    if (!asyncRemoveBlock) return;
    setEditorState(removeBlock({ editorState, blockKey: asyncRemoveBlock }));
    setasyncRemoveBlock(null);
  }, [asyncRemoveBlock]);

  // resets values when form is reset externally by react-hook-form
  useEffect(() => {
    if (!defaultValue) setEditorState(EditorState.createEmpty());
  }, [defaultValue]);

  return (
    <div className="w-full">
      <div className="flex flex-row">
        <div className={`input ${error ? 'is-danger' : ''}`}>
          <Editor
            onBlur={onBlur}
            customBlockRenderFunc={customBlockRenderFunc}
            editorState={editorState}
            onEditorStateChange={setEditorState}
            toolbarOnFocus
            readOnly={liveTeXEdits.count() > 0}
            handleReturn={handleReturn}
            wrapperClassName="richeditor-wrapper"
            toolbarClassName="richeditor-toolbar"
            toolbar={{
              options: ['inline', 'list', 'colorPicker', 'link', 'emoji', 'image'],
              inline: { inDropdown: true },
              // blockType: { options: ['Code'] },
            }}
            toolbarCustomButtons={[
              <AddTex onChange={setEditorState} editorState={editorState} setliveTeXEdits={setliveTeXEdits} />,
            ]}
            placeholder={placeholder}
            stripPastedStyles
            uploadCallback={uploadFile as (file: object) => Promise<object>}
          />
        </div>
      </div>
    </div>
  );
}

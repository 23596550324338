import React from 'react';
import { Controller } from 'react-hook-form';

import RichTextEditor from './HookFormWrappedEditor';

interface IProps {
  control: any;
  inputRef: any;
  rules: any;
  name: string;
  placeholder?: string;
  getValues: any;
  error?: string;
  defVal?: string;
  value?: string;
  defaultValue?: string;
  setMarkdownValue: (markdown: string) => void;
}

export function FormRichText(props: IProps) {
  const { control, rules, name, ...editorProps } = props;
  const defaultValue = props.value || props.defVal || props.defaultValue;

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ onChange, value }) => {
        return <RichTextEditor {...editorProps} setValue={onChange} defaultValue={defaultValue} />;
      }}
    />
  );
}

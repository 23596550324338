import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { requestJSON } from 'utils/request';
import { SYNC_ALL_SERVERS_API } from 'constants/app';
import Modal from 'fragments/Modal';
import { FaCheck, FaTimes } from 'react-icons/fa';

export type linkDetails = {
  linkName: string;
  linkUrl: string;
};

type TProps = {
  title: string;
  links?: linkDetails[];
  goBack?: boolean;
};

export const TitleMenu = (props: TProps) => {
  const { title, links, goBack = false } = props;
  const [syncing, setSyncing] = React.useState(false);
  const [syncRes, setSyncRes] = React.useState<{
    [key: string]: {
      exit: string;
      stdout: string;
      stderr: string;
    };
  }>();
  const [error, setError] = React.useState();

  const history = useHistory();
  const syncAllServers = async () => {
    const confirm = window.confirm(
      'Are you sure you want to sync all servers? Latest changes of all notebooks would be synced to all servers.'
    );
    if (!confirm) return;
    setSyncing(true);
    const response = await requestJSON(SYNC_ALL_SERVERS_API, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      setSyncing(false);
      return setError(response.error);
    }

    if (response.data) {
      setSyncRes(response.data.data.serverResponse);
    }
    setSyncing(false);
  };
  return (
    <>
      <div className="flex items-center justify-between">
        <h3>{title}</h3>
        <div className="flex">
          {links &&
            links.length > 0 &&
            links.map((link: linkDetails) => (
              <Link key={link.linkName} className="button clear" to={link.linkUrl}>
                {link.linkName}
              </Link>
            ))}

          {title == 'COURSES' && (
            <button
              className="button"
              type="button"
              onClick={async () => {
                await syncAllServers();
              }}
              disabled={syncing ? true : false}
            >
              {syncing ? 'Syncing...' : 'Sync All'}
            </button>
          )}

          {goBack && (
            <button onClick={history.goBack} className="button clear">
              Go Back
            </button>
          )}
        </div>
      </div>
      <hr />
      <Modal
        isOpen={(syncRes && Object.keys(syncRes).length > 0) || error != undefined}
        close={() => {
          setSyncRes(undefined);
          setError(undefined);
        }}
      >
        <div className="flex flex-col items-center justify-center">
          {syncRes && (
            <>
              <h3>Sync Result</h3>
              {
                <table>
                  <thead>
                    <tr>
                      <th>Server</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(syncRes).map(server => {
                      // create a table with headers as server and status
                      return (
                        <tr>
                          <td>{server}</td>
                          <td>{syncRes[server].exit == '0' ? <FaCheck></FaCheck> : <FaTimes></FaTimes>}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              <p className=" absolute bottom-0 text-xs text-white select-none text-opacity-0">
                {JSON.stringify(syncRes)}
              </p>
            </>
          )}
          {error && (
            <>
              <h3>Error</h3>
              <p>{JSON.stringify(error)}</p>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

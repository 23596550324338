import React from 'react';
import { FaEdit } from 'react-icons/fa';

import { ActionIcon } from './actions-style';

type TProps = {
  onClick: Function;
  tooltip: string;
};

export function ActionEdit(props: TProps) {
  return (
    <div data-tooltip={props.tooltip}>
      <ActionIcon color="blue">
        <FaEdit
          onClick={() => {
            props.onClick();
            //toggleModal(row);
          }}
        ></FaEdit>
      </ActionIcon>
    </div>
  );
}

import { Action } from 'components/Table/Actions';
import { SimpleTable } from 'components/Table/simple-table';
import { TA_BACKEND_URL } from 'constants/app';
import Spinner from 'fragments/Spinner';
import React, { useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FaBan, FaTrashAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useStore from 'globalstate';
import { requestJSON } from 'utils/request';

type RouteParams = {
  courseId: string;
};
export const Students = () => {
  const [studentsList, setStudentsList] = useState([]);
  const [totalStudents, setTotalStudents] = useState<number>(1);
  const [pageNum, setPageNum] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { courseId } = useParams<RouteParams>();
  const [studentEmail, setStudentEmail] = useState<string>('');
  const [isStudentEmailValid, SetIsStudentEmailValid] = useState<boolean>(true);
  const [finalStudentList, setFinalStudentList] = useState([]);
  const [inviteeRole, setInviteeRole] = useState<string>('student');
  const [searchText, setSearchText] = useState<string>('');
  const regexExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [State] = useStore();
  const { isAdmin } = State;
  const onPageChange = (page: number) => {
    setPageNum(page);
  };
  const getStudents = async () => {
    setLoading(true);
    const response = await requestJSON(`${TA_BACKEND_URL}/course-students?courseId=${courseId}&pageNum=${pageNum}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      setLoading(false);
      setStudentsList([]);
      toast.error('Unable to fetch  list.');
    }
    if (response.success) {
      const fetchData = response.data.data as { data: [] };
      const fetchCount = response.data.data as any;
      setStudentsList(fetchData.data);
      setFinalStudentList(fetchData.data);
      setTotalStudents(+fetchCount.count);
    } else {
      toast.error('Unable to fetch  list.');
    }

    setLoading(false);
  };
  const searchHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageNum(1);
    const searchValue = e.target.value;
    setSearchText(searchValue);
  };
  useEffect(() => {
    const setFilter = async () => {
      if (searchText.length === 0) return setFinalStudentList(studentsList);
      if (searchText.length > 1) {
        const filteredList = await studentsList.filter((student: any) => {
          if (student.name.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
          }
          if (student.email.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
          }
          return false;
        });
        setLoading(true);
        if (filteredList.length == 0) setFinalStudentList([]);
        else setFinalStudentList(filteredList);
        setLoading(false);
      }
    };

    setFilter();
  }, [searchText]);
  const deleteStudents = async (id: number | string, role: string, email: string) => {
    const response = await requestJSON(
      `${TA_BACKEND_URL}/course-students?courseId=${courseId}&studentId=${id}&role=${role}&email=${email}`,
      {
        credentials: 'header',
        method: 'delete',
        headers: { 'content-type': 'application/json' },
      }
    );
    if (!response.success) {
      toast.error(response.error || 'Unable to delete student.');
    }
    getStudents();
  };
  const AddStudents = async () => {
    setLoading(true);
    const response = await requestJSON(`${TA_BACKEND_URL}/course-member`, {
      credentials: 'header',
      method: 'post',
      body: JSON.stringify({ data: { data: studentEmail, courseId: courseId, role: inviteeRole } }),
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      toast.error(response.error || 'Unable to invite user(s).');
      setError(response.error.results);
      setLoading(false);
      return;
    }
    toast.success(inviteeRole + ' Added Successfully');
    getStudents();
  };
  useEffect(() => {
    getStudents();
  }, []);
  useEffect(() => {
    if (regexExp.test(studentEmail) && studentEmail.length !== 0) {
      SetIsStudentEmailValid(false);
    } else {
      SetIsStudentEmailValid(true);
    }
  }, [studentEmail]);
  const emailValidityTableColumns: TableColumn[] = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
  ];
  const columns: TableColumn<any>[] = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Role',
      selector: 'role',
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row: any) => {
        if (!isAdmin && row.role === 'staff') {
          return <FaBan className="text-gray-400" />;
        }
        return (
          <div className="flex flex-row space-x-2">
            <Action
              iconType={FaTrashAlt}
              onClick={() => {
                deleteStudents(row.id, row.role, row.email);
              }}
              tooltip={'Remove ' + row.role}
            />
          </div>
        );
      },
    },
  ];
  const getNewInvitesData = () => {
    return studentEmail
      .split(';')
      .map((emailNameString: string) => {
        return {
          name: emailNameString.split(',')[0],
          email: emailNameString.split(',')[1],
        };
      })
      .filter(row => {
        return row.email && row.name;
      });
  };
  const conditionalRowStyles = [
    {
      when: (row: { email: string; name: string }) => {
        if (row.email)
          return studentsList.map((student: any) => student.email.replace('(Invited)', '')).includes(row.email.trim());
        return false;
      },
      style: {
        backgroundColor: 'rgba(253, 0, 0, 0.4)',
        color: 'white',
        '&:hover': {
          cursor: 'not-allowed',
        },
      },
    },
  ];
  return (
    <div>
      <div className="mt-4 grid grid-cols-4 gap-4">
        <div className="p-2 col-start-4 col-end-4">
          <input type="text" placeholder="Search" value={searchText} onChange={searchHandler}></input>
        </div>
      </div>

      <SimpleTable
        columns={columns}
        data={finalStudentList}
        progressPending={loading}
        pagination={true}
        paginationTotalRows={finalStudentList.length}
        paginationPerPage={20}
        noRowsPerPage={true}
      ></SimpleTable>

      <div className="flex flex-col">
        <h3>Invite: </h3>
        <div className="flex flex-row gap-4 ">
          <div className="col-span-6">
            <textarea
              style={{ width: '300px' }}
              cols={30}
              rows={10}
              placeholder="name,student@univ.ai;student name,student@univ.ai"
              onChange={e => {
                setStudentEmail(e.target.value);
              }}
            />
          </div>
          <div className=" max-w-md w-3/12">
            <select id="role-selector" onChange={e => setInviteeRole(e.target.value)} style={{ maxWidth: '40%' }}>
              <option value="student">Student</option>
              {isAdmin && <option value="ta">Staff</option>}
            </select>
            <div className="flex flex-col " style={{ paddingTop: '3rem' }}>
              <span className="text-gray-500 p-5">
                <strong>Note:</strong> Please enter email in format [name1],[email1];[name2],[email2] and so on
                <br />
                <strong>Note:</strong> Existing emails(highlighted in red) will be ignored while adding new members
              </span>
              {inviteeRole === 'ta' && (
                <span className="text-gray-500 p-5">
                  <strong>Note:</strong> Only those users can be added as a <b>staff</b> who are already registered on
                  the platform
                </span>
              )}
            </div>
          </div>
          {studentEmail && isStudentEmailValid ? (
            <div className="w-full">
              <SimpleTable
                columns={emailValidityTableColumns}
                data={getNewInvitesData()}
                progressPending={loading}
                pagination={true}
                paginationTotalRows={studentEmail.split(';').length}
                paginationPerPage={10}
                noRowsPerPage={true}
                conditionalRowStyles={conditionalRowStyles}
              ></SimpleTable>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div>
          <button
            style={{ marginTop: '5px' }}
            disabled={loading}
            onClick={() => {
              AddStudents();
            }}
          >
            {loading && <Spinner size="tiny" />}
            Add/Invite
          </button>
          <span className="test-red-500">
            {error &&
              typeof error === 'object' &&
              Object.keys(error).map((email: string) => (
                <div>
                  <strong>{email}:</strong> <p className="text-red-500">{(error[email] as any).error}</p>
                </div>
              ))}
          </span>
        </div>
      </div>
    </div>
  );
};

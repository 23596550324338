import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaImage } from 'react-icons/fa';

import Spinner from 'fragments/Spinner';
import MathMd from 'components/Renderer/math-md';
import { requestJSON } from 'utils/request';
import { TA_GET_STATIC_URL } from 'constants/app';

type Props = {
  inputRef: any;
  name: string;
  placeholder?: string;
  defaultValue: string;
  totalRowSize?: number;
  setValue: any;
  getValues: any;
  error?: string;
  contentType?: 'md' | 'html' | 'text';
  showImageUpload?: boolean;
  showPreview?: boolean;
};

type TUpFields =
  | 'key'
  | 'bucket'
  | 'X-Amz-Algorithm'
  | 'X-Amz-Credential'
  | 'X-Amz-Date'
  | 'Policy'
  | 'X-Amz-Signature';

export function FormTextArea(props: Props) {
  const [uploading, setuploading] = useState<boolean>(false);

  const {
    defaultValue,
    error,
    name,
    placeholder,
    inputRef,
    setValue,
    getValues,
    totalRowSize = 10,
    contentType = 'text',
    showImageUpload = true,
  } = props;
  const [text, setText] = useState(defaultValue);
  const value = getValues(name);

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file = e.target?.files[0];
    if (!file) return;
    const extension = file.type.split('/')[1];

    const uploadURLResp = await requestJSON(`${TA_GET_STATIC_URL}/${extension}`, {
      method: 'GET',
      credentials: 'header',
    });
    if (!uploadURLResp.success) return toast.error('Upload failed');

    const uploadParams: { url: { fields: { [key: string]: string }; url: string }; filePath: [] } = uploadURLResp.data
      .data as { url: { fields: { [key: string]: string }; url: string }; filePath: [] };
    if (!uploadParams) return toast.error('Upload failed');

    const { url, filePath } = uploadParams;
    if (!url || !filePath) return toast.error('Upload failed');

    const form = new FormData();
    (Object.keys(url.fields) as TUpFields[]).forEach(key => form.append(key, url.fields[key]));
    form.append('file', file);
    setuploading(true);

    const resp = await fetch(url.url, { method: 'POST', body: form });
    if (resp.status === 200 || resp.status === 204) setValue(name, `${value ? '' : value}\n![](${filePath})`);
    else toast.error('Upload failed');
    setuploading(false);
  };
  useEffect(()=>{
    setText(defaultValue);
  },[defaultValue]);
  return (
    <div className="w-full">
      <div className="flex flex-row">
        <textarea
          className={`textarea ${error ? 'is-danger' : ''}`}
          name={name}
          ref={inputRef}
          placeholder={placeholder}
          rows={totalRowSize}
          defaultValue={defaultValue}
          onChange={e => setText(e.target.value)}
        />
        {showImageUpload && (
          <div className="ml-2 self-center">
            <input id="file-uploader" type="file" className="hidden" onChange={uploadFile} />
            <label className="button" htmlFor="file-uploader">
              {uploading ? (
                <span>
                  <Spinner size="tiny" /> Uploading ...
                </span>
              ) : (
                <FaImage className="inline" />
              )}
            </label>
          </div>
        )}
      </div>
      {text.length>0 && (
        <div className="p-2">
          <h5 className="uppercase text-gray-400 text-sm ">Preview</h5>
          <div className="w-full resize-y">
            {contentType === 'md' && <MathMd value={text} />}
            {contentType === 'html' && <iframe src={'data:text/html,' + encodeURIComponent(text)} className="w-full" />}
            {contentType === 'text' && text}
          </div>
        </div>
      )}
      {/* {value && (
        <div className="mt-2">
          <h5 className="uppercase text-gray-400 text-sm ">Preview</h5>
          <div className="w-full resize-y">
            {contentType === 'md' && <MathMd value={value} />}
            {contentType === 'html' && (
              <iframe src={'data:text/html,' + encodeURIComponent(value)} className="w-full" />
            )}
            {contentType === 'text' && value}
          </div>
        </div>
      )} */}
    </div>
  );
}

import React from 'react';
import { FaCheck } from 'react-icons/fa';

import { ActionIcon } from './actions-style';

type TProps = {
  onClick: Function;
  tooltip: string;
};

export function ActionCheck(props: TProps) {
  return (
    <div data-tooltip={props.tooltip}>
      <ActionIcon color="blue">
        <FaCheck
          onClick={() => {
            props.onClick();
            //toggleModal(row);
          }}
        ></FaCheck>
      </ActionIcon>
    </div>
  );
}

import { EditorState, Modifier, SelectionState } from 'draft-js';

interface IProps {
  blockKey: string;
  editorState: EditorState;
}

export default function RemoveBlock(props: IProps) {
  const { blockKey, editorState } = props;
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(blockKey);

  const targetRange = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: 0,
    focusKey: blockKey,
    focusOffset: block.getLength(),
  });

  const withoutBlock = Modifier.removeRange(content, targetRange, 'backward');
  const resetBlock = Modifier.setBlockType(withoutBlock, withoutBlock.getSelectionAfter(), 'unstyled');

  const newState = EditorState.push(editorState, resetBlock, 'remove-range');
  return EditorState.forceSelection(newState, resetBlock.getSelectionAfter());
}

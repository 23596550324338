export const isNotDev = process.env.REACT_APP_ENV !== 'development';
const DOMAIN_URL = isNotDev
  ? (() => {
      // assuming we'll be running content and course servers as subdomains
      const { host } = window.location;
      const hostComponents = host.split('.');
      hostComponents.shift();

      return hostComponents.join('.');
    })()
  : 'dev.univ.ai';

const PROTOCOL = isNotDev ? 'https' : 'http';

export const APP_BASENAME = isNotDev ? process.env.REACT_APP_BASENAME : '';
export const APP_BASEPATH = process.env.PUBLIC_URL;
export const CONTROL_PANEL_URL = process.env.REACT_APP_CONTROL_PANEL_URL;
export const AUTH_URL = `${PROTOCOL}://user.${DOMAIN_URL}`;

export const COURSE_API_URL = process.env.REACT_APP_API_URL;
export const AGENT_API_URL = `${PROTOCOL}://agentapi.${DOMAIN_URL}`;

export const APPLICATIONS_SITE_URL = process.env.REACT_APP_APPLICATIONS_BASE_URL;
export const EXERCISE_URL = `${PROTOCOL}://exercise.${DOMAIN_URL}`;
export const EXERCISE_API_URL = `${PROTOCOL}://api.exercise.${DOMAIN_URL}`;

export const CLASS_API_URL = COURSE_API_URL;
export const USER_PROFILE_URL = `${APPLICATIONS_SITE_URL}/profile`;
export const TA_BACKEND_URL = `${PROTOCOL}://api.admin.${DOMAIN_URL}`;
export const FILE_CABINET_URL = process.env.REACT_APP_FILE_CABINET_API_URL;
export const FILE_CABINET_URL_DRAFT = `${FILE_CABINET_URL}/draft`;
export const STATIC_UNIV_URL = `${PROTOCOL}://static.${DOMAIN_URL}/api/serve`;
export const getLoginURL = (redirectURL?: string) => `${APPLICATIONS_SITE_URL}/login?from=${redirectURL}`;
export const getLogoutURL = (redirectURL?: string) =>
  `${AUTH_URL}/logout${redirectURL ? `?redirect=${redirectURL}` : ''}`;
export const getAuthRefreshURL = (fromURL: string) => `${AUTH_URL}/jwt/refresh?from=${fromURL}`;

export const HELP_URL = `${APPLICATIONS_SITE_URL}/help`;
export const TA_COURSES_URL = `${COURSE_API_URL}/ta/courses`;
export const TA_TAG_URL = `${EXERCISE_API_URL}/ta/tag`;
export const TA_TAG_QUESTION_URL = `${EXERCISE_API_URL}/ta/tag/question`;
export const TA_QUESTIONS_URL = `${EXERCISE_API_URL}/ta/questions`;
export const TA_EXERCISES_URL = `${EXERCISE_API_URL}/ta/exercises`;
export const TA_EXERCISE_URL = `${EXERCISE_API_URL}/ta/exercise`;
export const TA_EXERCISES_UNPUBLISHED_URL = `${EXERCISE_API_URL}/ta/exercises?status=unpublished`;
export const TA_USER_EXERCISES_URL = `${EXERCISE_API_URL}/ta/user/exercises`;
export const TA_EXERCISE_OVERVIEW_URL = `${EXERCISE_API_URL}/ta/exercise/overview`;
export const TA_QUESTION_URL = `${EXERCISE_API_URL}/ta/question`;
export const TA_QUESTION_ACTIVE_URL = `${EXERCISE_API_URL}/ta/question/active`;
export const TA_QUESTIONS_ACTIVE_URL = `${EXERCISE_API_URL}/ta/questions?state=active`;
export const TA_EXERCISE_ADD_QUESTION_URL = `${EXERCISE_API_URL}/ta/question/assign-question`;
export const TA_GET_STATIC_URL = `${EXERCISE_API_URL}/ta/resource/upload-url`;
export const TA_EXERCISE_PUBLISH_URL = `${EXERCISE_API_URL}/ta/exercise/publish`;
export const TA_DATA_FILES_URL = `${EXERCISE_API_URL}/ta/data-files`;
export const CODE_QUESTION_RUN_URL = `${EXERCISE_API_URL}/question/run-code`;
export const CODE_QUESTION_RUN_TEST_URL = `${EXERCISE_API_URL}/question/run-test`;
//export const SCHEDULE_BANNER_CREATE_URL = `${COURSE_API_URL}/meetup/create-banner`;
export const SCHEDULE_BANNER_CREATE_URL = `${TA_BACKEND_URL}/meetup/create-banner`;
export const SEND_WA_MSG = `${COURSE_API_URL}/event/send-message`;

export const GET_NOTEBOOK_EXERCICE_API = `${TA_BACKEND_URL}/notebook-exercise`;
export const DOWNLOAD_PATH = `${EXERCISE_API_URL}/resource/download-url`;
export const EXERCISE_PATH = '/exercise';
export const QUESTION_PATH = '/question';
export const CREATE_EXERCISE_PATH = '/exercise/create';
export const CREATE_QUESTION_PATH = '/question/create';
export const POPULATE_EXERCISE_PATH = '/exercise/populate';
export const PUBLISH_EXERCISE_PATH = '/exercise/publish';
export const COURSEWARE_URL = `${PROTOCOL}://app.${DOMAIN_URL}`;
//Class Scheduling
export const CLASS_PATH = '/session';
export const CLASS_PATH_CREATE = '/session/create';
export const CLASS_BULK_UPLOAD_PATH = '/session/bulk-upload';
export const TA_GET_CLASSES_URL = `${TA_BACKEND_URL}/session/get-session`;
export const TA_CREATE_CLASSES_URL = `${TA_BACKEND_URL}/session/create-session`;
export const TA_UPDATE_CLASSES_URL = `${TA_BACKEND_URL}/session/update-session`;
export const TA_START_CLASSES_URL = `${TA_BACKEND_URL}/session/start-session`;
export const TA_STOP_CLASSES_URL = `${TA_BACKEND_URL}/session/end-session`;
export const TA_CLASS_BULK_UPLOAD_API_URL = `${TA_BACKEND_URL}/session/bulk`;

//scheduled Events
export const SCHEDULE_EVENTS_PATH = '/scheduled-events';
export const SCHEDULE_EVENTS_CREATE_PATH = '/scheduled-events/create-event';
export const TA_GET_SCHEDULE_EVENTS = `${TA_BACKEND_URL}/scheduled-events/get-event`;
export const TA_UPDATE_SCHEDULED_EVENTS_URL = `${TA_BACKEND_URL}/scheduled-events/update-event`;
export const TA_CREATE_SCHEDULED_EVENTS_URL = `${TA_BACKEND_URL}/scheduled-events/create-event`;
export const MAIL_EDITOR_PATH = '/scheduled-events/create-editor';
export const TA_GET_ALL_SCHEDULE_EVENTS = `${TA_BACKEND_URL}/scheduled-events/all-events`;

//meetup URLs
export const TA_CREATE_SCHEDULED_MEETUP_URL = `${TA_BACKEND_URL}/meetup/create`;
export const TA_UPDATE_SCHEDULED_MEETUP_URL = `${TA_BACKEND_URL}/meetup/update`;
export const TA_GET_SCHEDULE_MEETUP = `${TA_BACKEND_URL}/meetup/events`;
/// meetup paths
export const MEETUPS_PATH = '/meetups';
export const SCHEDULE_MEETUP_CREATE_PATH = '/meetups/create-meetups';
export const SCHEDULE_BANNER_CREATE_PATH = '/meetup/create-banner';

//Auth
export const IsTA_URL = `${TA_BACKEND_URL}/isTA`;
// setting URL
//export const SETTING_URL = `${COURSE_API_URL}/setting`;
export const SETTING_URL = `${TA_BACKEND_URL}/setting`;

//hackathon Paths
export const HACKATHON_CREATE_PATH = '/hackathon/create';
export const HACKATHON_LIST_PATH = '/hackathon';

export const SPACES_IMAGE_PATH = 'image';
export const SPACES_DATA_PATH = 'data';

export const SPACES_MY_SPACE_PUBLIC_PATH = 'public/my';
export const SPACES_MY_SPACE_PRIVATE_PATH = 'private/my';

//Course Paths
export const COURSE_ROUTER_CREATE_PATH = '/course/create';
export const COURSE_ROUTER_LIST_PATH = '/courses';
export const COURSE_API_PATH = `${TA_BACKEND_URL}/courses`;

//Program Paths
export const PROGRAM_ROUTER_CREATE_PATH = '/program/create';
export const PROGRAM_ROUTER_LIST_PATH = '/programs';
export const PROGRAM_API_PATH = `${TA_BACKEND_URL}/programs`;
export const PROGRAM_MAP_TO_COURSES_PATH = `${TA_BACKEND_URL}/map-program`;
export const PROGRAM_MAP_PATH = `${PROGRAM_ROUTER_LIST_PATH}/mappper`;
export const PROGRAM_MAPPING_PATH = `${PROGRAM_MAP_PATH}/:programId`;

//Product
export const PRODUCT_ROUTER_CREATE_PATH = '/product/create';
export const PRODUCT_ROUTER_LIST_PATH = '/products';
export const PRODUCT_API_PATH = `${TA_BACKEND_URL}/products`;

//Certificate
export const CERTIFICATE_ROUTER_CREATE_PATH = '/certificate/create';
export const CERTIFICATE_ROUTER_LIST_PATH = '/certificates';
export const CERTIFICATE_ROUTER_BULK_UPLOAD_PATH = '/certificates/bulk-upload';
export const CERTIFICATE_API_PATH = `${TA_BACKEND_URL}/certificate`;
export const CERTIFICATE_MAP_USER_API_PATH = `${TA_BACKEND_URL}/certificate/map/bulk`;
export const CERTIFICATE_AWARDEE_URL = `${TA_BACKEND_URL}/certificate/awardee`;
//Application Paths
export const APPLICATION_ROUTER_LIST_PATH = '/application';
export const APPLICATION_GET_PATH = (pageNo: number) => `${TA_BACKEND_URL}/applications?page=${pageNo}`;

export const APPLICATION_GET_TOTAL_COUNT_PATH = `${TA_BACKEND_URL}/applications/total`;
export const APPLICATION_GET_PRODUCT_PATH = `${TA_BACKEND_URL}/products`;

export const APPLICATION_GET_SEARCH_PATH = (searchInput: string, pageNo: number) =>
  `${TA_BACKEND_URL}/applications/search?query=${searchInput}&&page=${pageNo}`;

export const APPLICATION_GET_FILTER_APPLICATION_PATH = (applicationState: string, pageNo: number) =>
  `${TA_BACKEND_URL}/applications/filter?key=state&&value=${applicationState}&&page=${pageNo}`;

export const APPLICATION_GET_FILTER_PRODUCT_PATH = (productName: string, pageNo: number) =>
  `${TA_BACKEND_URL}/applications/filter?key=product_name&&value=${productName}&&page=${pageNo}`;

export const SYNC_ALL_SERVERS_API = `${TA_BACKEND_URL}/sync-all-servers`;
export const APPLICATION_PATCH_STATE_PATH = `${TA_BACKEND_URL}/applications`;
export const APPLICATION_EXPIRE_API_URL = `${TA_BACKEND_URL}/applications-expire`;
export const DOWNLOAD_RESUME_URL = `${TA_BACKEND_URL}/application/resume`;
export const CERTIFICATES_SIGNATORIES = [
  {
    name: 'Siddharth Das',
    position: 'Chief Executive Officer',
    institution: 'Univ.AI',
    sigPath: 'https://application.univ.ai/sid.svg',
  },
  {
    name: 'Dr Rahul Dave',
    position: 'Founding Faculty & Chief Scientist',
    institution: 'Univ.AI',
    sigPath: 'https://application.univ.ai/rahul.svg',
  },
  {
    name: 'Dr Pavlos Protopapas',
    position: 'Scientific Program Director, IACS',
    institution: 'Harvard University',
    sigPath: 'https://application.univ.ai/pavlos.svg',
  },
  {
    name: 'Dr Raghu Meka',
    position: 'Associate Professor, CS Department',
    institution: 'UCLA',
    sigPath: 'https://application.univ.ai/raghu.svg',
  },
  {
    name: 'Atul Tripathi',
    position: 'Principal Data Scientist - Cyber-security',
    institution: 'Tech Mahindra',
    sigPath: 'https://application.univ.ai/atul.svg',
  },
];

//PAYMENT
export const PAYMENT_API_PATH = `${TA_BACKEND_URL}/payment/`;

//SUBSCRIPTIONS
export const SUBSCRIPTION_PLAN_API_PATH = `${TA_BACKEND_URL}/subscriptions?condensed`;
export const SUBSCRIPTION_ROUTER_LIST_PATH = '/subscriptions';
export const SUBSCRIPTION_MAP_MEETUP_API_PATH = `${TA_BACKEND_URL}/subscriptions/update`;
export const SUBSCRIPTION_MEETUPS_LIST_API_PATH = `${TA_BACKEND_URL}/subscriptions/meetup-subscriptions`;

//GLOBAL SLOTS
export const GLOBAL_SLOT_LIST_PATH = '/global-slot';
export const GLOBAL_SLOT_CREATE_PATH = '/global-slot/create';
export const GLOBAL_SLOT_API_URL = `${TA_BACKEND_URL}/meetup-slots`;
//export const GLOBAL_SLOT_CREATE_API_URL = '';

//COHORTS
export const COHORTS_LIST_PATH = '/cohorts';
export const COHORTS_CREATE_PATH = '/cohorts/create';
export const COHORTS_API_URL = '/cohorts';

//COUPONS
export const COUPONS_LIST_PATH = '/coupons';
export const COUPON_CREATE_PATH = '/coupon/create';
export const COUPON_API_URL = `${TA_BACKEND_URL}/discount`;

//REFERRALS
export const REFERRALS_LIST_PATH = '/referrals';
// export const REFERRALS_CREATE_PATH = '/referrals/create';
export const REFERRALS_API_URL = `${TA_BACKEND_URL}/referrals`;

//USERS
export const USER_LIST_PATH = '/users';
export const USER_API_URL = (pageNum: number) => `${TA_BACKEND_URL}/user-details?page=${pageNum}`;
export const USER_TOTAL_COUNT_API_URL = `${TA_BACKEND_URL}/users/total`;
export const USER_PROFILE_DETAILS_API_URL = (userId: number) => `${TA_BACKEND_URL}/profile-details?userId=${userId}`;
export const USER_REFERRAL_DETAILS_API_URL = (userId: number) => `${TA_BACKEND_URL}/users-referred?userId=${userId}`;
export const USER_PRODUCT_ACCESS_DETAILS_API_URL = (userId: number) =>
  `${TA_BACKEND_URL}/products/get-access?userId=${userId}`;
export const USER_PRODUCT_ACCESS_REVOKE_API_URL = (userId: number, productValue: string) =>
  `${TA_BACKEND_URL}/products/revoke-access?userId=${userId}&productValue=${productValue}`;
export const USER_PRODUCT_ACCESS_GRANT_URL = `${TA_BACKEND_URL}/products/allow-access`;
export const USER_COURSE_ACCESS_GRANT_URL = `${TA_BACKEND_URL}/course/allow-access`;
export const USER_PRODUCT_ACCESS_GRANT_BULK_URL = `${TA_BACKEND_URL}/products/allow-access-bulk`;
export const VALIDATE_USERS_API_URL = `${TA_BACKEND_URL}/validate-referral`;
export const USER_SEARCH_API_URL = (searchTerm: string, pageNum: number) =>
  `${TA_BACKEND_URL}/user-search?query=${searchTerm}&&page=${pageNum}`;

//JOBS
export const JOBS_LIST_PATH = '/jobs';
export const JOBS_API_URL = `${TA_BACKEND_URL}/jobs`;
// export const JOBS_LIST_API_URL = (pageNum: number) => `${COURSE_API_URL}/jobs?page=${pageNum}`;
export const JOBS_LIST_API_URL = (pageNum: number) => `${TA_BACKEND_URL}/jobs?page=${pageNum}`;
export const JOBS_CREATE_PATH = '/jobs/create';
export const JOB_APPLICATION_LIST_PATH = '/job-applications/:jobId/:title';
export const JOB_APPLICATION_API_URL = (jobId: number, pageNum: number) =>
  `${TA_BACKEND_URL}/jobs-application?jobId=${jobId}&page=${pageNum}`;
export const JOB_APP_UPDATE_API_URL = `${TA_BACKEND_URL}/jobs-application`;
export const JOB_CATEGORY_API_URL = `${TA_BACKEND_URL}/jobs-category`;
export const JOB_COMPANY_API_URL = `${TA_BACKEND_URL}/jobs-company`;
export const JOB_TAGS_API_URL = `${TA_BACKEND_URL}/tags`;
export const JOB_SEARCH_API_URL = (searchTerm: string, pageNum: number) =>
  `${TA_BACKEND_URL}/jobs-search?query=${searchTerm}&&page=${pageNum}`;

//TEMPLATE FILE LINKS
export const BULK_UPLOAD_CLASSES_SPACES_URL =
  'https://static-ai.nyc3.digitaloceanspaces.com/univai/public/samples/ScheduleClassesSample.csv';
export const BULK_UPLOAD_CERTIFICATES_SPACES_URL =
  'https://nyc3.digitaloceanspaces.com/static-ai/public/templates/bulk%20upload%20cert.csv';
export const BULK_UPLOAD_MEETUPS_SPACES_URL =
  'https://nyc3.digitaloceanspaces.com/static-ai/univai/public/ScheduleMeetupsSample.csv';

//USER AGENT
export const USER_AGENT_LIST_PATH = '/agent';
export const USER_AGENT_CREATE_PATH = '/agent/create';
export const USER_AGENT_API_URL = `${AGENT_API_URL}/agent`;
export const USER_AGENT_LIST_API_URL = `${AGENT_API_URL}/agents`;
export const USER_AGENT_TOGGLE_STATUS_API_URL = `${AGENT_API_URL}/toggle-agent-status`;
export const USER_AGENT_RESEND_EMAIL_API_URL = `${AGENT_API_URL}/resend-code`;

//FEEDBACK
export const FEEDBACK_LIST_PATH = '/feedbacks';
export const FEEDBACK_LIST_API_URL = (pageNum: number) => `${TA_BACKEND_URL}/feedbacks?page=${pageNum}`;
export const FEEDBACK_UPDATE_API_URL = `${TA_BACKEND_URL}/feedback`;
export const FEEDBACK_VIEW_IMAGE_API_URL = (filePath: string) => `${TA_BACKEND_URL}/signed-url?path=${filePath}`;

//CMS
export const CMS_PATH = '/cms';
export const CMS_UPLOAD_COURSE_ZIP_PATH = `${CMS_PATH}/course/file/upload-course-zip`;
export const CMS_COURSE_PATH = `${CMS_PATH}/:courseId`;
export const CMS_LESSON_PATH = `${CMS_PATH}/:courseId/:lessonTitle`;
export const CMS_TOC_API_URL = `${FILE_CABINET_URL}/cms/ta/toc`;
export const CMS_UPLOAD_COURSE_ZIP_API_URL = `${FILE_CABINET_URL}/spaces/ta/upload-course-zip`;
export const CMS_LESSON_API_URL = `${FILE_CABINET_URL}/cms/ta/lesson`;
export const CMS_MODULE_API_URL = `${FILE_CABINET_URL}/cms/ta/slide/header`;
export const CMS_SECTION_API_URL = `${FILE_CABINET_URL}/cms/ta/slide`;
export const CMS_DOWNLOADABLE_SECTION_API_URL = `${FILE_CABINET_URL}/cms/ta/slide/dowloadble-path`;
export const CMS_DELETE_DOWNLOADABLE_SECTION_API_URL = (
  courseId: string,
  lessonTitle: string,
  downloadPathId: string
) =>
  `${FILE_CABINET_URL}/cms/ta/slide/dowloadble-path?courseId=${courseId}&&lessonTitle=${lessonTitle}&&downloadPathId=${downloadPathId}`;

export const CMS_MODULE_DELETE_API_URL = (courseId: string, lessonTitle: string, headerId: string) =>
  `${FILE_CABINET_URL}/cms/ta/slide/header?courseId=${courseId}&&lessonTitle=${lessonTitle}&&headerId=${headerId}`;
export const CMS_MODULE_ADD_API_URL = (courseId: string, lessonTitle: string, headerName: string) =>
  `${FILE_CABINET_URL}/cms/ta/slide/header?courseId=${courseId}&&lessonTitle=${lessonTitle}&&headerName=${headerName}`;
export const CMS_MODULE_UPDATE_API_URL = (
  courseId: string,
  lessonTitle: string,
  newHeaderName: string,
  headerId: string
) =>
  `${FILE_CABINET_URL}/cms/ta/slide/header?courseId=${courseId}&&lessonTitle=${lessonTitle}&&newHeaderName=${newHeaderName}&&headerId=${headerId}`;

export const CMS_GET_SECTION_API_URL = (courseId: string, lessonTitle: string, type: string, sectionId: string) =>
  `${FILE_CABINET_URL}/cms/ta/slide?courseId=${courseId}&&lessonTitle=${lessonTitle}&&type=${type}&&sectionId=${sectionId}`;
export const CMS_DELETE_SECTION_API_URL = (courseId: string, type: string, sectionId: string, lessonTitle: string) =>
  `${FILE_CABINET_URL}/cms/ta/slide?courseId=${courseId}&&type=${type}&&sectionId=${sectionId}&&lessonTitle=${lessonTitle}`;
export const CMS_HIDE_SECTION_API_URL = (courseId: string, sectionId: string, lessonTitle: string) =>
  `${FILE_CABINET_URL}/cms/ta/slide/hide-section?courseId=${courseId}&&sectionId=${sectionId}&&lessonTitle=${lessonTitle}`;
export const CMS_UNHIDE_SECTION_API_URL = (courseId: string, sectionId: string, lessonTitle: string) =>
  `${FILE_CABINET_URL}/cms/ta/slide/unhide-section?courseId=${courseId}&&sectionId=${sectionId}&&lessonTitle=${lessonTitle}`;
export const CMS_SAVE_COURSE_API_URL = (courseId: string, commitText: string) =>
  `${FILE_CABINET_URL}/ta/save-course?courseName=${courseId}&commitMessage=${commitText}`;
export const CMS_PUBLISH_COURSE_API_URL = (courseName: string) =>
  `${FILE_CABINET_URL}/ta/publish-course?courseName=${courseName}`;
export const CMS_LESSON_ORDER_API_URL = `${FILE_CABINET_URL}/cms/ta/reorder-lesson`;
export const CMS_MODULES_ORDER_API_URL = `${FILE_CABINET_URL}/cms/ta/reorder`;
export const STUDENT_EXERCISE_DETAILS_URL = `${EXERCISE_API_URL}/ta/student/responses`;
export const MAX_ALLOWED_FILE_SIZE = {
  //All sizes are in MB
  NOTEBOOK: 45,
  PDF: 45,
  VIDEO: 45,
  IMAGE_SLIDE_TOTAL: 50,
  IMAGE_SLIDE_SINGLE: 2,
};

export const APPLICATION_PROCESS_PATH = '/application-process';
export const CREATE_APPLICATION_PROCESS_PATH = '/application-process/create';

export const QUESTIONNAIRE_PATH = '/questionnaire';
export const CREATE_QUESTIONNAIRE_PATH = '/questionnaire/create';

export const TOPICS_PATH = '/topics';
export const STUDENT_MANAGEMENT_PATH = '/student-management/:courseId';
export const STUDENT_MANAGEMENT_EVALUATION_STUDENT_PATH =
  '/student-management-dashboard/:courseId/student/:studentUserId/:studentName/:exerciseId';

export const SUBMISSIONS_PATH = '/assignments';
export const COURSE_SUBMISSIONS_PATH = '/:courseId/assignments';
export const SUBMISSIONS_CREATE_PATH = '/assignments/create';
export const STUDENT_SUBMISSION_LIST_PATH = '/student-assignment/:submissionId/';
export const SUBMISSION_EVALUATION_LIST_PATH = '/student-assignment/:submissionId/:studentUserId';

export const STUDENT_MANAGEMENT_DASHBOARD = '/student-management-dashboard/:courseId';

export const UPLOAD_SESSION_RECORDING_API_URL = TA_BACKEND_URL + '/upload-session-recording';
export const NOTEBOOK_ALL_FILES_GET_API_URL = (exerciseId:string)=>`${TA_BACKEND_URL}/manage-notebook-file?exerciseId=${exerciseId}&action=list&type=all`;
export const NOTEBOOK_FILES_GET_SIGNED_API_URL = (exerciseId:string,action:string, type:string, filename:string)=>`${TA_BACKEND_URL}/manage-notebook-file?exerciseId=${exerciseId}&action=${action}&type=${type}&filename=${filename}`;
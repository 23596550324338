import React from 'react';

type Props = {
  defaultValue?: string;
  inputRef: React.Ref<HTMLInputElement>;
  name: string;
  options?: string[] | number[];
  placeholder?: string;
  error?: string;
  errors?: { [key: string]: string };
  getLabelElement?: (option: string | number) => Element;
  labelElementInline?: boolean;
};

export function FormCheck(props: Props) {
  const { errors, name, options, inputRef, getLabelElement, labelElementInline = false, defaultValue } = props;
  if (!options) return null;

  const error = errors !== undefined && errors[`${name}`];
  const opts = [...options];
  const isChecked = (option: string | number) => {
    if (defaultValue) return defaultValue.includes(option as string);
  };
  return (
    <div className="control">
      {opts.map((option: string | number) => (
        <div className="mb-2 flex flex-row items-center" key={`check-${option}`}>
          <label className={`check ${error ? 'danger' : ''}`}>
            <input
              className="mr-4"
              type="checkbox"
              name={name}
              ref={inputRef}
              value={option}
              defaultChecked={isChecked(option)}
            />
            {getLabelElement ? (labelElementInline ? getLabelElement(option) : null) : option}
          </label>
          {getLabelElement && !labelElementInline ? getLabelElement(option) : null}
        </div>
      ))}
    </div>
  );
}

import {
    FormInput,
    FormNumber,
    Date,
    preProcessForm,
    FormComponentHolder,
    FormTextArea,
    FormDropdown,
  } from 'components/Form';
  import Spinner from 'fragments/Spinner';
  import Error from 'components/Error';
  import moment from 'moment';
  import React, { useEffect, useState } from 'react';
  import { useForm } from 'react-hook-form';
  import { FaSave } from 'react-icons/fa';
  import { EModalTypes, TFormFields, TSubmission } from 'types';
  import { TA_BACKEND_URL } from 'constants/app';
  import { toast } from 'react-toastify';
  import { requestJSON } from 'utils/request';
import { formatData } from './utils';
  
  type TProps = {
    editedRow?: TSubmission;
    onUpdate?: Function;
    closeModal?: Function;
    type: 'create' | 'update' | 'duplicate';
    isActive?: boolean;
  };
  
  export default function SubmissionForm(props: TProps) {
    const { editedRow } = props;
    const { register, handleSubmit, control, errors, reset, getValues, setValue } = useForm();
    const [coursesList, setCoursesList] = useState<{id:string;name:string}[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>();
  
    let formFields: TFormFields[] = [
      {
        FormComponent: FormInput,
        name: 'title',
        label: 'Enter the Assignment Title',
        info: '',
        placeholder: 'Assignment for course',
        defaultValue: '',
        constraints: { required: true, minLength: 2, maxLength: 200 },
      },
      {
        FormComponent: FormTextArea,
        name: 'description',
        label: 'Enter the Assignment Description',
        placeholder: 'Any text here. 115 characters would be shown',
        defaultValue: '',
        constraints: { required: true, minLength: 5, maxLength: 4000 },
      },
      {
        FormComponent: FormTextArea,
        name: 'summary',
        label: 'Summary for the assignment',
        placeholder: 'Any text here. 115 characters would be shown',
        defaultValue: '',
        constraints: { required: true, minLength: 5, maxLength: 400 },
      },
      {
        FormComponent: FormDropdown,
        name: 'course_id',
        label: 'Select the Course',
        defaultValue: '',
        info: '',
        options: coursesList.map((course: {id:string;name:string}) => ({ id: course.id, shownValue: course.name })),
        constraints: { required: true },
      },
      {
        FormComponent: FormInput,
        name: 'discussion_url',
        label: 'Enter the Discussion URL',
        info: 'discussion url',
        placeholder: '',
        defaultValue: '',
      },
      {
        FormComponent: FormNumber,
        name: 'total_marks',
        label: 'Enter the Total Marks for the Assignment',
        placeholder: '100',
        constraints: { required: true, min: 1 },
      },
      {
        FormComponent: Date,
        name: 'start_date',
        label: 'Enter the Start Date for the Assignment ',
        info: '',
        defaultValue: moment().add(1, 'day'),
        timeRequired: false,
        constraints: { required: true },
      },
      {
        FormComponent: Date,
        name: 'end_date',
        label: 'Enter the End Date for the Assignment',
        info: '',
        defaultValue: moment().add(1, 'day'),
        timeRequired: false,
        constraints: { required: true },
      }
    ];
  
    const fillDefaultValues = (row: TSubmission) => {
      const dbData = { ...row };
      return formFields.map((field: TFormFields) => {
        field.defaultValue = dbData[field.name as keyof TSubmission];
        return field;
      });
    };
  
    if (editedRow != undefined) formFields = fillDefaultValues(editedRow);
  
    const processedFormFields = preProcessForm(formFields);
  
    const inputParams = processedFormFields.map(f => ({
      ...f,
      errors,
      error: errors[`${f.name}`] && errors[`${f.name}`].message,
      inputRef: f.constraints ? register(f.constraints) : register(),
      getValues,
      setValue,
      refGenerator: register,
      control,
    }));
  
    const publishForm = (data: any) => {
      setSubmitting(true);
      setLoading(true);
      const sendData = formatData(data);
      if (props.type == 'create' && props.onUpdate) {
        props.onUpdate(sendData);
      } else if (props.type == 'duplicate' && props.onUpdate) {
        props.onUpdate(sendData);
      } else if (props.type == 'update' && props.onUpdate) {
        props.onUpdate(sendData);
      }
      if (props.closeModal != undefined) props.closeModal();
      setSubmitting(false);
      setLoading(false);
    };
  
    const getCourse = async () => {
      setLoading(true);
  
      const response = await requestJSON(`${TA_BACKEND_URL}/courses-list`, {
        credentials: 'header',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
  
      if (!response.success) {
        setLoading(false);
        setCoursesList([]);
        return setError(response.error);
      }
      if (response.data) {
        const fetchedCourses = response.data.data as {id:string;name:string}[];
        setCoursesList(fetchedCourses);
      } else {
        toast.error('Unable to fetch Course list.');
      }
      setLoading(false);
    };
  
    useEffect(() => {
    getCourse();
      return () => {
        setCoursesList([]);
      };
    }, []);
  
    if (loading) return <Spinner spaced />;
    if (coursesList.length === 0)
      return (
        <Error
          embed
          preset="dependentData"
          text="Either job company or job category data unavailable. Create them before creating a job"
        />
      );
    if (error) return <Error embed preset="nothing" text={error} />;
  
    return (
      <form onSubmit={handleSubmit(publishForm)}>
        {inputParams.map(inputParam => {
          return <FormComponentHolder key={inputParam.name} {...inputParam} />;
        })}
  
        <div className="flex justify-between items-center my-4">
          <button className="button clear secondary" type="button" tabIndex={-1} onClick={reset}>
            Reset
          </button>
          <button className="button" type="submit">
            {submitting ? (
              <Spinner size="tiny" />
            ) : (
              <>
                <FaSave className="inline mr-2" /> Save
              </>
            )}
          </button>
        </div>
        <hr />
      </form>
    );
  }
  
import imageCompression from 'browser-image-compression';
import { DOWNLOAD_PATH, SPACES_MY_SPACE_PRIVATE_PATH, TA_GET_STATIC_URL } from 'constants/app';
import { requestJSON } from 'utils/request';
import { toast } from 'react-toastify';
import { TuploadSpaces } from 'types';
import { parse } from 'csv-parse/lib/sync';

type TUpFields =
  | 'key'
  | 'bucket'
  | 'X-Amz-Algorithm'
  | 'X-Amz-Credential'
  | 'X-Amz-Date'
  | 'Policy'
  | 'X-Amz-Signature';

export function normalizeValues(value: string | boolean | string[], _field: string) {
  if (value === true) return 'Yes';
  if (value === false) return 'No';

  return value;
}
// export function getChangedValues(o1: { [key: string]: any }, o2: { [key: string]: any }) {
//   const diff: { [key: string]: any } = {};

//   for (const key in o1) {
//     if (o2[key] && o2[key] !== o1[key]) {
//       diff[key] = o1[key];
//     } else if (!o2[key] && (!o2.meta[key] || o2.meta[key] != o1[key])) {
//       diff[key] = o1[key];
//     }
//   }

//   return diff;
// }

export const uploadFileToSpaces = async (
  file: File,
  uploadPath: string = SPACES_MY_SPACE_PRIVATE_PATH,
  folder = 'univai',
  acl: 'private' | 'public' = 'private'
): Promise<TuploadSpaces | number> => {
  if (!file) return -1;
  const spacesFilePath = `${uploadPath}/${file.name}`;
  const options = { maxWidthOrHeight: 600 }; // scale image
  const compressedFile = (await imageCompression(file, options)) as File;

  const fileName = encodeURIComponent(spacesFilePath);
  const fileType = encodeURIComponent(file.type);
  const uploadURLResp = await requestJSON(
    `${TA_GET_STATIC_URL}?name=${fileName}&type=${fileType}&folder=${folder}&acl=${acl}`,
    {
      method: 'GET',
      credentials: 'header',
    }
  );
  if (!uploadURLResp.success) {
    toast.error(uploadURLResp.error);
    return -1;
  }

  //remove types
  const uploadParams: { url: { fields: { [key: string]: string }; url: string }; filePath: [] } = uploadURLResp.data
    .data as { url: { fields: { [key: string]: string }; url: string }; filePath: [] };

  if (!uploadParams) {
    toast.error('Upload failed');
    return -1;
  }

  const { url, filePath } = uploadParams;
  if (!url || !filePath) {
    toast.error('Upload failed');
    return -1;
  }

  const form = new FormData();
  (Object.keys(url.fields) as TUpFields[]).forEach(key => form.append(key, url.fields[key]));
  form.append('file', compressedFile);

  const resp = await fetch(url.url, { method: 'POST', body: form });
  if (resp.status !== 200 && resp.status !== 204) {
    toast.error('Upload failed');
    return -1;
  }
  const privateFilePath = `${DOWNLOAD_PATH}?path=${filePath}`;
  // format expected to render file
  return { data: { link: privateFilePath, absLink: url.url + '/' + filePath } };
};

export const createNewData = async <T>(data: T, url: string) => {
  const body = JSON.stringify(data);
  const response = await requestJSON(url, {
    credentials: 'header',
    body: body,
    method: 'POST',
    headers: { 'content-type': 'application/json' },
  });

  return response;
};

export const updateExistingData = async <T>(data: T, url: string) => {
  const body = JSON.stringify(data);

  const response = await requestJSON(url, {
    credentials: 'header',
    body: body,
    method: 'PATCH',
    headers: { 'content-type': 'application/json' },
  });

  return response;
};

export const validateDate = (startDate: string, endDate: string, mode: 'create' | 'update') => {
  let startDateInBigInt;
  let endDateInBigInt;
  if (mode === 'create') {
    startDateInBigInt = new Date(startDate).getTime();
    endDateInBigInt = new Date(endDate).getTime();
  } else {
    if (startDate.match(/^[0-9]*$/)) {
      startDateInBigInt = startDate;
    } else startDateInBigInt = new Date(startDate).getTime();

    if (endDate.match(/^[0-9]*$/)) {
      endDateInBigInt = endDate;
    } else endDateInBigInt = new Date(endDate).getTime();
  }
  if (startDateInBigInt < Date.now()) return { error: true, message: 'Start date should be greater than current time' };
  else if (endDateInBigInt < startDateInBigInt)
    return { error: true, message: 'End date should be greater than the start date' };
  else return { error: false, message: '' };
};

export const retrieveMetaData = (data: string) => {
  return JSON.parse(data);
};

export const isValidEmail = (email: string) => {
  const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
  return emailPattern.test(email);
};
export const formatQuestionCsvObj = (data: any, optionsColumnCount: number, type: string) => {
  if (type === 'free-form') {
    return [...data.map((a: any) => {
      return {
        ...a,
        tags: a.tags.split(',') || [],
        type: 'text',
        max_points: +a.award_points,
        award_points: +a.award_points
      };
    })];
  } else {
    return [...data.map((a: any) => {
      const options: { points: number, text: string }[] = [];
      for (let i = 1; i <= optionsColumnCount; i++) {
        if (a[`option-${i}-points`] != '' && a[`option-${i}-text`] != '')
        options.push({ 'text': a[`option-${i}-text`], 'points': +a[`option-${i}-points`] });
        if (a[`option-${i}-points`] || a[`option-${i}-points`] === '') delete a[`option-${i}-points`];
        if (a[`option-${i}-text`] || a[`option-${i}-text`] === '') delete a[`option-${i}-text`];
      }
      return {
        ...a,
        options,
        tags: a.tags.split(',') || [],
        type: getType(options),
        max_points: getPoints(options),
        award_points: getPoints(options)
      };

    })];
  };
};
const getType = (data: { text: string; points: number }[]) => {
  let i = 0;
  for (let j = 0; j < data.length; j++) {
    if (data[j].points !== 0) {
      i = i + 1;
    }
  }
  return i > 1 ? 'mcq' : 'radio';
};
const getPoints = (data: { text: string; points: number }[]) => {
  return data.reduce((a: number, b: { text: string; points: number }) => { return a + b.points; }, 0);
};
export const csvToArray = (str: string | Buffer) => {
  const rows = parse(str, {
    columns: true,
    skip_empty_lines: true,
    skip_records_with_empty_values: true,
  });
  return rows;
};
export const getCSVHeaders = (str: string) => {
  return [...str.split('\n')[0].split(',').map(a => {
    if (a.includes('\r'))
      return a.split('\r')[0];
    else return a;
  })];
};
export const csvColumnToArray = (str: string | Buffer) => {
  const rows = parse(str, {
    columns: true,
    skip_empty_lines: true,
    skip_records_with_empty_values: true,
  });
  return rows.reduce((a: { user_id: number[], product_id: number[] },
    b: { userId: string, productId: string }) => {
    a.user_id.push(+b.userId);
    a.product_id.push(+b.productId);
    return a;
  }, { user_id: [], product_id: [] });
};

export const downloadURI = (uri: string, name: string) => {
  const link = document.createElement('a');
  link.href = uri;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const formattedDataForTable = (data: any) => {
  for (let i = 0; i < data.length; i++) {
    const name = data[i].meetup_names.join(', ') || 'no associated meetups';
    delete data[i].meetup_names;
    data[i].meetup_name = name;
  }
};

// export const removeAllBlankObjects = (detailsObj: any) => {
//   Object.keys(detailsObj).forEach(k => {
//     if (detailsObj[k] && typeof detailsObj[k] === 'object' && removeAllBlankObjects(detailsObj[k]) === null) {
//       delete detailsObj[k];
//     }
//   });
//   if (!Object.keys(detailsObj).length) {
//     return null;
//   } else return detailsObj;
// };

import React, { ChangeEventHandler } from 'react';

type Props = {
  inputRef: React.Ref<HTMLInputElement>;
  defaultValue?: string | boolean | number;
  name: string;
  options?: string[] | number[];
  placeholder?: string;
  error?: string;
  errors?: { [key: string]: string };
  getLabelElement?: (option: string | boolean | number) => Element;
  labelElementInline?: boolean;
  changeHandler?: ChangeEventHandler<HTMLInputElement>;
};

export function FormRadio(props: Props) {
  const {
    errors,
    name,
    options,
    inputRef,
    getLabelElement,
    labelElementInline = false,
    defaultValue,
    changeHandler,
  } = props;
  if (!options) return null;

  const error = errors !== undefined && errors[`${name}`];
  const opts = [...options];

  const checkSelectedRadio = (option: string | boolean | number) => {
    if (defaultValue === option) return true;
    else if (defaultValue === true && option == 'Yes') return true;
    else if (defaultValue === false && option == 'No') return true;
    return false;
  };
  return (
    <div className="control flex flex-col">
      {opts.map((option: string | number, i: number) => (
        <div
          className="mb-2 flex  flex-row items-center"
          key={`radio-${option}`}
          id={`radio-${option}`}
          // style={{ marginLeft: `${i !== 0 ? '10px' : ''}` }}
        >
          <label className={`radio ${error ? 'danger' : ''}`}>
            <input
              className="mr-4"
              type="radio"
              name={name}
              ref={inputRef}
              value={option}
              onChange={changeHandler}
              defaultChecked={checkSelectedRadio(option)}
            />
            {getLabelElement ? (labelElementInline ? getLabelElement(option) : null) : option}
          </label>
          {getLabelElement && !labelElementInline ? getLabelElement(option) : null}
        </div>
      ))}
    </div>
  );
}

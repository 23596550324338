/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { DatePickerInput } from 'rc-datepicker';
import moment, { Moment } from 'moment';
import 'rc-datepicker/lib/style.css';
import { Time } from './Time';
import { Controller } from 'react-hook-form';

type Props = {
  Icon?: any;
  inputRef: any;
  label: string;
  info?: string;
  name: string;
  refGenerator?: any;
  placeholder?: string;
  defaultValue: Moment;
  minDate?: Function;
  formValues?: any;
  errors?: any;
  control: any;
  [key: string]: any;
  timeRequired?: boolean;
};

export function Date(props: Props) {
  const { defaultValue, control, name, timeRequired = true } = props;
  const [temp,setTemp] = useState<Moment | string>('');

  const get30MinNearest = (t?: Moment): Moment => {
    const start = t ? t : moment();
    const remainder = 30 - (start.minute() % 30);

    const dateTime = moment(start).add(remainder, 'minutes');

    return dateTime;
  };

  const [combinedDateTime, setCombinedDateTime] = useState<Moment>(get30MinNearest());

  const setDateTime = (dateTime: Moment, type: 'date' | 'time', onChange?: Function) => {
    // eslint-disable-next-line prefer-const

    const nd = combinedDateTime?.clone();
    if (type == 'time') {
      nd?.set('date', moment(temp || defaultValue).get('date'));
      nd?.set('month', moment(temp ||  defaultValue).get('month'));
      nd?.set('year', moment(temp || defaultValue).get('year'));
      nd?.set('hours', dateTime.get('hours'));
      nd?.set('minutes', dateTime.get('minutes'));
      nd?.set('seconds', +0);
      setTemp(nd);
    } else {
      nd?.set('date', dateTime.get('date'));
      nd?.set('month', dateTime.get('month'));
      nd?.set('year', dateTime.get('year'));
      nd?.set('hours', moment(temp || defaultValue).get('hours'));
      nd?.set('minutes', moment(temp || defaultValue).get('minutes'));
      nd?.set('seconds', +0);
      setTemp(nd);
    }
    if (onChange) onChange(nd?.format());
    if (moment(nd).isValid()) setCombinedDateTime(nd);
    return nd;
  };

  const propsTime = (onChange: Function, value: any) => {
    if (!value) value = moment().format();
    return {
      defaultValue: moment(value),
      onChangeTime: (t: any) => {
        setDateTime(moment(t, 'HH:mm'), 'time', onChange);
      },
    };
  };
  return (
    <div>
      <div className="mr-4">
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || combinedDateTime}
          render={({ onChange, value }) => (
            <div className="flex space-x-4 flex-row">
              <DatePickerInput
                key={name + '_date'}
                defaultValue={value}
                displayFormat="YYYY-MM-DD"
                onChange={value => {
                  setDateTime(moment(value), 'date', onChange);
                }}
                showOnInputClick
                placeholder="Date"
                minDate={
                  props.minDate ? moment(props.minDate && props.minDate(props.formValues)) || moment() : undefined
                }
              />
              {timeRequired && <Time key={name + '_time'} {...propsTime(onChange, value)}></Time>}
            </div>
          )}
        ></Controller>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { StudentProgress } from './StudentProgress';
import { TitleMenu } from 'components/UI';
import { Students } from './Students';
import { Evaluations } from './Evaluations';
import { Attendance } from './Attendance';

export const StudentManagementDashboard = () => {
  const [currentView, setCurrentView] = useState<string>('students');
  return (
    <div className="content">
      <TitleMenu title="Student Management" />
      <div style={{ display: 'flex ', cursor: 'pointer' }}>
        <h3
          style={{
            marginLeft: '10px',
            textDecoration: currentView === 'students' ? 'underline' : 'none',
            textDecorationColor: currentView === 'students' ? '#324BD9' : 'none',
            textDecorationThickness: currentView === 'students' ? '5px' : '',
          }}
          onClick={() => setCurrentView('students')}
        >
          Students
        </h3>
        <h3
          style={{
            marginLeft: '10px',
            textDecoration: currentView === 'evaluations' ? 'underline' : 'none',
            textDecorationColor: currentView === 'evaluations' ? '#324BD9' : 'none',
            textDecorationThickness: currentView === 'evaluations' ? '5px' : '',
          }}
          onClick={() => setCurrentView('evaluations')}
        >
          Evaluations
        </h3>
        <h3
          style={{
            marginLeft: '10px',
            textDecoration: currentView === 'progress' ? 'underline' : 'none',
            textDecorationColor: currentView === 'progress' ? '#324BD9' : 'none',
            textDecorationThickness: currentView === 'progress' ? '5px' : '',
          }}
          onClick={() => setCurrentView('progress')}
        >
          Progress
        </h3>
        <h3
          style={{
            marginLeft: '10px',
            textDecoration: currentView === 'attendance' ? 'underline' : 'none',
            textDecorationColor: currentView === 'attendance' ? '#324BD9' : 'none',
            textDecorationThickness: currentView === 'attendance' ? '5px' : '',
          }}
          onClick={() => setCurrentView('attendance')}
        >
          Attendance
        </h3>
      </div>
      {currentView === 'progress' && <StudentProgress />}
      {currentView === 'students' && <Students />}
      {currentView === 'evaluations' && <Evaluations />}
      {currentView === 'attendance' && <Attendance />}
    </div>
  );
};

import Error from 'components/Error';
import { EXERCISE_API_URL } from 'constants/app';
import React, { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { requestJSON } from 'utils/request';
type TScores = {
  exercise_name: string;
  student_name: string;
  student_email: string;
  points_scored: number;
  max_points: number;
  percent_score: number | string;
};

export type TProps = {
  enrolledStudent: { id: number; email: string; name: string }[];
  exerciseId: { exerciseId: string[]; nameObj: {} };
  courseId: string;
};
export const StudentManagementTable = (props: any) => {
  const [scores, setScores] = useState<TScores[]>([]);
  const [error, setError] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const columns: TableColumn<TScores>[] = [
    {
      name: 'Exercise Name',
      selector: 'exercise_name',
      sortable: true,
    },
    {
      name: 'Student Name',
      selector: 'student_name',
      sortable: true,
    },
    {
      name: 'Student Name',
      selector: 'student_email',
      sortable: true,
    },
    {
      name: 'Points Scored',
      selector: 'points_scored',
      sortable: true,
    },
    {
      name: 'Max Points',
      selector: 'max_points',
      sortable: true,
    },
    {
      name: 'Percent Score',
      selector: 'percent_score',
    },
  ];
  const getStudentsName = (userId: number) => {
    const data = props.enrolledStudent.filter((a: any) => {
      return a.id === userId;
    });
    return data[0].name || '';
  };
  const getStudentsEmail = (userId: number) => {
    const data = props.enrolledStudent.filter((a: any) => {
      return a.id === userId;
    });
    return data[0].email || '';
  };
  const downloadFile = (data: any, fileName: string, fileType: string) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    setSubmitting(false);
  };
  const exportDataToCsv = (e: React.MouseEvent) => {
    setSubmitting(true);
    e.preventDefault();
    const headers = [
      'exercise_name',
      'student_name',
      'student_email',
      'points_scored',
      'max_points',
      'percent_score',
    ].join(',');
    const scoresCsv = scores.reduce((acc: string[], score) => {
      const { exercise_name, student_name, student_email, points_scored, max_points, percent_score } = score;
      acc.push([exercise_name, student_name, student_email, points_scored, max_points, percent_score].join(','));
      return acc;
    }, []);
    downloadFile([headers, ...scoresCsv].join('\n'), `${props.courseId}-evaluations.csv`, 'text/json');
    toast.success('Evaluations File Downloaded Successfully.');
  };
  const getStudentsEvaluation = async () => {
    const userIds = props.enrolledStudent.map((a: any) => {
      return a.id;
    });
    if (userIds.length !== 0) {
      const response = await requestJSON(
        `${EXERCISE_API_URL}/ta/exercise/evaluations?exerciseIds=${props.exerciseId.exerciseId.join(',')}`,
        {
          credentials: 'header',
          method: 'POST',
          body: JSON.stringify({ exerciseIds: props.exerciseId.exerciseId.join(','), userIds: userIds }),
          headers: { 'content-type': 'application/json' },
        }
      );
      if (!response.success) {
        toast.error('Failed to get Students Evaluations');

        return setError(response.error);
      }
      if (response.data) {
        const evaluations = (response.data as any) || [];
        const score = [];
        for (let i = 0; i < evaluations.length; i++) {
          const exerciseName = (props.exerciseId.nameObj as any)[evaluations[i]._id] || '';
          const evals = evaluations[i].evaluations || [];
          for (let k = 0; k < evals.length; k++) {
            score.push({
              exercise_name: exerciseName,
              student_name: getStudentsName(evals[k].user_id),
              student_email: getStudentsEmail(evals[k].user_id),
              points_scored: evals[k].evaluation.points_scored,
              max_points: evals[k].evaluation.max_points,
              percent_score: evals[k].evaluation.percent.toFixed(2),
            });
          }
        }
        setScores(score);
      } else {
        toast.error('Failed to get Students Evaluations');
      }
    } else {
    }
  };
  useEffect(() => {
    getStudentsEvaluation();
  }, []);
  if (error) return <Error embed preset="nothing" text={error} />;
  return (
    <>
      <button disabled={submitting || props.loading} onClick={e => exportDataToCsv(e)}>
        Download Student Evaluations
      </button>
      <DataTable
        columns={columns}
        data={scores}
        progressPending={props.loading}
        pagination={true}
        paginationPerPage={20}
        paginationComponentOptions={{ noRowsPerPage: true }}
      />
    </>
  );
};

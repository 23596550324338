/// <reference types="@emotion/react/types/css-prop" />
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Suspense from 'fragments/Suspense';
import Preloader from 'components/Preloader';
import MaybeRedirect from 'components/Preloader/MaybeRedirect';
import Navbar from 'components/Navbar';
import 'styles/App.css';
import 'styles/App.scss.css';

import {
  isNotDev,
  APP_BASENAME,
  CREATE_EXERCISE_PATH,
  CREATE_QUESTION_PATH,
  EXERCISE_PATH,
  POPULATE_EXERCISE_PATH,
  PUBLISH_EXERCISE_PATH,
  CLASS_PATH,
  SCHEDULE_EVENTS_PATH,
  CLASS_PATH_CREATE,
  SCHEDULE_EVENTS_CREATE_PATH,
  QUESTION_PATH,
  MEETUPS_PATH,
  SCHEDULE_MEETUP_CREATE_PATH,
  SCHEDULE_BANNER_CREATE_PATH,
  HACKATHON_LIST_PATH,
  HACKATHON_CREATE_PATH,
  MAIL_EDITOR_PATH,
  COURSE_ROUTER_CREATE_PATH,
  COURSE_ROUTER_LIST_PATH,
  PROGRAM_ROUTER_CREATE_PATH,
  PROGRAM_ROUTER_LIST_PATH,
  PRODUCT_ROUTER_CREATE_PATH,
  PRODUCT_ROUTER_LIST_PATH,
  CERTIFICATE_ROUTER_CREATE_PATH,
  CERTIFICATE_ROUTER_LIST_PATH,
  CERTIFICATE_ROUTER_BULK_UPLOAD_PATH,
  APPLICATION_ROUTER_LIST_PATH,
  PROGRAM_MAP_PATH,
  PROGRAM_MAPPING_PATH,
  SUBSCRIPTION_ROUTER_LIST_PATH,
  COHORTS_LIST_PATH,
  COUPONS_LIST_PATH,
  REFERRALS_LIST_PATH,
  GLOBAL_SLOT_LIST_PATH,
  GLOBAL_SLOT_CREATE_PATH,
  COHORTS_CREATE_PATH,
  COUPON_CREATE_PATH,
  USER_LIST_PATH,
  CLASS_BULK_UPLOAD_PATH,
  JOBS_CREATE_PATH,
  JOBS_LIST_PATH,
  JOB_APPLICATION_LIST_PATH,
  // REFERRALS_CREATE_PATH,
  USER_AGENT_LIST_PATH,
  USER_AGENT_CREATE_PATH,
  FEEDBACK_LIST_PATH,
  CMS_COURSE_PATH,
  CMS_LESSON_PATH,
  CMS_UPLOAD_COURSE_ZIP_PATH,
  APPLICATION_PROCESS_PATH,
  CREATE_APPLICATION_PROCESS_PATH,
  QUESTIONNAIRE_PATH,
  CREATE_QUESTIONNAIRE_PATH,
  TOPICS_PATH,
  STUDENT_MANAGEMENT_PATH,
  SUBMISSIONS_PATH,
  SUBMISSIONS_CREATE_PATH,
  STUDENT_SUBMISSION_LIST_PATH,
  COURSE_SUBMISSIONS_PATH,
  SUBMISSION_EVALUATION_LIST_PATH,
  STUDENT_MANAGEMENT_DASHBOARD,
  STUDENT_MANAGEMENT_EVALUATION_STUDENT_PATH,
} from 'constants/app';

import 'styles/App.scss.css';
import Topics from 'components/Topics';
import { StudentManagement } from 'components/StudentManagement';
import Submissions from 'components/Submissions';
import CreateSubmissions from 'components/Submissions/CreateSubmissions';
import StudentSubmissionList from 'components/Submissions/StudentSubmissionList';
import { StudentEvaluationList } from 'components/Submissions/StudentEvaluationsList';
import { StudentManagementDashboard } from 'components/StudentManagementDashboard/index';
import StudentEvaluationDetail from 'components/StudentManagementDashboard/StudentEvaluationDetail';

toast.configure({
  // autoClose: 8000,
});

const Home = React.lazy(() => import('components/Home'));
const MailEditor = React.lazy(() => import('components/ScheduledEvents/MailEditor'));
const CreateMeetup = React.lazy(() => import('components/Meetup/Create'));
const CreateBanner = React.lazy(() => import('components/Meetup/Banner'));
const Hackathon = React.lazy(() => import('components/Hackathon'));
const CreateHakathon = React.lazy(() => import('components/Hackathon/Create'));
const CreateCourse = React.lazy(() => import('components/Course/CreateCourse'));
const CoursesList = React.lazy(() => import('components/Course/CoursesList'));
const CreateProgram = React.lazy(() => import('components/Program/CreateProgram'));
const CreateProduct = React.lazy(() => import('components/Product/CreateProduct'));
const ProductList = React.lazy(() => import('components/Product/ProductList'));
const CreateCertificate = React.lazy(() => import('components/Certificate/CreateCertificate'));
const CertificateList = React.lazy(() => import('components/Certificate/CertificateList'));
const CertificateBulkUpload = React.lazy(() => import('components/Certificate/BulkUploadCertificate'));
const SubscriptionList = React.lazy(() => import('components/Subscription/index'));
const Exercise = React.lazy(() => import('components/Exercise'));
const Question = React.lazy(() => import('components/Question'));
const CreateExercise = React.lazy(() => import('components/Exercise/Create'));
const PopulateExercise = React.lazy(() => import('components/Exercise/Populate'));
const PublishExercise = React.lazy(() => import('components/Exercise/Publish'));
const CreateQuestion = React.lazy(() => import('components/Question/Create'));
const Events = React.lazy(() => import('components/ScheduledEvents'));
const Meetups = React.lazy(() => import('components/Meetup'));
const TAClass = React.lazy(() => import('components/Classes'));
const TAClassCreate = React.lazy(() => import('components/Classes/Create'));
const ClassBulkUpload = React.lazy(() => import('components/Classes/BulkUploadClasses'));
const TAEventCreate = React.lazy(() => import('components/ScheduledEvents/Create'));
const PCMapper = React.lazy(() => import('components/Program/index'));
const Mapping = React.lazy(() => import('components/Program/PCmapping'));
const Application = React.lazy(() => import('components/Application/index'));
const ApplicationProcess = React.lazy(() => import('components/ApplicationProcess/index'));
const CreateApplicationProcess = React.lazy(() => import('components/ApplicationProcess/CreateApplicationProcess'));
const Questionnaire = React.lazy(() => import('components/Questionnaire/index'));
const CreateQuestionnaire = React.lazy(() => import('components/Questionnaire/CreateQuestionnaire'));
const Cohorts = React.lazy(() => import('components/Cohorts'));
const CreateCohort = React.lazy(() => import('components/Cohorts/CreateCohort'));
const Coupons = React.lazy(() => import('components/Coupons'));
const CreateCoupon = React.lazy(() => import('components/Coupons/CreateCoupon'));
const Referrals = React.lazy(() => import('components/Referrals'));
const Users = React.lazy(() => import('components/User'));
const GlobatSlotList = React.lazy(() => import('components/GlobalSlot'));
const CreateGlobalSlot = React.lazy(() => import('components/GlobalSlot/CreateGlobalSlot'));
const JobsList = React.lazy(() => import('components/Jobs/JobsList'));
const CreateJob = React.lazy(() => import('components/Jobs/CreateJobs'));
const JobApplicationList = React.lazy(() => import('components/Jobs/Applications/ApplicationList'));
const UserAgentList = React.lazy(() => import('components/UserAgent'));
const CreateUserAgent = React.lazy(() => import('components/UserAgent/UACreate'));
const CMS = React.lazy(() => import('components/CMS'));
const LessonContent = React.lazy(() => import('components/CMS/Section'));
const CMSCourseBulkUpload = React.lazy(() => import('components/CMS/UploadCourseZipForm'));
const FeedbackList = React.lazy(() => import('components/Feedback'));
function App() {
  return (
    <Preloader>
      <Router {...(isNotDev ? { basename: APP_BASENAME } : {})}>
        <div className="flex flex-column max-w-screen-4xl mx-auto">
          <Navbar />
          <Switch>
            <Route exact path="/">
              <Suspense>
                <Home />
              </Suspense>
            </Route>
            <Route exact path={COURSE_ROUTER_LIST_PATH}>
              <Suspense>
                <CoursesList />
              </Suspense>
            </Route>
            <Route exact path={COURSE_ROUTER_CREATE_PATH}>
              <Suspense>
                <CreateCourse />
              </Suspense>
            </Route>
            <Route exact path={PROGRAM_ROUTER_CREATE_PATH}>
              <Suspense>
                <CreateProgram />
              </Suspense>
            </Route>
            <Route exact path={PROGRAM_ROUTER_LIST_PATH}>
              <Suspense>
                <PCMapper />
              </Suspense>
            </Route>
            <Route exact path={PRODUCT_ROUTER_LIST_PATH}>
              <Suspense>
                <ProductList />
              </Suspense>
            </Route>
            <Route exact path={PRODUCT_ROUTER_CREATE_PATH}>
              <Suspense>
                <CreateProduct />
              </Suspense>
            </Route>
            <Route exact path={CERTIFICATE_ROUTER_LIST_PATH}>
              <Suspense>
                <CertificateList />
              </Suspense>
            </Route>
            <Route exact path={CERTIFICATE_ROUTER_BULK_UPLOAD_PATH}>
              <Suspense>
                <CertificateBulkUpload />
              </Suspense>
            </Route>
            <Route exact path={CERTIFICATE_ROUTER_CREATE_PATH}>
              <Suspense>
                <CreateCertificate />
              </Suspense>
            </Route>
            <Route exact path={SUBSCRIPTION_ROUTER_LIST_PATH}>
              <Suspense>
                <SubscriptionList />
              </Suspense>
            </Route>
            <Route exact path={CREATE_EXERCISE_PATH}>
              <Suspense>
                <CreateExercise />
              </Suspense>
            </Route>
            <Route exact path={EXERCISE_PATH}>
              <Suspense>
                <Exercise />
              </Suspense>
            </Route>
            <Route exact path={PUBLISH_EXERCISE_PATH}>
              <Suspense>
                <PublishExercise />
              </Suspense>
            </Route>
            <Route exact path={QUESTION_PATH}>
              <Suspense>
                <Question />
              </Suspense>
            </Route>
            <Route path={`${CREATE_QUESTION_PATH}/:type?`}>
              <Suspense>
                <CreateQuestion />
              </Suspense>
            </Route>
            <Route exact path={POPULATE_EXERCISE_PATH}>
              <Suspense>
                <PopulateExercise />
              </Suspense>
            </Route>
            <Route exact path={CLASS_PATH_CREATE}>
              <Suspense>
                <TAClassCreate />
              </Suspense>
            </Route>
            <Route exact path={CLASS_BULK_UPLOAD_PATH}>
              <Suspense>
                <ClassBulkUpload />
              </Suspense>
            </Route>
            <Route exact path={CLASS_PATH}>
              <Suspense>
                <TAClass />
              </Suspense>
            </Route>
            <Route exact path={SCHEDULE_EVENTS_PATH}>
              <Suspense>
                <Events />
              </Suspense>
            </Route>
            <Route exact path={SCHEDULE_EVENTS_CREATE_PATH}>
              <Suspense>
                <TAEventCreate />
              </Suspense>
            </Route>
            <Route exact path={TOPICS_PATH}>
              <Suspense>
                <Topics />
              </Suspense>
            </Route>
            <Route exact path={SUBMISSIONS_PATH}>
              <Suspense>
                <Submissions />
              </Suspense>
            </Route>
            <Route exact path={COURSE_SUBMISSIONS_PATH}>
              <Suspense>
                <Submissions />
              </Suspense>
            </Route>
            <Route exact path={SUBMISSION_EVALUATION_LIST_PATH}>
              <Suspense>
                <StudentEvaluationList />
              </Suspense>
            </Route>
            <Route exact path={MEETUPS_PATH}>
              <Suspense>
                <Meetups />
              </Suspense>
            </Route>
            <Route exact path={SCHEDULE_MEETUP_CREATE_PATH}>
              <Suspense>
                <CreateMeetup />
              </Suspense>
            </Route>
            <Route exact path={SUBMISSIONS_CREATE_PATH}>
              <Suspense>
                <CreateSubmissions />
              </Suspense>
            </Route>
            <Route exact path={STUDENT_SUBMISSION_LIST_PATH}>
              <Suspense>
                <StudentSubmissionList />
              </Suspense>
            </Route>
            <Route exact path={SCHEDULE_BANNER_CREATE_PATH}>
              <Suspense>
                <CreateBanner />
              </Suspense>
            </Route>
            <Route exact path={GLOBAL_SLOT_LIST_PATH}>
              <Suspense>
                <GlobatSlotList />
              </Suspense>
            </Route>
            <Route exact path={GLOBAL_SLOT_CREATE_PATH}>
              <Suspense>
                <CreateGlobalSlot />
              </Suspense>
            </Route>
            <Route exact path={JOBS_LIST_PATH}>
              <Suspense>
                <JobsList />
              </Suspense>
            </Route>
            <Route exact path={JOBS_CREATE_PATH}>
              <Suspense>
                <CreateJob />
              </Suspense>
            </Route>
            <Route path={JOB_APPLICATION_LIST_PATH}>
              <Suspense>
                <JobApplicationList />
              </Suspense>
            </Route>
            <Route exact path={COHORTS_LIST_PATH}>
              <Suspense>
                <Cohorts />
              </Suspense>
            </Route>
            <Route exact path={COHORTS_CREATE_PATH}>
              <Suspense>
                <CreateCohort />
              </Suspense>
            </Route>
            <Route exact path={COUPONS_LIST_PATH}>
              <Suspense>
                <Coupons />
              </Suspense>
            </Route>
            <Route exact path={COUPON_CREATE_PATH}>
              <Suspense>
                <CreateCoupon />
              </Suspense>
            </Route>
            <Route exact path={REFERRALS_LIST_PATH}>
              <Suspense>
                <Referrals />
              </Suspense>
            </Route>
            <Route exact path={USER_LIST_PATH}>
              <Suspense>
                <Users />
              </Suspense>
            </Route>
            {/* <Route exact path={REFERRALS_CREATE_PATH}>
              <Suspense>
                <CreateReferral />
              </Suspense>
            </Route> */}
            <Route exact path={HACKATHON_LIST_PATH}>
              <Suspense>
                <Hackathon />
              </Suspense>
            </Route>
            <Route exact path={HACKATHON_CREATE_PATH}>
              <Suspense>
                <CreateHakathon />
              </Suspense>
            </Route>
            <Route exact path={MAIL_EDITOR_PATH}>
              <Suspense>
                <MailEditor />
              </Suspense>
            </Route>
            <Route exact path={PROGRAM_MAP_PATH}>
              <Suspense>
                <PCMapper />
              </Suspense>
            </Route>
            <Route exact path={PROGRAM_MAPPING_PATH}>
              <Suspense>
                <Mapping />
              </Suspense>
            </Route>
            <Route exact path={APPLICATION_ROUTER_LIST_PATH}>
              <Suspense>
                <Application />
              </Suspense>
            </Route>
            <Route exact path={APPLICATION_PROCESS_PATH}>
              <Suspense>
                <ApplicationProcess />
              </Suspense>
            </Route>
            <Route exact path={CREATE_APPLICATION_PROCESS_PATH}>
              <Suspense>
                <CreateApplicationProcess rows={[]} title="" description="" rowId="" type="create" />
              </Suspense>
            </Route>
            <Route exact path={USER_AGENT_LIST_PATH}>
              <Suspense>
                <UserAgentList />
              </Suspense>
            </Route>
            <Route exact path={USER_AGENT_CREATE_PATH}>
              <Suspense>
                <CreateUserAgent />
              </Suspense>
            </Route>
            <Route exact path={CMS_COURSE_PATH}>
              <Suspense>
                <CMS />
              </Suspense>
            </Route>
            <Route exact path={CMS_LESSON_PATH}>
              <Suspense>
                <LessonContent />
              </Suspense>
            </Route>

            <Route exact path={CMS_UPLOAD_COURSE_ZIP_PATH}>
              <Suspense>
                <CMSCourseBulkUpload />
              </Suspense>
            </Route>
            <Route exact path={FEEDBACK_LIST_PATH}>
              <Suspense>
                <FeedbackList />
              </Suspense>
            </Route>
            <Route exact path={QUESTIONNAIRE_PATH}>
              <Suspense>
                <Questionnaire />
              </Suspense>
            </Route>
            <Route exact path={CREATE_QUESTIONNAIRE_PATH}>
              <Suspense>
                <CreateQuestionnaire rows={[]} title="" description="" rowId="" type="create" pages="" />
              </Suspense>
            </Route>
            <Route exact path={STUDENT_MANAGEMENT_PATH}>
              <Suspense>
                <StudentManagement />
              </Suspense>
            </Route>
            <Route exact path={STUDENT_MANAGEMENT_DASHBOARD}>
              <Suspense>
                <StudentManagementDashboard />
              </Suspense>
            </Route>
            <Route exact path={STUDENT_MANAGEMENT_EVALUATION_STUDENT_PATH}>
              <Suspense>
                <StudentEvaluationDetail />
              </Suspense>
            </Route>
          </Switch>
        </div>
        <MaybeRedirect />
      </Router>
    </Preloader>
  );
}

export default App;

import { SimpleTable } from 'components/Table/simple-table';
import { TA_BACKEND_URL } from 'constants/app';
import React, { useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { requestJSON } from 'utils/request';
import { arrayToCSV } from './helpers';
import Modal from 'fragments/Modal';
import NotebookDetail from './NotebookDetail';
type Tprops = {
  courseId: string;
};
const Assignments = (props: Tprops) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [assignmentList, SetAssignmentList] = useState<{ id: number; title: string }[]>([]);
  const [error, setError] = useState('');
  const [evaluations, setEvaluations] = useState<any>([]);
  const [selectedId, setSelectedId] = useState(0);
  const [evalutaionsCounts, SetEvaluationsCounts] = useState<number>(0);
  const [pageNum, setPageNum] = useState<number>(1);
  const [downloading, setDownloading] = useState<boolean>(false);
  const getCourseAssignments = async () => {
    setLoading(true);
    const response = await requestJSON(`${TA_BACKEND_URL}/course-assignments?courseId=${props.courseId}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      setLoading(false);
      SetAssignmentList([]);
      toast.error('Unable to fetch  list.');
      return setError(response.error);
    }
    if (response.data) {
      const fetchData = response.data.data as { id: number; title: string }[];
      SetAssignmentList(fetchData);
    } else {
      toast.error('Unable to fetch  list.');
    }
    setLoading(false);
  };
  const getEvaluations = async () => {
    setLoading(true);
    const response = await requestJSON(
      `${TA_BACKEND_URL}/course-assignments-evaluations?pageNum=${pageNum}&assignmentId=${selectedId}`,
      {
        credentials: 'header',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      }
    );
    if (!response.success) {
      setLoading(false);
      SetAssignmentList([]);
      toast.error('Unable to fetch  list.');
      return setError(response.error);
    }
    if (response.data) {
      const fetchData = response.data.data as any;
      setEvaluations(fetchData.data);
      SetEvaluationsCounts(+fetchData.count);
    } else {
      toast.error('Unable to get evaluations list.');
    }
    setLoading(false);
  };
  const columns: TableColumn<any>[] = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Marks',
      selector: 'marks',
      sortable: true,
    },
    {
      name: 'Grade',
      selector: 'grade',
      sortable: true,
    },
  ];
  useEffect(() => {
    if (selectedId !== 0) getEvaluations();
  }, [selectedId, pageNum]);
  useEffect(() => {
    getCourseAssignments();
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedId(+e.target.value);
    setPageNum(1);
  };
  const downloadFile = (data: any, fileName: string, fileType: string) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    setDownloading(false);
  };
  const downloadCsv = (data: any) => {
    setDownloading(true);
    const assignmentName = assignmentList.filter(a => {
      if (+a.id === selectedId) return a;
    })[0].title;
    const csvData = arrayToCSV(data);
    downloadFile(csvData, `${assignmentName}-${props.courseId}.csv`, 'text/json');
  };
  return (
    <div style={{ marginLeft: '10px', marginTop: '10px' }}>
      {assignmentList?.length !== 0 ? (
        <>
          <h3>Select the Assignment from below list to view its evaluations</h3>

          <select
            style={{ width: '350px' }}
            id="select-dropdown"
            defaultValue={selectedId}
            onChange={e => {
              handleChange(e);
            }}
          >
            <option defaultValue={selectedId}>Select Assignment</option>
            {assignmentList.map((a: any) => {
              return (
                <option key={a.id} value={a.id}>
                  {a.title}
                </option>
              );
            })}
          </select>
          {selectedId != 0 ? (
            <div style={{ marginTop: '5px' }}>
              <button
                disabled={evaluations.length === 0 || downloading}
                onClick={() => {
                  downloadCsv(evaluations);
                }}
              >
                Download Assignment Evaluation
              </button>
              <SimpleTable
                columns={columns}
                data={evaluations}
                progressPending={loading}
                pagination={true}
                paginationTotalRows={evaluations?.length}
                paginationPerPage={20}
                noRowsPerPage={true}
              ></SimpleTable>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <h3>No Assignments for the course</h3>
        </>
      )}
    </div>
  );
};
export type TEvaluation = {
  id: string;
  user_id: number;
  name: string;
  email: string;
  title: string;
  points_scored: number;
  max_points: number;
  percent: number;
};
const Exercises = (props: Tprops) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [exercises, setExercises] = useState<any>([]);
  const [selectedId, setSelectedId] = useState('');
  const [exerciseEvaluations, SetExerciseEvaluations] = useState<any>([]);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<string>('');
  const getCourseExercises = async () => {
    setLoading(true);
    const response = await requestJSON(`${TA_BACKEND_URL}/course-exercises?courseId=${props.courseId}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      setLoading(false);
      setExercises([]);
      toast.error('Unable to fetch  list.');
      return setError(response.error);
    }
    if (response.data) {
      const fetchData = response.data.data as { id: number; title: string }[];
      setExercises(fetchData);
    } else {
      toast.error('Unable to fetch  list.');
    }
    setLoading(false);
  };
  const getEvaluations = async () => {
    setLoading(true);
    const response = await requestJSON(
      `${TA_BACKEND_URL}/course-exercises-evaluations?&exerciseId=${selectedId}&courseId=${props.courseId}`,
      {
        credentials: 'header',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      }
    );
    if (!response.success) {
      setLoading(false);
      SetExerciseEvaluations([]);
      toast.error('Unable to fetch  list.');
      return setError(response.error);
    }
    if (response.data) {
      const fetchData = response.data.data as any;
      SetExerciseEvaluations(fetchData);
    } else {
      toast.error('Unable to get evaluations list.');
    }
    setLoading(false);
  };

  const columns: TableColumn<TEvaluation>[] = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      format: (row: TEvaluation) => (
        <Link
          className="cursor-pointer"
          to={`${props.courseId}/student/${row.user_id}/${row.name.trim().replace(/ /g, '_')}/${selectedId}?name=${
            row.name
          }`}
        >
          {row.name}
        </Link>
      ),
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Points Scored',
      selector: 'points_scored',
      sortable: true,
    },
    {
      name: 'Max Points',
      selector: 'max_points',
      sortable: true,
    },
    {
      name: 'Percent',
      selector: 'percent',
      sortable: true,
    },
  ];
  useEffect(() => {
    if (selectedId !== '') getEvaluations();
  }, [selectedId]);
  useEffect(() => {
    getCourseExercises();
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedId(e.target.value);
  };
  const downloadFile = (data: any, fileName: string, fileType: string) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    setDownloading(false);
  };
  const downloadCsv = (data: any) => {
    setDownloading(true);
    const exerciseName = exercises.filter((a: any) => {
      if (a.id === selectedId) return a;
    })[0].title;
    const csvData = arrayToCSV(data);
    downloadFile(csvData, `${exerciseName}-${props.courseId}.csv`, 'text/json');
  };
  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParam(e.target.value);
  };

  return (
    <div style={{ marginLeft: '10px', marginTop: '10px' }}>
      {exercises?.length !== 0 ? (
        <>
          <h3>Select the Assignment from below list to view its evaluations</h3>

          <select
            style={{ width: '350px' }}
            id="select-dropdown"
            defaultValue={selectedId}
            onChange={e => {
              handleChange(e);
            }}
          >
            <option defaultValue={selectedId}>Select Exercise</option>
            {exercises?.map((a: any) => {
              return (
                <option key={a.id} value={a.id}>
                  {a.title}
                </option>
              );
            })}
          </select>
          {selectedId != '' ? (
            <div style={{ marginTop: '5px' }}>
              <button
                disabled={exerciseEvaluations.length === 0 || downloading}
                onClick={() => {
                  downloadCsv(exerciseEvaluations);
                }}
              >
                Download Exercise Evaluation
              </button>
              <div className="flex justify-between items-center mb-2">
                <div className="flex-shrink">
                  <p className="p-2">Search</p>
                  <div>
                    <input type="text" placeholder="Search" onChange={searchHandler}></input>
                  </div>
                </div>
              </div>
              <SimpleTable
                columns={columns}
                data={
                  searchParam
                    ? exerciseEvaluations.filter(
                        (row: any) => row.name.includes(searchParam) || row.email.includes(searchParam)
                      )
                    : exerciseEvaluations
                }
                progressPending={loading}
                paginationPerPage={20}
                noRowsPerPage={true}
                pagination={true}
                paginationTotalRows={exerciseEvaluations?.length}
              ></SimpleTable>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
const Notebooks = (props: Tprops) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [exercises, setExercises] = useState<any>([]);
  const [selectedId, setSelectedId] = useState('');
  const [exerciseEvaluations, SetExerciseEvaluations] = useState<any>([]);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [submissionId, setSubmissionId] = useState<string>();
  const [searchParam, setSearchParam] = useState<string>('');

  const getCourseExercises = async () => {
    setLoading(true);
    const response = await requestJSON(`${TA_BACKEND_URL}/course-notebook-exercises?courseId=${props.courseId}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      setLoading(false);
      setExercises([]);
      toast.error('Unable to fetch  list.');
      return setError(response.error);
    }
    if (response.data) {
      const fetchData = response.data.data as { id: number; title: string }[];
      setExercises(fetchData);
    } else {
      toast.error('Unable to fetch  list.');
    }
    setLoading(false);
  };
  const getEvaluations = async () => {
    setLoading(true);
    const response = await requestJSON(
      `${TA_BACKEND_URL}/course-notebook-evaluations?&exerciseId=${selectedId}&courseId=${props.courseId}`,
      {
        credentials: 'header',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      }
    );
    if (!response.success) {
      setLoading(false);
      SetExerciseEvaluations([]);
      toast.error('Unable to fetch  list.');
      return setError(response.error);
    }
    if (response.data) {
      const fetchData = response.data.data as any;
      SetExerciseEvaluations(fetchData);
    } else {
      toast.error('Unable to get evaluations list.');
    }
    setLoading(false);
  };
  function openNotebookDetailModal(id: string) {
    setSubmissionId(id);
  }
  const columns: TableColumn<any>[] = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      format: (row: TEvaluation) => (
        <span
          className="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"
          onClick={() => {
            openNotebookDetailModal(row.id);
          }}
        >
          {row.name}
        </span>
      ),
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Points Scored',
      selector: 'points_scored',
      sortable: true,
    },
    {
      name: 'Max Points',
      selector: 'max_points',
      sortable: true,
    },
    {
      name: 'Percent',
      selector: 'percent',
      sortable: true,
    },
    {
      name: 'Late Submission',
      selector: 'late_submission',
      sortable: true,
    },
    {
      name: 'Submitted On',
      selector: 'timestamp',
      sortable: true,
    },
  ];
  useEffect(() => {
    if (selectedId !== '') getEvaluations();
  }, [selectedId]);
  useEffect(() => {
    getCourseExercises();
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedId(e.target.value);
  };
  const downloadFile = (data: any, fileName: string, fileType: string) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    setDownloading(false);
  };
  const downloadCsv = (data: any) => {
    setDownloading(true);
    const exerciseName = exercises.filter((a: any) => {
      if (a.id === selectedId) return a;
    })[0].title;
    const csvData = arrayToCSV(data);
    downloadFile(csvData, `${exerciseName}-${props.courseId}.csv`, 'text/json');
  };
  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParam(e.target.value);
  };

  return (
    <div style={{ marginLeft: '10px', marginTop: '10px' }}>
      {exercises?.length !== 0 ? (
        <>
          <h3>Select the Notebook exercise from below list to view its evaluations</h3>

          <select
            style={{ width: '350px' }}
            id="select-dropdown"
            defaultValue={selectedId}
            onChange={e => {
              handleChange(e);
            }}
          >
            <option defaultValue={selectedId}>Select Notebook Exercise</option>
            {exercises?.map((a: any) => {
              return (
                <option key={a.id} value={a.id}>
                  {a.title}
                </option>
              );
            })}
          </select>
          {selectedId != '' ? (
            <div style={{ marginTop: '5px' }}>
              <button
                disabled={exerciseEvaluations.length === 0 || downloading}
                onClick={() => {
                  downloadCsv(exerciseEvaluations);
                }}
              >
                Download Exercise Evaluation
              </button>
              <div className="flex justify-between items-center mb-2">
                <div className="flex-shrink">
                  <p className="p-2">Search</p>
                  <div>
                    <input type="text" placeholder="Search" onChange={searchHandler}></input>
                  </div>
                </div>
              </div>
              <SimpleTable
                columns={columns}
                data={
                  searchParam
                    ? exerciseEvaluations.filter(
                        (row: any) => row.name.includes(searchParam) || row.email.includes(searchParam)
                      )
                    : exerciseEvaluations
                }
                progressPending={loading}
                paginationPerPage={20}
                noRowsPerPage={true}
                pagination={true}
                paginationTotalRows={exerciseEvaluations?.length}
              ></SimpleTable>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      <Modal
        isOpen={!!submissionId}
        close={() => {
          setSubmissionId(undefined);
        }}
        size="md"
      >
        {submissionId ? <NotebookDetail submissionId={submissionId} /> : <></>}
      </Modal>
    </div>
  );
};
export const Evaluations = () => {
  const [currentView, setCurrentView] = useState<string>('assignments');
  const { courseId } = useParams<Tprops>();
  const [loading, setLoading] = useState<boolean>(false);
  const [allEvaluations, SetAllEvaluations] = useState<any>(false);
  const [error, setError] = useState<string>('');
  const [downloading, setDownloading] = useState<boolean>(false);
  const downloadFile = (data: any, fileName: string, fileType: string) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    setDownloading(false);
  };
  const downloadCsv = (data: any) => {
    setDownloading(true);
    downloadFile(data, `${courseId}-all-evaluations.csv`, 'text/json');
  };
  const getAllEvaluations = async () => {
    setLoading(true);
    const response = await requestJSON(`${TA_BACKEND_URL}/course-all-evaluations?courseId=${courseId}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      setLoading(false);
      SetAllEvaluations([]);
      toast.error('Unable to fetch  list.');
      return setError(response.error);
    }
    if (response.data) {
      const fetchData = response.data.data as any;
      SetAllEvaluations(fetchData);
      downloadCsv(fetchData);
    } else {
      toast.error('Unable to fetch  list.');
    }
    setLoading(false);
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', cursor: 'pointer' }}>
        <h3
          style={{
            marginLeft: '10px',
            textDecoration: currentView === 'assignments' ? 'underline' : 'none',
            textDecorationColor: currentView === 'assignments' ? '#324BD9' : 'none',
            textDecorationThickness: currentView === 'assignments' ? '5px' : '',
          }}
          onClick={() => setCurrentView('assignments')}
        >
          Assignments
        </h3>
        <h3
          style={{
            marginLeft: '10px',
            textDecoration: currentView === 'exercises' ? 'underline' : 'none',
            textDecorationColor: currentView === 'exercises' ? '#324BD9' : 'none',
            textDecorationThickness: currentView === 'exercises' ? '5px' : '',
          }}
          onClick={() => setCurrentView('exercises')}
        >
          Exercises
        </h3>
        <h3
          style={{
            marginLeft: '10px',
            textDecoration: currentView === 'notebooks' ? 'underline' : 'none',
            textDecorationColor: currentView === 'notebooks' ? '#324BD9' : 'none',
            textDecorationThickness: currentView === 'notebooks' ? '5px' : '',
          }}
          onClick={() => setCurrentView('notebooks')}
        >
          Notebooks
        </h3>
      </div>
      <button style={{}} onClick={getAllEvaluations}>
        Downloand All Evaluations
      </button>
      <hr />
      {currentView === 'assignments' && <Assignments courseId={courseId} />}
      {currentView === 'exercises' && <Exercises courseId={courseId} />}
      {currentView === 'notebooks' && <Notebooks courseId={courseId} />}
    </div>
  );
};

import Error, { IError } from 'components/Error';
import { getLoginURL, getLogoutURL } from 'constants/app';
import React from 'react';
type TProps = {
  kind?: 'not TA' | 'unauth' | 'not admin';
};
export default function Unauth(props: TProps) {
  const { kind = 'not TA' } = props;
  let error: IError = {
    emoji: '( ˘︹˘ )',
    emotext: 'Not Authorized',
    text: 'You are not authorized',
    extrablock: 'You are not Authorized to access this service.Please contact the Administrator',
    allowBack: false,
  };
  if (kind == 'unauth') {
    error = {
      emotext: 'Login to see things here',
      emoji: '( ˘︹˘ )',
      text: 'Content on this page is based on what you do. Log in to do more!',
      allowBack: false,
      extrablock: (
        <div className="mt-4 flex justify-center">
          <a className="button is-white is-medium" href={getLoginURL(window.location.href)}>
            Login / Register
          </a>
        </div>
      ),
    };
  }
  if (kind == 'not admin') {
    error = {
      emotext: 'Looks like you are trying to access an admin page',
      emoji: '( ˘︹˘ )',
      text: 'Are you lost ?',
      allowBack: false,
      extrablock: (
        <div className="mt-4 flex justify-center">
          <a className="button is-white is-medium" href={getLogoutURL(window.location.href)}>
            Logout
          </a>
        </div>
      ),
    };
  }

  return (
    <div className="h-screen">
      <div style={{ textAlignLast: 'center' }}>
        <Error {...error}></Error>
      </div>
    </div>
  );
}

import React, { ChangeEventHandler } from 'react';
import { FaFileDownload } from 'react-icons/fa';

type Props = {
  inputRef: React.Ref<HTMLInputElement>;
  name: string;
  placeholder?: string;
  defaultValue: string;
  error?: string;
  html?: Element;
  disabled?: boolean;
  multiple?: boolean;
  changeHandler?: ChangeEventHandler<HTMLInputElement>;
  accept?: string;
};

export function FormFile(props: Props) {
  const { defaultValue, error, name, placeholder, inputRef, multiple = false, changeHandler, accept } = props;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeHandler && changeHandler(e);
  };

  return (
    <div className="flex items-center">
      <input
        className={`input ${error ? 'is-danger' : ''}`}
        name={name}
        ref={inputRef}
        placeholder={placeholder}
        type="file"
        defaultValue={''}
        multiple={multiple}
        onChange={e => onChange(e)}
        accept={accept}
      />
      {defaultValue && defaultValue != '' ? (
        <a href={defaultValue} target="_blank" className="ml-4">
          <FaFileDownload data-tooltip="View File" />
        </a>
      ) : (
        ''
      )}
    </div>
  );
}

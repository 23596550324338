import { TitleMenu } from 'components/UI';
import { TA_BACKEND_URL } from 'constants/app';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { requestJSON } from 'utils/request';
import Error from 'components/Error';
import { SimpleTable } from 'components/Table/simple-table';
import { TableColumn } from 'react-data-table-component';
import Modal from 'react-modal';
import { PRIMARY_BACKGROUND } from 'constants/colors';
import { FaCheckSquare, FaRegWindowClose } from 'react-icons/fa';

type RouteParams = {
  courseId: string;
};

export const StudentProgress = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [studentProgress, setStudentProgress] = useState<any>([]);
  const [selectedStudentData, setSelectedStudentData] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { courseId } = useParams<RouteParams>();
  const [searchParam, setSearchParam] = useState<string>('');

  let dynamicColumns: any = [];
  const [tableData, setTableData] = useState<any>([]);
  const [cols, setCols] = useState<any>([]);
  const getStudentProgress = async () => {
    setLoading(true);
    const response = await requestJSON(`${TA_BACKEND_URL}/student-progress?courseId=${courseId}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });

    if (!response.success) {
      setLoading(false);
      toast.error('Failed to get Students progress');
      return setError(response.error);
    }
    if (response.data) {
      const studentsProgress = response.data.data as any;
      dynamicColumns = (response.data.data as any)[0][0].completionData.map((a: any) => {
        if (a.type === 'about') return { name: 'Intro', selector: 'intro' };
        else return { name: a.title, selector: a.title.toLowerCase().split(' ').join('_') };
      });
      setCols(dynamicColumns);
      setStudentProgress(studentsProgress);
      const tableDate = (response.data.data as any).map((a: any) => {
        const obj: any = {};
        a[0].completionData.map((j: any) => {
          let key = '';
          if (j.type === 'about') {
            obj['intro'] = j.percent;
            return { intro: j.percent };
          } else {
            key = j.title.toLowerCase().split(' ').join('_');
            obj[key] = j.percent;
            return { [key]: j.percent };
          }
        });
        return { name: a[0].name, email: a[0].email, id: a[0].userId, ...obj };
      });
      setTableData(tableDate);
    } else {
      toast.error('Failed to get Student progress data');
    }
    setLoading(false);
  };
  const onRowClicked = (data: any) => {
    setSelectedStudentData(
      studentProgress.filter((a: any) => {
        return a[0].userId === data.id;
      })[0][0]
    );
    setShowModal(true);
  };

  const columns: TableColumn<any>[] = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    ...cols,
  ];

  useEffect(() => {
    getStudentProgress();
  }, []);
  const closeAllModals = () => {
    setShowModal(false);
  };
  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParam(e.target.value);
  };

  if (error) return <Error embed preset="nothing" text={error} />;
  return (
    <div className="content">
      <TitleMenu title="Student Progress" />
      <div className="flex justify-between items-center mb-2">
        <div className="flex-shrink">
          <p className="p-2">Search</p>
          <div>
            <input type="text" placeholder="Search" onChange={searchHandler}></input>
          </div>
        </div>
      </div>
      <SimpleTable
        columns={columns}
        data={
          searchParam
            ? tableData.filter((row: any) => row.name.includes(searchParam) || row.email.includes(searchParam))
            : tableData
        }
        progressPending={loading}
        pagination={true}
        paginationTotalRows={tableData?.length}
        paginationPerPage={20}
        noRowsPerPage={true}
        onRowClicked={onRowClicked}
      ></SimpleTable>
      <Modal
        isOpen={showModal}
        onRequestClose={closeAllModals}
        style={{
          overlay: {
            zIndex: 99999,
            backgroundColor: 'rgba(5, 10, 15, 0.9)',
          },
          content: {
            zIndex: 32,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflowY: 'scroll',
            backgroundColor: PRIMARY_BACKGROUND,
            height: 'maxContent',
            maxHeight: `70%`,
            width: '90%',
            maxWidth: `780px`,
          },
        }}
      >
        {' '}
        <div style={{ display: 'flex' }}>
          <div style={{ marginTop: '150px' }}>
            <h2>Student Details</h2>
            <h3>Name:{selectedStudentData.name}</h3>
            <h3>email:{selectedStudentData.email}</h3>
          </div>
          <div style={{ marginLeft: '150px' }}>
            <h2>Student Progress</h2>
            {selectedStudentData?.completionData?.map((a: any) => {
              if (a.type === 'about')
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h4>Introduction</h4>
                    <div style={{ marginLeft: '5px' }}>
                      {a.isComplete ? <FaCheckSquare color="green" /> : <FaRegWindowClose color="black" />}
                    </div>
                  </div>
                );
              if (a.title)
                return (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4>{a.title}</h4>
                    <div style={{ marginLeft: '10px' }}>
                      {a.sectionData.map((a: any) => {
                        if (a.header) return <h5>{a.header}</h5>;
                        return (
                          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                            <h6>{a.title}</h6>
                            <div style={{ marginLeft: '5px' }}>
                              {a.isComplete ? <FaCheckSquare color="green" /> : <FaRegWindowClose color="black" />}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import ClipLoader from 'react-spinners/ClipLoader';
import BarLoader from 'react-spinners/BarLoader';

interface IProps {
  color?: string;
  size?: string;
  show?: boolean;
  spaced?: boolean;
  bar?: boolean;
}

export default (props: IProps) => {
  const { color, size, bar = false, show = false, spaced = false } = props;
  const [isLoading, setIsLoading] = useState(show);
  let timeoutTracker: ReturnType<typeof setTimeout>;

  useEffect(() => {
    // do not show loader for a second
    timeoutTracker = setTimeout(() => setIsLoading(true), 1000);

    return () => {
      if (timeoutTracker) clearTimeout(timeoutTracker);
    };
  }, []);

  return (
    <div
      css={css`
      ${spaced ? 'margin-top: 10vh' : ''}
      align-items: center;
      display: flex;
      justify-content: center;
    `}
    >
      {bar ? (
        <BarLoader />
      ) : (
        <ClipLoader
          loading={isLoading}
          color={color || '#123abc'}
          size={size === 'tiny' ? 15 : size === 'small' ? 25 : 65}
        />
      )}
    </div>
  );
};

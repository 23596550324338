// import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
// import { TSignatoryIndices } from 'components/Certificate/Certificate';
// import React from 'react';
import { RegisterOptions } from 'react-hook-form';
import { StringMappingType } from 'typescript';

export type TColumn = {
  Header: string;
  accessor: string;
  type?: string;
};

export type TField = {
  fieldName: string;
  fieldId: string;
  fieldType: string;
  maxLength?: number;
  minLength?: number;
  foreignKey?: string;
  fkKey?: string;
  fkValue?: string;
  regex?: string;
  required: boolean;
  url?: string;
};
export type TDependent = { field: string; value: string | string[] };
export type TFormFields = {
  constraints?: RegisterOptions;
  dependentOn?: TDependent[];
  [key: string]: any;
};

export type TProcessedFormField = {
  name: string;
  label: string;
  type: string;
  constraints: RegisterOptions;
  disabled?: boolean;
  dependentOn?: TDependent[];
};

export enum EModalTypes {
  Edit = 'EDIT',
  Duplicate = 'DUPLICATE',
  Activate = 'ACTIVATE',
  Deactivate = 'DEACTIVATE',
  Map = 'MAP',
  Schedule = 'SCHEDULE',
  Feature = 'FEATURE',
  UnFeature = 'UNFEATURE',
  Delete = 'DELETE',
  Content = 'CONTENT',
  Resend = 'RESEND',
  ViewProfile = 'VIEWPROFILE',
  Hide = 'HIDE',
  Show = 'SHOW',
  Download = 'DOWNLOAD',
}

export enum EFilterTypes {
  All = 'ALL',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Search = 'SEARCH',
  Upcoming = 'UPCOMING',
}

export enum EQuestionDiffuculty {
  Easy = 'EASY',
  Medium = 'MEDIUM',
  Hard = 'HARD',
}

export type TQuestion = {
  _id: string;
  text: string;
  explanation: string;
  type: string;
  difficulty: EQuestionDiffuculty;
  question: { text: string; [key: string]: string };
};

export type TExercise = {
  _id: string;
  name: string;
  title: string;
  explanation?: string;
  published: boolean;
};

export type TTTAQuestion = TQuestion & {
  award_points: number;
  max_points: number;
  tags: TTATag[];
  [key: string]: any;
};

export type TTATag = {
  _id: string;
  text: string;
  name: string;
};

export type THackathon = {
  name: string;
  id: number;
};

export type TuploadSpaces = {
  data: {
    link: string;
    absLink: string;
  };
};
export type TApplication = {
  name: string;
  email: string;
  application_id: number;
  user_id: number;
  state: string;
  application_date: Date | string;
  product_id: number;
  'Product Name': string;
  last_activity_ts?: string | null;
  last_activity?: string | null;
  reference_id: number | string;
};
export type TCourse = {
  id: string;
  name: string;
  description: string;
  url: string;
  bannerurl: string;
  meta: {
    subtitle: string;
    requirements: string;
    duration: string;
    mode: string;
    color: string;
    discourseURL: string;
    slackURL: string;
    detailsURL: string;
    startDate: Date;
    active: boolean;
  };
  start_date: Date;
  end_date: Date;
  isChecked?: boolean;
};

export type TCourseFormData = {
  name: string;
  description: string;
  url: string;
  id: string;
  start_date: string;
  end_date: string;
  subtitle: string;
  duration: string;
  discourseURL: string;
  slackURL: string;
  detailsURL: string;
  requirements: string;
  mode: string;
  color: string;
  image: string;
};

export type TProgram = {
  id: string;
  name: string;
  description: string;
  url: string;
  bannerurl: string;
  meta: {
    subtitle: string;
    requirements: string;
    duration: string;
    mode: string;
    color: string;
    discourseURL: string;
    slackURL: string;
    detailsURL: string;
    active: boolean;
  };

  courses: { name: string; id: string }[];
  start_date?: Date;
  end_date?: Date;
  created_at?: Date;
};

export type TProgramFormData = {
  name: string;
  description: string;
  url: string;
  id: string;
  start_date: string;
  end_date: string;
  subtitle: string;
  duration: string;
  discourseURL: string;
  slackURL: string;
  detailsURL: string;
  requirements: string;
  mode: string;
  color: string;
  image: string;
  courses: { name: string; id: string }[];
};

export type TDateValidation = {
  error: boolean;
  message: string;
};

export type TProduct = {
  id: number;
  cp_id: string;
  name: string;
  description: string;
  discountedrate: string | null | number | { [key: string]: string | number };
  featured: boolean;
  active: boolean;
  free: boolean;
  rate: string;
  ratelist: {
    INR: string;
    USD: string;
  };
  sidetext: string;
  special: boolean;
  type: string;
  showAppCount?: boolean;
  applicationprocessid: number;
};

export type TProductDropdownSelect = {
  value: number;
  label: string;
};

export type TProductFormData = {
  cp_id: string;
  description: string;
  discountedRate: string;
  featured: boolean;
  free: boolean;
  rate: string;
  multipleCurrency: boolean;
  price_usd: string;
  productCourseValue: string;
  productProgramValue: string;
  showAppCount: boolean;
  sidetext: string;
  special: boolean;
  type: string;
  applicationProcessId: number;
};

export type TCertificate = {
  id?: number;
  description: string;
  product: string;
  productsub: string;
  purpose: string;
  title: string;
  completed: Date;
  duration: string;
  topics: string;
  signatories: string;
  sharedescription: string;
};

export type TCertificateFormData = {
  completed: string;
  description: string;
  product: string;
  productsub: string;
  purpose: string;
  title: string;
  duration: string;
  topics: string;
  signatories: string[] | string;
  sharedescription: string;
  version?: number;
};

export type TPayment = {
  id: number;
  description: string;
  type: 'program_fee' | 'course_fee' | 'sub_fee';
};

export type TSubscriptionPlan = {
  id: number;
  name: string;
  billing_frequency: string;
  payment_id: number;
  role_id: number;
  meta: {
    name: string;
    included_meetups: number[];
  };
};
export type TMeetup = {
  id: number;
  name: string;
  description: string;
  type: string;
  page_url: string;
  scheduled_datetime: Date;
  end_datetime: Date;
  is_active: boolean;
  product_id?: string;
  subscription_id?: number;
  payment_id?: number;
  is_free: boolean;
  meetup_payment_type: string;
  plan_name: string;
  meta: {
    course_id?: string;
    meetup_link?: string;
    display_name?: string;
    allowed_roles?: string[];
    require_registration?: string;
    is_reg_external?: string;
    registration_url?: string;
    prof_name?: string;
    hero_image?: string;
    event_code?: string;
    included_meetups?: number[];
    difficulty_level: string;
    learn_topics: string[];
  };

  created_at: Date;
  updated_at: Date;
  registered_on?: Date;
};
export type TMeetupsAndSubs = {
  meetup_id: string;
  subscription_id: number[];
};

export type TSubsAndMeetup = {
  subscription_id: string;
  meetup_id: number[];
};

export type TGlobalSlot = {
  id: number;
  name: string;
  is_active: boolean;
  start_date: string;
  total_slots: number;
  available_slots: number;
};

export type TGlobalSlotFormFields = {
  name: string;
  start_date: string;
  total_slots: number;
};

export type TFilterState = {
  type: EFilterTypes;
  data: (TCourse | TProgram | TProduct | TCertificate | TSubscriptionPlan)[];
};

export type TCoupon = {
  id: number;
  discount_percent: number;
  discount_code: string;
  discount_text: string;
  description: string;
  payment_id: number;
  active: boolean;
};

export type TCouponFormFields = {
  discount_percent: number;
  discount_code: string;
  discount_text: string;
  description: string;
  payment_id: number;
  productValue: string;
};

export type TClasses = {
  id?: number;
  class_name?: string;
  class_description?: string;
  meeting_link?: string;
  schedule_date_time?: string;
  status?: number;
  created_on?: string;
  updated_on?: string;
  admin_meeting_link?: string;
  class_page_link?: string;
  course_id?: string;
  class_end_date_time?: string;
  class_start_date_time?: string;
  class_ta_id?: number;
};

export type TUser = {
  id: number;
  name: string;
  username: string;
  email: string;
};

export type TReferredUser = {
  id: number;
  name: string;
  phone: string;
  email: string;
  iemail: string;
  username: string;
  idcard: string;
  is_valid: boolean;
};

export type TWork = {
  workType?: string;
  cCity?: string;
  organization?: string;
  designation?: string;
  role?: string;
  natureOfExperience?: string;
  joiningDate?: string;
  relieveDate?: string;
  sector?: string;
  ctc?: string;
};

export type TSchool = {
  name?: string;
  grade?: string;
  graduation?: string;
};

export type TGraduation = {
  eduStatus?: string;
  university?: string;
  college?: string;
  major?: string;
  cgpa?: string;
  graduation?: string;
  subject?: string;
  startYear?: string;
  endYear?: string;
};

export type TDoctorate = {
  university?: string;
  cgpa?: string;
  subject?: string;
  topic?: string;
  startYear?: string;
};

export type TAward = {
  name: string;
  year: string;
  grantOrg: string;
};

export type TSname = {
  firstname: string;
  middlename: string;
  lastname: string;
};

export type TActivities = {
  name: string;
  description: string;
  duration: string;
};

export type TAcademicWork = {
  name: string;
  publication: string;
};

export type TWorkExperience = {
  totalExperienceMonths: string;
  progLang: string;
  progLibs: string;
  progTools: string;
  dsExperience: string;
};
type TOptions = '0' | '1' | '2' | '3';
export type TUserDetailOther = {
  name: string;
  sname?: TSname;
  username?: string;
  email?: string;
  iemail?: string;
  phone?: string;
  cCity?: string;
  picture?: string;
  highestEducation?: string;
  school?: TSchool;
  undergraduate?: TGraduation;
  postgraduate?: TGraduation;
  doctorate?: TDoctorate;
  isWorking?: boolean;
  currentWork?: TWork;
  pastWork?: TWork[];
  experience?: string;
  gender?: string;
  source?: string;
  programming?: TOptions;
  statistics?: TOptions;
  algebra?: TOptions;
  calculus?: TOptions;
  payment?: string;
  birthYear?: string;
  blurb?: string;
  isLookingPlacement?: boolean;
  isEmailOtpVerified?: boolean;
  is_phone_otp_verified?: boolean;
  hasValidInstitutionMail?: boolean;
  verifiedEmail?: string;
  verified_phone?: string;
  award?: TAward[];
  activities?: TActivities[];
  topskills?: string;
  academicWork?: TAcademicWork[];
  udspOthers?: TActivities[];
  workExperience?: TWorkExperience;
  certifications?: TActivities[];
};

export type UserProductAccessDetail = {
  product_type: string;
  product_value: string;
  meetup_names: string[];
};

export type TUserDetail = {
  user_id: number;
  phone: string;
  education: string;
  work: string;
  other: TUserDetailOther;
};

export type TJobsFormData = {
  title: string;
  position: string;
  description: string;
  category: string;
  max_applicants: number;
  max_positions: number;
  salary_start: number;
  salary_end: number;
  posted_date: string;
  expiry_date: string;
  company_id: number;
  recruiter_id: number;
  technical_blog: string;
  student_domain: string;
  skills: string;
  nature_of_work: string;
  location: string;
  tag_name: string[];
  other_details: string;
  min_experience_req: number;
  application_process: string;
};

export type TJobs = {
  id: number;
  title: string;
  position: string;
  description: string;
  category: string;
  max_applicants: number;
  max_positions: number;
  salary_start: number;
  salary_end: number;
  posted_date: string;
  expiry_date: string;
  meta: {};
  company_id: number;
  recruiter_id: number;
  is_active: boolean;
  tag: { name: string; type: string }[];
  applicantCount: number;
  application_process: number;
};

export type TSubmission = {
  id?: number | string;
  title: string;
  description: string;
  total_marks: number;
  discussion_url: string;
  start_date: Date;
  end_date: Date;
  course_id: string;
  summary: string;
};

export type TJobApplications = {
  id: number;
  status: string;
  meta: {};
  job_id: number;
  user_id: number;
  created_at: string;
  name: string;
  username: string;
  form_additional_fields: any;
  isSelected?: boolean;
};

export type TJobCategory = {
  id: number;
  name: string;
  description: string;
};

export type TJobCompany = {
  id: number;
  name: string;
  description: string;
  logo: string;
};

export type TJobTag = {
  id: string;
  name: string;
  tag_type: string;
};

export type TAgentFormData = {
  name: string;
  email: string;
  phone: string;
};

export type TAgent = {
  id: number;
  name: string;
  email: string;
  phone: string;
  leadsActive: number;
  leadsClosed: number;
  agentType: string;
  active: boolean;
};

export type CodeUsage = {
  availed: boolean;
  availedOn: string;
  paymentIntent: string;
  userEmail: string;
  userId: string;
};

export type Code = {
  code: string;
  generatedForEmail: string;
  discount: number;
  agentId: boolean;
  CodeUsage?: CodeUsage[];
};
export type TAgentDetail = {
  id: number;
  name: string;
  email: string;
  phone: string;
  Code: Code[];
};

export type TFeedbackStatus = 'following' | 'resolved' | 'active';

export type TFeedback = {
  id: number;
  comment: string;
  type: string;
  email: string;
  screenshot: string;
  file: string;
  status: TFeedbackStatus;
  user_id: number;
};

export type TSettings = {
  enable_mark: boolean; // to show mark button in ui
  enable_terminal: boolean;
  enable_manual_feedback: boolean; // to not show feedback button in ui
  enable_file_upload: boolean;
  enable_file_download: boolean;
  enable_file_browser: boolean;
  enable_manual_evaluation: boolean; // to not show evaluation
  commands: {
    run: string;
    test: string;
    build: string;
  };
};

type TFileData = { [key: string]: { key: string; name: string } };
export type TNotebookFiles = { scaffold: TFileData; solution: TFileData; test: TFileData }

export type TNotebookExercise = {
  _id: string;
  course_id: string;
  title: string;
  description: string;
  explanation?: string;
  start_date: Date;
  end_date: Date;
  max_points: number;
  settings: TSettings;
  tags: any | undefined;
  is_active: boolean;
  test_file_name: string;
  notebook_to_test_file_name: string;
  files: TNotebookFiles;
};

//types for student test results
export interface TStudentExerciseResponses {
  exerciseId: string;
  startTime: string;
  endTime: string;
  ended: boolean;
  endedGracefully: boolean;
  courseId: string;
  exerciseSessionId: string;
  evaluation: Evaluation;
  questions: {
    [key: string]: Question;
  };
}

export interface Question {
  test: Test;
  datafiles: any[];
  tags: any[];
  _id: string;
  tag: any[];
  type: string;
  description: string;

  expected_text: string;
  explanation: string;
  max_points: number;
  award_points: number;
  is_active: boolean;
  options?: TResponseOption[];
  __v: number;
  text: string;
  mode: string;
  responses: Response[];
  correct: boolean;
  correctResponse: Response;
}

export type TResponseOption = {
  _id: string;
  text: string;
  points: number;
};
export interface Response {
  evaluation: QuestionEvaluation;
  _id: string;
  question_id: string;
  locked: boolean;
  updated_at: string;
  user_id: number;
  text: string;
  timestamp: number;
  selected_option: string[];
}

export interface Test {
  private: string[];
  public: string[];
}

export interface Evaluation {
  points_scored: number;
  max_points: number;
  percent: number;
}
export interface QuestionEvaluation {
  points: number;
  is_correct_answer: boolean;
}
//student test results end

import { Action } from 'components/Table/Actions';
import { TA_BACKEND_URL } from 'constants/app';
import Spinner from 'fragments/Spinner';
import React, { useState, useEffect } from 'react';
import { FaFileDownload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { requestJSON, requestText } from 'utils/request';
import moment from 'moment';
import Modal from 'react-modal';
import { IpynbRenderer } from "react-ipynb-renderer";
import { PRIMARY_BACKGROUND } from 'constants/colors';


type RouteParams = {
  submissionId: string;
  studentUserId: string;
};
type TStudentSubmission = {
  'submission_path': string;
  'comments': string;
  'email': string;
  'name': string;
  'submission_id': number;
  'marks': number;
  'grade': string;
  'feedback': any;
  'submission_evaluation_id': number;
  'submission_date': string | Date;
}
export const StudentEvaluationList = () => {
  const { submissionId, studentUserId } = useParams<RouteParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [evaluationModal, setEvaluationModal] = useState<boolean>(false);
  const [submitting, setSubmmiting] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const grades = ['A', 'B', 'C', 'D', 'E', 'F'];
  const [method, setMethod] = useState<string>('');
  const [file, setFile] = useState({});
  const [feedbackFileName, setFeedbackFileName] = useState<string>('');
  const [error, setError] = useState<string>();
  const [ipynbData, setIpynbData] = useState<string>('');
  const [studentSubmissions, setStudentSubmissions] = useState<TStudentSubmission[]>([]);
  const [index, setIndex] = useState(0);
  const [submissionDisplayed, setSubmissionDisplayed] = useState<TStudentSubmission[]>([]);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>('');
  const [pdfUrl,setPdfUrl] = useState<string>('');
  const getSubmissionsByStudents = async () => {
    setLoading(true);
    const response = await requestJSON(`${TA_BACKEND_URL}/student-submissions?studentUserId=${studentUserId}&submissionId=${submissionId}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      setLoading(false);
      setStudentSubmissions([]);
      return setError(response.error);
    }
    if (response.data) {
      const studentSubs = (response.data.data as TStudentSubmission[]);
      setStudentSubmissions(studentSubs);
    } else {
      toast.error('Unable to fetch Job Application list.');
    }
    setLoading(false);
  };
  useEffect(() => {
    getSubmissionsByStudents();
  }, []);
  const getIpynbText = async (fileName: string) => {
    setFileLoading(true);
    const response = await requestJSON(`${TA_BACKEND_URL}/submission-file?fileName=${fileName}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      toast.error('Failed to get ipynb file');
      setFileLoading(false);
      return setError(response.error);
    };
    if (response.data) {
      const uri = response.data;
      const txtResp = await requestText(uri.data as any,{method:'GET'});
      if (!txtResp.success) {
        toast.error('Failed to get email template');
        setFileLoading(false);
        return;
      }
      setIpynbData(txtResp.data);
      setFileLoading(false);
    } else {
      setFileLoading(false);
      toast.error('Failed download assignment file');
    }
  };
  const getPdfS3Link = async(fileName:string) => {
    setFileLoading(true);
    setIpynbData('');
    const response = await requestJSON(`${TA_BACKEND_URL}/submission-file?fileName=${fileName}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      setFileLoading(false);
      toast.error('Failed get pdf url file');
      return setError(response.error);
    };
    if (response.data) {
      const uri = response.data.data as string;
      setPdfUrl(uri);
      setFileLoading(false);
    } else {
      toast.error('Failed download assignment file');
      setFileLoading(false);
    }
  };
  useEffect(() => {
    if (studentSubmissions[index]?.submission_path.split('.').slice(-1)[0] === 'ipynb') {
      getIpynbText(studentSubmissions[index]?.submission_path);
    }
    if (studentSubmissions[index]?.submission_path.split('.').slice(-1)[0] === 'pdf') {
      getPdfS3Link(studentSubmissions[index]?.submission_path);
    }
  },[index,studentSubmissions]);
  const downloadFile = (data: any, fileName: string, fileType: string) => {
    setDownloading(true);
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    setDownloading(false);
  };
  const downloadIpynb = async (name: string) => {
    downloadFile(ipynbData, name, 'text/json');
  };
  const downloadURI = async (uri: string, name: string) => {
    const link = document.createElement('a');
    link.href = uri;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadSubmission = async (filename: string) => {
    if (filename.split('.').slice(-1)[0] === 'ipynb') {
      downloadIpynb(filename);
    } else {
      setDownloading(true);
      const response = await requestJSON(`${TA_BACKEND_URL}/submission-file?fileName=${filename}`, {
        credentials: 'header',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (!response.success) {
        setDownloading(false);
        toast.error('Failed download assignment file');
        return setError(response.error);
      };
      if (response.data) {
        const uri = response.data.data;
        downloadURI(uri as string, filename);
        toast.success('Downloaded assignment file succesfully.');
        setDownloading(false);
      } else {
        toast.error('Failed download assignment file');
        setDownloading(false);
      }
    }
  };
  const closeAllModals = () => {
    setEvaluationModal(false);
  };
 
  const handleEvaluation = async (method: string, submissionId: number | undefined,
    submimissionEvaluationId: number | undefined,
    marks: number,
    grade: string, feedback: string) => {
    setSubmmiting(true);
    const data = {
      studentSubmissionId: submissionId,
      grade: grade,
      marks: marks,
      submissionEvaluationId: submimissionEvaluationId,
      feedback: feedback,
      fileName: feedbackFileName ? feedbackFileName : ''
    };
    uploadFile();
    const response = await requestJSON(`${TA_BACKEND_URL}/evaluate-submission`, {
      credentials: 'header',
      body: JSON.stringify({ data: data }),
      method: method,
      headers: { 'content-type': 'application/json' },
    });

    if (!response.success) {
      toast.error('Unable to update the evaluation.');
      setSubmmiting(false);
      closeAllModals();
      return setError(response.error);
    } else {
      toast.success('Evaluation update successfully.');
      setSubmmiting(false);
      closeAllModals();
    }
    getSubmissionsByStudents();
    closeAllModals();
  };
  const handelFileChane = (event: any) => {
    setFile(event.target.files[0]);
    const name = event.target.files[0].name;
    setFeedbackFileName(name);
  };
  const uploadFile = async () => {
      const fileTwo = file;
      const fileNameTwo = encodeURIComponent(`feedback_${submissionId}_${feedbackFileName}`);
      const fileType = encodeURIComponent((file as any).type);
      const apiUrl = `${TA_BACKEND_URL}/upload-feedback?fileName=${fileNameTwo}&type=${fileType}&ACL=private`;
      const form = new FormData();
      const response = await requestJSON(apiUrl, {
        credentials: 'header',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (!response.success) {
        toast.error('Failed to get ipynb file');
        setFileLoading(false);
        return setError(response.error);
      };
      if (response.data) {
        const uploadParams = response.data.data as any;
        const { url, filePath } = uploadParams;
        (Object.keys(url.fields)).forEach(key => form.append(key, url.fields[key]));
        form.append('file', fileTwo as any);
        const resp = await fetch(url.url, { method: 'POST', body: form });
        if(resp.status !== 204 && resp.status !== 200) {
          toast.error('failed to upload file');
        }
      } else {
        setFileLoading(false);
        toast.error('Failed download assignment file');
      }
    
  };
  const setMarksValue = (value: number, idx: number) => {
    const updatedStudentSubmissions = studentSubmissions?.map((a, i) => {
      if (i === idx) return { ...a, marks: value };
      else return a;
    });
    setStudentSubmissions(updatedStudentSubmissions);
  };
  const setGradeValue = (value: string, idx: number) => {
    const updatedStudentSubmissions = studentSubmissions?.map((a, i) => {
      if (i === idx) return { ...a, grade: value };
      else return a;
    });
    setStudentSubmissions(updatedStudentSubmissions);
  };
  const divClicked = (idx: number) => {
    setIndex(idx);
    setSubmissionDisplayed([
      ...studentSubmissions.filter((a, i) => {
        return i === idx;
      })
    ]);
  };
  return (
    <div style={{ 'width': '100%', 'display': 'flex' }}>
      <div style={{ 'float': 'left', 'width': '40%', 'height': '100vh', 'borderRight': '2px solid #4086D0', 'overflowY': 'auto' }}>
        <div>
          <div style={{ marginLeft: '20px' }}>
            <h2>Name: {studentSubmissions[0]?.name}</h2>
            <h2>Email: {studentSubmissions[0]?.email}</h2>
          </div>
          {studentSubmissions?.map((submission, idx) => {
            return (<div onClick={() => { divClicked(idx); }} style={{ borderBottom: '0.5px solid #4086D0', padding: '5px', marginLeft: '20px' }}>
              <h4>Assignment {studentSubmissions.length - idx}</h4>
              <p>Date Submitted:{moment(submission.submission_date).format('DD-MM-YY HH:mm')}</p>
            </div>);
          })}

        </div>
      </div>
      <div style={{ 'float': 'right', 'width': '70%' }}>
        <div>
          {
            studentSubmissions[index]?.submission_evaluation_id
              ?
              <div>
                <div style={{ 'display': 'flex', marginLeft: '8px', alignItems: 'center' }} key={studentSubmissions[index]?.submission_id}>
                  <h3 style={{ marginRight: '8px' }}>Update Evaluation</h3>
                  <div style={{ marginRight: '8px' }}>
                    <h5>Marks</h5>
                    <input
                      style={{ 'width': '70px' }}
                      type='number'
                      value={studentSubmissions[index]?.marks || ''}
                      disabled={true}
                    />
                  </div>
                  <div>
                    <h5 style={{ marginRight: '8px' }}>Grade</h5>
                    <select
                      style={{ 'width': '70px' }}
                      value={studentSubmissions[index]?.grade || ''}
                      disabled={true}
                    >
                      {grades.map(d => (
                        <option key={d} value={d}>
                          {d}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginLeft: '12px', marginRight: '12px', marginTop: '20px' }}>{downloading ? <Spinner size="tiny" /> : <Action
                        tooltip="Download Assignment file"
                        iconType={FaFileDownload}
                        iconColor={'#4086D0'}
                        onClick={() => { downloadSubmission(studentSubmissions[index].submission_path); }} />}</div>
                      <div>{submitting ? <Spinner size="tiny" /> : <button onClick={() => {
                        setMethod('PATCH');
                        setEvaluationModal(true);
                      }} style={{ marginTop: '8px' }}>Update Evaluations</button>}
                      </div>
                    </div>
                  </div>
                </div>
                <h5 style={{ marginLeft: '8px', }}> Student Comment:{studentSubmissions[index]?.comments}</h5>
              </div>
              :
              <div>
                <div style={{ 'display': 'flex', marginLeft: '8px', marginRight: '8px', alignItems: 'center' }} key={studentSubmissions[index]?.submission_id}>
                  <h3 style={{ marginRight: '8px' }}>Evaluation</h3>
                  <div style={{ marginRight: '8px' }}>
                    <h5 >Marks</h5>
                    <input
                      style={{ 'width': '70px' }}
                      type='number'
                      value={studentSubmissions[index]?.marks || ''}
                    />

                  </div>
                  <div style={{ marginRight: '8px', marginLeft: '8px' }}>
                    <h5>Grade</h5>
                    <select
                      style={{ 'width': '70px' }}
                      value={studentSubmissions[index]?.grade || ''}
                    >
                      {grades.map(d => (
                        <option key={d} value={d}>
                          {d}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginLeft: '12px', marginRight: '12px', marginTop: '20px' }}>{downloading ? <Spinner size="tiny" /> : <Action
                      tooltip="Download Assignment file"
                      iconType={FaFileDownload}
                      iconColor={'#4086D0'}
                      onClick={() => { downloadSubmission(studentSubmissions[index].submission_path); }} />}</div>
                    <div>{submitting ? <Spinner size="tiny" /> : <button onClick={() => {
                      setMethod('POST');
                      setEvaluationModal(true);
                    }} style={{ marginTop: '8px' }}>Evaluate</button>}
                    </div>
                  </div>
                </div>
                <h5 style={{ marginLeft: '8px' }}>Comments:{studentSubmissions[index]?.comments}</h5>
              </div>
          }
          <div style={{ marginTop: '8px', marginLeft: '8px' }}>
            {studentSubmissions[index]?.submission_evaluation_id && <h1>Feedbacks</h1>}
            {studentSubmissions.length!==0 && studentSubmissions[index].feedback?.map((a:any,idx:number)=>{
              return(<div><>{`${idx+1}. ${a.feedback}`}</></div>);
            })}
         {ipynbData.length!==0 && <IpynbRenderer
              ipynb={JSON.parse(ipynbData)} />}
            {studentSubmissions[index]?.submission_path.split('.').slice(-1)[0] === 'pdf' && <object width="100%" height="800" data={pdfUrl} type="application/pdf"></object>
            }
          </div>
        </div>
      </div>
      <Modal
        isOpen={evaluationModal}
        onRequestClose={closeAllModals}
        style={{
          overlay: {
            zIndex: 99999,
            backgroundColor: 'rgba(5, 10, 15, 0.9)',
          },
          content: {
            zIndex: 32,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: PRIMARY_BACKGROUND,
            height: 'maxContent',
            maxHeight: `60%`,
            width: '70%%',
            maxWidth: `1024px`,
          },
        }}
      >
        <h2>Evaluations</h2>
        <div style={{ marginRight: '8px' }}>
          <h5 >Marks</h5>
          <input
            style={{ 'width': '70px' }}
            type='number'
            value={studentSubmissions[index]?.marks || ''}
            onChange={(e) => { setMarksValue(+e.target.value, index); }}
          />

        </div>
        <div style={{ marginRight: '8px' }}>
          <h5>Grade</h5>
          <select onChange={(e) => { setGradeValue(e.target.value, index); }}
            style={{ 'width': '70px' }}
            value={studentSubmissions[index]?.grade || ''}
          >
            {grades.map(d => (
              <option key={d} value={d}>
                {d}
              </option>
            ))}
          </select>
        </div>
        <h2>Feedback</h2>
        <h5>Feedback</h5>
        <input
          style={{ 'width': '200px' }}
          type='text'
          value={feedback}
          onChange={(e) => { setFeedback(e.target.value); }}
        />
        <h5>Uplaod Feedback file</h5>
        <input type='file' onChange={handelFileChane}></input>
        <button style={{ marginTop: '10px' }} disabled={!feedback} onClick={() => { handleEvaluation(method, studentSubmissions[index].submission_id, studentSubmissions[index]?.submission_evaluation_id, studentSubmissions[index]?.marks, studentSubmissions[index]?.grade, feedback as string); }}>Save</button>
      </Modal>
    </div>);
};
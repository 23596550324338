import { TSubmission } from "types";

export const formatData = (data: TSubmission) => {
    const newData = {
        "title": data.title,
        "description": data.description,
        "course_id": data.course_id,
        "discussion_url": data.discussion_url,
        "total_marks": +data.total_marks,
        "end_date": data.end_date,
        "start_date": data.start_date,
        "summary": data.summary
    };
    return newData;
};
import React from 'react';
import { FaRedoAlt } from 'react-icons/fa';

import { ActionIcon } from './actions-style';

type TProps = {
  onClick: Function;
  tooltip: string;
};

export function ActionRevert(props: TProps) {
  return (
    <div data-tooltip={props.tooltip}>
      <div data-tooltip={props.tooltip}>
        <ActionIcon color="blue">
          <FaRedoAlt
            onClick={() => {
              props.onClick();
            }}
          ></FaRedoAlt>
        </ActionIcon>
      </div>
    </div>
  );
}

import React from 'react';
import Modal from 'react-modal';
import { FaTimesCircle } from 'react-icons/fa';

interface IProps {
  isOpen: boolean;
  close: () => void;
  children: JSX.Element | null;
  size?: 'sm' | 'md' | 'lg';
}

export default function (props: IProps) {
  const { close, isOpen, children, size = 'sm' } = props;
  const sizeClasses = (() => {
    if (size === 'sm') return 'xl:w-5/12 lg:w-6/12 md:w-7/12 sm:w-8/12';
    if (size === 'md') return 'xl:w-6/12 lg:w-7/12 md:w-8/12 sm:w-9/12';
    if (size === 'lg') return 'xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12';
  })();

  return (
    <Modal
      isOpen={isOpen}
      portalClassName="absolute top-0 left-0"
      overlayClassName="z-1010 fixed bg-black bg-opacity-70 h-screen w-screen flex items-center justify-center"
      className={`p-4 m-4 h-4/5 w-full bg-gray-50 overflow-auto ${sizeClasses} md:p-8 md:h-70vh`}
      onRequestClose={close}
    >
      <div className="absolute top-4 right-4 cursor-pointer text-2xl text-white" onClick={close}>
        <FaTimesCircle />
      </div>
      <div className="h-full w-full">{children}</div>
    </Modal>
  );
}

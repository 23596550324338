import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { FaBars, FaUser, FaSignOutAlt, FaChevronDown, FaChevronUp } from 'react-icons/fa';

import {
  // getLoginURL,
  getLogoutURL,
  QUESTION_PATH,
  CREATE_EXERCISE_PATH,
  EXERCISE_PATH,
  CREATE_QUESTION_PATH,
  POPULATE_EXERCISE_PATH,
  PUBLISH_EXERCISE_PATH,
  CLASS_PATH,
  SCHEDULE_EVENTS_PATH,
  MEETUPS_PATH,
  HACKATHON_LIST_PATH,
  COURSE_ROUTER_LIST_PATH,
  PRODUCT_ROUTER_LIST_PATH,
  PROGRAM_ROUTER_LIST_PATH,
  CERTIFICATE_ROUTER_LIST_PATH,
  APPLICATION_ROUTER_LIST_PATH,
  SUBSCRIPTION_ROUTER_LIST_PATH,
  COUPONS_LIST_PATH,
  GLOBAL_SLOT_LIST_PATH,
  USER_LIST_PATH,
  JOBS_LIST_PATH,
  USER_AGENT_LIST_PATH,
  FEEDBACK_LIST_PATH,
  APPLICATION_PROCESS_PATH,
  QUESTIONNAIRE_PATH,
  TOPICS_PATH,
  SUBMISSIONS_PATH,
} from 'constants/app';
import useStore from 'globalstate';
import Error from 'components/Error';

type TMenu = {
  title: string;
  path: string;
  menus?: TMenu[];
  icon?: JSX.Element;
};

type TSidemenuStatus = { [path: string]: boolean };

export default function NavBar() {
  const [isOpen, setisOpen] = useState<boolean>(window.innerWidth > 768);
  const [showUserMenu, setshowUserMenu] = useState<boolean>(false);
  const [sidemenuStatus, setsidemenuStatus] = useState<TSidemenuStatus>({});

  const [State] = useStore();
  const { isAuthenticated, userDetails } = State;

  const location = useLocation();

  // const loginURL = getLoginURL(window.location.href);
  const logoutURL = getLogoutURL(window.location.href);

  const handleSubmenuChange = (path: string, status: boolean) => {
    const newsidemenuStatus = { ...sidemenuStatus, [path]: status };
    setsidemenuStatus(newsidemenuStatus);
  };
  const TALINKS = [
    { title: 'Courses', path: COURSE_ROUTER_LIST_PATH },
    { title: 'Sessions', path: CLASS_PATH },
    null,
    {
      title: 'Questions',
      path: CREATE_EXERCISE_PATH,
      menus: [
        { title: 'Questions', path: QUESTION_PATH },
        { title: 'Create Questions', path: CREATE_QUESTION_PATH },
      ],
    },
    {
      title: 'Exercise',
      path: CREATE_EXERCISE_PATH,
      menus: [
        { title: 'Exercises', path: EXERCISE_PATH },
        { title: 'Create Exercise', path: CREATE_EXERCISE_PATH },
        { title: 'Populate Exercise', path: POPULATE_EXERCISE_PATH },
        { title: 'Publish Exercise', path: PUBLISH_EXERCISE_PATH },
      ],
    },
  ];
  const LINKS = [
    { title: 'Sessions', path: CLASS_PATH },
    { title: 'Notifications', path: SCHEDULE_EVENTS_PATH },
    { title: 'Topics', path: TOPICS_PATH },
    { title: 'Application', path: APPLICATION_ROUTER_LIST_PATH },
    {
      title: 'Application Process',
      path: APPLICATION_PROCESS_PATH,
    },
    {
      title: 'Questionnaire',
      path: QUESTIONNAIRE_PATH,
    },
    {
      title: 'Assignments',
      path: SUBMISSIONS_PATH,
    },
    {
      title: 'Certificates',
      path: CERTIFICATE_ROUTER_LIST_PATH,
    },
    {
      title: 'Users',
      path: USER_LIST_PATH,
    },
    null,
    { title: 'Courses', path: COURSE_ROUTER_LIST_PATH },
    {
      title: 'Programs',
      path: PROGRAM_ROUTER_LIST_PATH,
    },
    {
      title: 'Products',
      path: PRODUCT_ROUTER_LIST_PATH,
    },
    { title: 'Meetups', path: MEETUPS_PATH },
    null,
    {
      title: 'Jobs',
      path: JOBS_LIST_PATH,
      // menus: [
      //   { title: 'All Jobs', path: JOBS_LIST_PATH },
      //   { title: 'Job Applications', path: JOB_APPLICATION_LIST_PATH },
      // ],
    },
    null,
    {
      title: 'Subscriptions',
      path: SUBSCRIPTION_ROUTER_LIST_PATH,
    },
    {
      title: 'Global Slots',
      path: GLOBAL_SLOT_LIST_PATH,
    },
    // {
    //   title: 'Cohorts',
    //   path: COHORTS_LIST_PATH,
    // },
    {
      title: 'Coupons',
      path: COUPONS_LIST_PATH,
    },
    // {
    //   title: 'Referrals',
    //   path: REFERRALS_LIST_PATH,
    // },
    null,
    {
      title: 'Questions',
      path: CREATE_EXERCISE_PATH,
      menus: [
        { title: 'Questions', path: QUESTION_PATH },
        { title: 'Create Questions', path: CREATE_QUESTION_PATH },
      ],
    },
    {
      title: 'Exercise',
      path: CREATE_EXERCISE_PATH,
      menus: [
        { title: 'Exercises', path: EXERCISE_PATH },
        { title: 'Create Exercise', path: CREATE_EXERCISE_PATH },
        { title: 'Populate Exercise', path: POPULATE_EXERCISE_PATH },
        { title: 'Publish Exercise', path: PUBLISH_EXERCISE_PATH },
      ],
    },
    null,
    {
      title: 'Feedbacks',
      path: FEEDBACK_LIST_PATH,
    },
    {
      title: 'Hackathons',
      path: HACKATHON_LIST_PATH,
    },
    {
      title: 'Agent',
      path: USER_AGENT_LIST_PATH,
    },
  ];
  // const linksInSidebar = LINKS.filter((path): path is { title: string; path: string } => path !== null).map(
  //   ({ path }: { path: string }) => path
  // );

  const renderMenus = (menu: TMenu | null, index: number) => {
    if (!menu) return <hr key={`navbar-break-${index}`} />;

    const { path, title, menus, icon } = menu;
    const isActive = location.pathname === path;
    if (menus) {
      // highlights when a child is active but the submenu is collapsed
      const issidemenuActive = menus.some(m => m.path === location.pathname) && !sidemenuStatus[path];
      return (
        <SubMenu
          onOpenChange={(open: boolean) => {
            handleSubmenuChange(path, open);
          }}
          className={issidemenuActive ? 'bg-brand-lightest text-brand link-white-hover' : ''}
          key={`navbar-menu-${title}`}
          title={title}
        >
          {menus.map(renderMenus)}
        </SubMenu>
      );
    }

    return (
      <MenuItem
        key={`navbar-menuitem-${title}`}
        className={isActive ? 'bg-brand-lightest text-brand link-white-hover' : ''}
        icon={icon}
      >
        <Link to={path} className={isActive ? 'link-white-hover' : ''}>
          {title}
        </Link>
      </MenuItem>
    );
  };

  // if (!linksInSidebar.includes(location.pathname)) return null;
  const getAllowedMenus = () => {
    if (!userDetails || userDetails?.roles.split(',').length == 0) return [];

    if (userDetails?.roles.split(',').includes('admin')) return LINKS;
    if (userDetails.roles.split(',').includes('ta')) return TALINKS;
    return [];
  };
  return (
    <React.Fragment>
      <div className="relative container-sidebar">
        <div className="fixed h-screen z-1010">
          {isAuthenticated && getAllowedMenus().length > 0 ? (
            <ProSidebar toggled={isOpen} onToggle={setisOpen} breakPoint="md" width="200px">
              <SidebarHeader>
                <div className="w-full my-8 flex justify-center">
                  <img className="h-5" src="/logo.svg" alt="Univ.AI" />
                </div>
              </SidebarHeader>
              <SidebarContent>
                <Menu iconShape="square">{getAllowedMenus().map(renderMenus)}</Menu>
              </SidebarContent>

              <hr className="my-2" />
              <SidebarFooter>
                <Menu iconShape="square">
                  <MenuItem
                    icon={<FaUser />}
                    onClick={() => {
                      setshowUserMenu(!showUserMenu);
                    }}
                  >
                    <div className="flex flex-row items-center">
                      <span className="mr-2">{userDetails.name}</span>
                      {showUserMenu ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                  </MenuItem>
                  <MenuItem className={`${showUserMenu ? 'block' : 'hidden'}`} icon={<FaSignOutAlt />}>
                    <a href={logoutURL} className="flex items-center">
                      Logout
                    </a>
                  </MenuItem>
                </Menu>
              </SidebarFooter>
            </ProSidebar>
          ) : (
            <Error></Error>
          )}
        </div>
      </div>
      <div
        onClick={() => {
          setisOpen(!isOpen);
        }}
        className={`h-12 w-12 p-4 rounded-full flex items-center justify-between fixed bottom-2 right-2
          bg-black md:hidden
      `}
      >
        <FaBars color="white" />
      </div>
    </React.Fragment>
  );
}

import React from 'react';
import { IconType } from 'react-icons';
import { ActionIcon } from './actions-style';

type TProps = {
  onClick: Function;
  tooltip: string;
  iconType: IconType;
  iconColor?: string;
};

export function Action(props: TProps) {
  const { tooltip, onClick, iconColor = 'blue' } = props;
  const IconComponent = props.iconType;
  return (
    <div data-tooltip={tooltip}>
      <ActionIcon color={iconColor}>
        <IconComponent
          onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
            onClick(e);
          }}
        ></IconComponent>
      </ActionIcon>
    </div>
  );
}

import { TitleMenu } from 'components/UI';
import { FILE_CABINET_URL, TA_BACKEND_URL } from 'constants/app';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { requestJSON } from 'utils/request';
import Error from 'components/Error';
import { StudentManagementTable } from './table';

type RouteParams = {
  courseId: string;
};

export const StudentManagement = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [exerciseId, setExerciseId] = useState<{ exerciseId: string[]; nameObj: {} }>({ exerciseId: [], nameObj: {} });
  const [enrolledStudent, setEnrolledStudent] = useState<{ id: number; email: string; name: string }[]>([]);
  const [error, setError] = useState<string>('');
  const dummyObj: { [key: string]: string } = {};
  const { courseId } = useParams<RouteParams>();
  const getStudentsEnrolled = async () => {
    setLoading(true);
    const response = await requestJSON(`${TA_BACKEND_URL}/student-management?courseId=${courseId}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });

    if (!response.success) {
      setLoading(false);
      toast.error('Failed to get Students Enrolled');
      return setError(response.error);
    }
    if (response.data) {
      const students = response.data.data as { id: number; email: string; name: string }[];
      setEnrolledStudent(students);
    } else {
      toast.error('Failed to get Enrolled Students');
    }
    setLoading(false);
  };

  const getToc = async () => {
    const response = await requestJSON(`${FILE_CABINET_URL}/cms/ta/toc?courseName=${courseId}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });

    if (!response.success) {
      setLoading(false);
      toast.error('Failed to get TOC data');
      return setError(response.error);
    }
    const exerciseIds = [];
    if (response.data) {
      const toc = response.data.data as any;
      for (let i = 0; i < toc.length; i++) {
        if (toc[i].sections) {
          const section = toc[i].sections;
          for (let k = 0; k < section.length; k++) {
            if (section[k].type === 'exercise') {
              exerciseIds.push(section[k].exerciseId);
              dummyObj[section[k].exerciseId] = section[k]?.title;
            }
          }
        }
      }
      setExerciseId({ exerciseId: exerciseIds, nameObj: dummyObj });
      getStudentsEnrolled();
    } else {
      toast.error('Failed to get TOC data');
    }
    setLoading(false);
  };
  useEffect(() => {
    getToc();
  }, []);

  if (error) return <Error embed preset="nothing" text={error} />;
  return (
    <div className="content">
      <TitleMenu title="Student Management" />
      {enrolledStudent.length > 0 ? (
        <StudentManagementTable enrolledStudent={enrolledStudent} exerciseId={exerciseId} courseId={courseId} />
      ) : (
        <div className="text-center">Looks Like there are no enrolled students for this course. </div>
      )}
    </div>
  );
};

import { TA_BACKEND_URL } from 'constants/app';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { requestJSON } from 'utils/request';

export interface TSubmissionDetailResult {
  id: string;
  user_id: number;
  name: string;
  email: string;
  timestamp: string;
  late_submission: string;
  files: File[];
}

export interface File {
  name: string;
  url: string;
}

type TProps = {
  submissionId: string;
};

function NotebookDetail(props: TProps) {
  const { submissionId } = props;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [studentSubmission, setStudentSubmission] = React.useState<TSubmissionDetailResult>();

  const getSubmissionDetails = async () => {
    setLoading(true);
    const response = await requestJSON(`${TA_BACKEND_URL}/submission-notebook-detail?submissionId=${submissionId}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      setLoading(false);
      setStudentSubmission(undefined);
      return setError(response.error);
    }
    if (response.data) {
      const studentSubs = response.data.data;
      setStudentSubmission(studentSubs);
    } else {
      toast.error('Unable to fetch Student Submission Details');
    }
    setLoading(false);
  };
  useEffect(() => {
    getSubmissionDetails();
  }, [submissionId]);
  return (
    <div className="flex flex-row  w-full">
      <div className="flex flex-col border border-2 h-screen p-10">
        <div className="grid grid-cols-2 ">
          <strong>Student Name</strong>
          <span>{studentSubmission?.name}</span>

          {/* <strong>Marks</strong>
        <span>
          {studentEvaluationDetail?.evaluation.points_scored}/{studentEvaluationDetail?.evaluation.max_points}
        </span> */}

          {/* <strong>Percentage</strong>
        <span>{studentEvaluationDetail?.evaluation.percent}</span> */}

          {/* <strong>Started At</strong>
        <span>{moment(studentEvaluationDetail?.startTime).format('DD MMM YY HH:mm:ss')}</span> */}

          <strong>Submission Time</strong>
          <span>{moment(studentSubmission?.timestamp).format('')}</span>

          <strong>Late Submission</strong>
          <span>{studentSubmission?.late_submission}</span>
        </div>
      </div>
      <div className=" max-h-screen p-8">
        <table>
          <thead>
            <tr>
              <th>File Name</th>
              <th>File URL</th>
            </tr>
          </thead>
          <tbody>
            {studentSubmission?.files.map(file => (
              <tr>
                <td>{file.name}</td>
                <td>
                  <a href={file.url} target="_blank">
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default NotebookDetail;

import { SimpleTable } from 'components/Table/simple-table';
import { TitleMenu } from 'components/UI';
import { TableColumn } from 'react-data-table-component';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { requestJSON } from 'utils/request';
import { TA_BACKEND_URL } from 'constants/app';
import { PRIMARY_BACKGROUND } from 'constants/colors';
import Modal from 'react-modal';
import Spinner from 'fragments/Spinner';
import Error from 'components/Error';

const Topics = () => {
  const [topicList, setTopicList] = useState<{ key: string, name: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNum, setPageNum] = useState<number>(0);
  const [topicName, setTopicName] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [submitting,setSubmitting] = useState<boolean>(false);
  const getTopics = async () => {
    const response = await requestJSON(`${TA_BACKEND_URL}/novu/topics?pageNum=${pageNum}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      setLoading(false);
      setTopicList([]);
      toast.error('Unable to fetch Topics list.');
      return setError(response.error);
    }
    if (response.data) {
      setTopicList((response.data as any).data);
      setTotalCount((response.data as any).totalCount);
    } else {
      toast.error('Unable to fetch Topics list.');
    }
    setLoading(false);
  };
  useEffect(() => { getTopics(); }, [pageNum]);
  const columns: TableColumn<{ key: string, name: string }>[] = [
    {
      name: 'Topic Key',
      selector: 'key',
      sortable: true,
    },
    {
      name: 'Topic Name',
      selector: 'name',
      sortable: false,
    }
  ];
  const onPageChange = (page: number) => {
    setPageNum(page);
  };
  const closeAllModals = () => {
    setShowModal(false);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTopicName(e.target.value);
  };
  const submitTopic = async () => {
    const response = await requestJSON(`${TA_BACKEND_URL}/novu/topics?topicName=${topicName}`, {
      credentials: 'header',
      method: 'POST',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      toast.error('Failed to create Topic.');
      return setError(response.error);
    } else {
      toast.success('Topic created successfully!');
    }
    setLoading(false);
    closeAllModals();
    getTopics();
  };
  if (error) return <Error embed preset="nothing" text={error} />;
  return (
    <div className="content">
      <div>
        <TitleMenu title="TOPICS" />
        <button onClick={() => { setShowModal(true); }}>Create New Topic!</button>
      </div>
      <SimpleTable
        columns={columns}
        data={topicList}
        progressPending={loading}
        pagination={true}
        paginationServer={true}
        paginationTotalRows={totalCount}
        paginationPerPage={10}
        noRowsPerPage={true}
        onChangePage={onPageChange}
      />
      <Modal
        isOpen={showModal}
        onRequestClose={closeAllModals}
        style={{
          overlay: {
            zIndex: 99999,
            backgroundColor: 'rgba(5, 10, 15, 0.9)',
          },
          content: {
            zIndex: 32,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'scroll',
            backgroundColor: PRIMARY_BACKGROUND,
            height: 'maxContent',
            maxHeight: `70%`,
            width: '90%',
            maxWidth: `1024px`,
          },
        }}
      >
        <div style={{marginBottom:'10px'}}>
          <h2>Add the name of new topic you wish to add!</h2>
          <input
            className="flex-grow mr-2"
            type="text"
            placeholder="Topic Name"
            value={topicName}
            onChange={handleInputChange}
          />
        </div>
        <button disabled={submitting || topicName.length <= 0} className="mt-10 button" onClick={submitTopic}>
          {submitting ? (
            <Spinner size="tiny" />
          ) : (
            <>
               Create
            </>
          )}
        </button>
      </Modal>
    </div>
  );
};
export default Topics;
import { PRIMARY_APP } from 'constants/colors';
import React from 'react';
import { SyncLoader } from 'react-spinners';

export const Loader = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center" style={{ height: '70vh' }}>
      <p className="text-2xl">Please wait</p>
      <div className="my-4">
        <SyncLoader color={PRIMARY_APP} />
      </div>
    </div>
  );
};

import React, { useRef, useEffect, useState } from 'react';
import { ContentState, ContentBlock } from 'draft-js';

import MathMd from 'components/Renderer/math-md';

interface IProps {
  contentState: ContentState;
  block: ContentBlock;
  blockProps: {
    onStartEdit: (blockKey: string) => void;
    onFinishEdit: (blockKey: string, contentState: ContentState) => void;
    onRemoveBlock: (blockKey: string) => void;
  };
}

export default function TexBlock(props: IProps) {
  const {
    contentState,
    block,
    blockProps: { onStartEdit, onFinishEdit, onRemoveBlock },
  } = props;
  const [texValue, settexValue] = useState<string>('$$ ');
  const [isEditing, setisEditing] = useState<boolean>(true);
  const textBoxRef = useRef<HTMLTextAreaElement>(null);

  const blockKey = block.getKey();

  const onComplete = () => {
    const entityKey = block.getEntityAt(0);
    const newContentState = contentState.mergeEntityData(entityKey, { content: texValue });
    setisEditing(false);
    onFinishEdit(blockKey, newContentState);
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    settexValue(e.target.value);
  };

  const onRemove = () => {
    setisEditing(false);
    onRemoveBlock(blockKey);
  };

  useEffect(() => {
    onStartEdit(blockKey);
    if (textBoxRef.current) {
      textBoxRef.current.focus();
      textBoxRef.current.selectionStart = textBoxRef.current.value.length;
      textBoxRef.current.selectionEnd = textBoxRef.current.value.length;
    }
  }, []);

  return (
    <div className="border border-solid border-gray-200 rounded">
      <div
        className="bg-gray-200 w-full p-2 flex items-center justify-center"
        onClick={() => {
          if (!isEditing) setisEditing(true);
        }}
      >
        <MathMd value={texValue} />
      </div>
      {isEditing ? (
        <div className="flex items-center justify-center mt-1">
          <textarea ref={textBoxRef} onChange={onChange} value={texValue} className="bg-gray-100" />
          <div className="p-2">
            <button className="secondary mb-2 small" onClick={() => onRemove()}>
              Remove
            </button>
            <button className="small" onClick={() => onComplete()}>
              Save
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

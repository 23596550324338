import { SimpleTable } from 'components/Table/simple-table';
import { TA_BACKEND_URL } from 'constants/app';
import React, { useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { requestJSON } from 'utils/request';
import { arrayToCSV } from './helpers';
import { FaCheckSquare, FaRegWindowClose } from 'react-icons/fa';
type RouteParams = {
  courseId: string;
};
export const Attendance = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [sessions, setSessions] = useState<{ id: number; title: string }[]>();
  const [attendance, setAttendance] = useState<any>([]);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const { courseId } = useParams<RouteParams>();
  const [searchParam, setSearchParam] = useState<string>('');

  const getCourseSessions = async () => {
    setLoading(true);
    const response = await requestJSON(`${TA_BACKEND_URL}/course-sessions?courseId=${courseId}`, {
      credentials: 'header',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (!response.success) {
      setLoading(false);
      setSessions([]);
      toast.error('Unable to fetch  list.');
      return setError(response.error);
    }
    if (response.data) {
      const fetchData = response.data.data as any;
      setSessions(fetchData);
    } else {
      toast.error('Unable to fetch  list.');
    }
    setLoading(false);
  };
  const getEvaluations = async () => {
    setLoading(true);
    const response = await requestJSON(
      `${TA_BACKEND_URL}/course-sessions-attendance?sessionId=${selectedId}&courseId=${courseId}`,
      {
        credentials: 'header',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      }
    );
    if (!response.success) {
      setLoading(false);
      setAttendance([]);
      toast.error('Unable to fetch  list.');
      return setError(response.error);
    }
    if (response.data) {
      const fetchData = response.data.data as any;
      setAttendance(fetchData);
    } else {
      toast.error('Unable to get evaluations list.');
    }
    setLoading(false);
  };
  const columns: TableColumn<any>[] = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Present',
      selector: 'present',
      sortable: true,
      cell: (row: any) => {
        return <div>{row.present ? <FaCheckSquare color="green" /> : <FaRegWindowClose color="black" />}</div>;
      },
    },
  ];
  useEffect(() => {
    if (selectedId !== 0) getEvaluations();
  }, [selectedId]);
  useEffect(() => {
    getCourseSessions();
  }, []);
  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParam(e.target.value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedId(+e.target.value);
  };
  const downloadFile = (data: any, fileName: string, fileType: string) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    setDownloading(false);
  };
  const downloadCsv = (data: any) => {
    setDownloading(true);
    const sessionsName = sessions?.filter((a: any) => {
      if (a.id === selectedId) return a;
    })[0].title;
    const csvData = arrayToCSV(data);
    downloadFile(csvData, `${sessionsName}-${courseId}.csv`, 'text/json');
  };
  return (
    <div style={{ marginLeft: '10px', marginTop: '10px' }}>
      {sessions?.length !== 0 ? (
        <>
          <h3>Select the Assignment from below list to view its evaluations</h3>

          <select
            style={{ width: '350px' }}
            id="select-dropdown"
            defaultValue={selectedId}
            onChange={e => {
              handleChange(e);
            }}
          >
            <option defaultValue={selectedId}>Select Session</option>
            {sessions?.map((a: any) => {
              return (
                <option key={a.id} value={a.id}>
                  {a.title}
                </option>
              );
            })}
          </select>
          {selectedId != 0 ? (
            <div style={{ marginTop: '5px' }}>
              <button
                disabled={attendance.length === 0 || downloading}
                onClick={() => {
                  downloadCsv(attendance);
                }}
              >
                Download Session Attendance
              </button>
              <div className="flex justify-between items-center mb-2">
                <div className="flex-shrink">
                  <p className="p-2">Search</p>
                  <div>
                    <input type="text" placeholder="Search" onChange={searchHandler}></input>
                  </div>
                </div>
              </div>
              <SimpleTable
                columns={columns}
                data={
                  searchParam
                    ? attendance.filter((row: any) => row.name.includes(searchParam) || row.email.includes(searchParam))
                    : attendance
                }
                progressPending={loading}
                paginationPerPage={20}
                noRowsPerPage={true}
                pagination={true}
                paginationTotalRows={attendance?.length}
              ></SimpleTable>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

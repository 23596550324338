import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Spinner from 'fragments/Spinner';
import Error from 'components/Error';
import { createNewData } from 'utils/helper';
import { SUBMISSIONS_PATH, TA_BACKEND_URL } from 'constants/app';
import SubmissionForm from './SubmissionForm';

export default function CreateSubmissions() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const history = useHistory();

  const createSubmissionHandler = async (formData: any) => {
    setLoading(true);

    const sendData = { data: { ...formData } };
    const response = await createNewData(sendData, `${TA_BACKEND_URL}/submissions`);

    if (!response.success) {
      setLoading(false);
      toast.error('Error: Unable to create job');
      return setError('Unable to create job');
    } else {
      setLoading(false);
      toast.success('Job has been Created!');
      history.push(SUBMISSIONS_PATH);
    }
  };
  return (
    <div className="content">
      <div className="flex items-center justify-between">
        <h3>Create New Assignment</h3>
      </div>
      {error && <Error embed preset="nothing" text={error} />}
      {loading && <Spinner spaced />}
      {loading == false && error?.length == 0 && <SubmissionForm onUpdate={createSubmissionHandler} type="create"></SubmissionForm>}
    </div>
  );
}

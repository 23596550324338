import React, { useEffect, useState } from 'react';

import { SUBMISSIONS_PATH, TA_BACKEND_URL } from 'constants/app';
import Error from 'components/Error';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { TitleMenu } from 'components/UI';
import { TableColumn } from 'react-data-table-component';
import { SimpleTable } from 'components/Table/simple-table';
import { toast } from 'react-toastify';
import { requestJSON } from 'utils/request';
import { Action } from 'components/Table/Actions';
import { PRIMARY_BACKGROUND } from 'constants/colors';
import { useParams } from 'react-router-dom';
import { FaStackExchange } from 'react-icons/fa';

type RouteParams = {
    submissionId: string;
};

enum EModalTypes {
    VIEW_SUBMISSION = 'VIEW_SUBMISSION',
}

type SubmissionEvaluationRow = {
    student_user_id: number;
    email: string;
    name: string;
    submission_count: number;
};

export default function StudentSubmissionList() {
    const { submissionId } = useParams<RouteParams>();
    const [studentSubmissionList, setStudentSubmissionList] = useState<SubmissionEvaluationRow[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [totalSubmission, setTotalSubmissions] = useState<number>(1);
    const headerLinks = [{ linkName: 'View All Assignment', linkUrl: SUBMISSIONS_PATH }];
    const [pageNum, setPageNum] = useState<number>(1);
    const history = useHistory();
    const onPageChange = (page: number) => {
        setPageNum(page);
    };
    const columns: TableColumn<SubmissionEvaluationRow>[] = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,

        },
        {
            name: 'Assignment Count',
            selector: 'submission_count',
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => {
                return (
                    <>
                        <div className="flex flex-row space-x-2">
                            <Action
                                iconType={FaStackExchange}
                                onClick={() =>   history.push(`/student-assignment/${submissionId}/${row.student_user_id}`)}
                                tooltip="Feedback and evaluation"
                            />
                        </div>
                    </>
                );
            },
        },
    ];

    const getStudentSubmission = async (page: number) => {
        setLoading(true);

        const response = await requestJSON(`${TA_BACKEND_URL}/student-submissions-list?pageNum=${page}&submissionId=${submissionId}`, {
            credentials: 'header',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
        });

        if (!response.success) {
            setLoading(false);
            setStudentSubmissionList([]);
            return setError(response.error);
        }
        if (response.data) {
            const jobApps = (response.data.data as any).data as any;
            setStudentSubmissionList(jobApps);
            setTotalSubmissions(+(response.data.data as any).count);
        } else {
            toast.error('Unable to fetch Job Application list.');
        }
        setLoading(false);
    };

    useEffect(() => {
        getStudentSubmission(pageNum);

        return () => {
            setStudentSubmissionList([]);
        };
    }, [pageNum]);

    if (error) return <Error embed preset="nothing" text={error} />;

    return (
        <div className="content">
            <TitleMenu title="Student Assignments" links={headerLinks} />
            <SimpleTable
                columns={columns}
                data={studentSubmissionList}
                progressPending={loading}
                pagination={true}
                paginationServer={true}
                paginationTotalRows={totalSubmission}
                paginationPerPage={20}
                noRowsPerPage={true}
                onChangePage={onPageChange}
            />
        </div>
    );
}

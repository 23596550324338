import React from 'react';
import Switch from 'react-switch';
import { Control, Controller } from 'react-hook-form';

import { PRIMARY_APP } from 'constants/colors';

type Props = {
  // Icon?: any;
  label: string;
  defaultValue?: boolean | string;
  control: Control;
  info?: string;
  name: string;
  error?: string;
};

export function FormSwitch(props: Props) {
  const { defaultValue, control, name } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value = false }) => (
        <Switch height={20} width={40} onChange={onChange} checked={value} onColor={PRIMARY_APP} />
      )}
    />
  );
}

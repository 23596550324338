import React from 'react';

type Props = {
  inputRef: React.Ref<HTMLInputElement>;
  name: string;
  placeholder?: string;
  defaultValue: number;
  error?: string;
  disabled?: boolean;
};

export function FormNumber(props: Props) {
  const { defaultValue, error, name, placeholder, inputRef, disabled } = props;

  return (
    <input
      className={`input ${error ? 'is-danger' : ''}`}
      name={name}
      ref={inputRef}
      placeholder={placeholder}
      type="number"
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
}

import { getCookieValue } from 'utils/cookies';

interface IModifiedRequestInit extends Omit<RequestInit, 'credentials'> {
  credentials?: RequestCredentials | 'header';
}

type TFetchTimeoutOptions = { signal?: AbortSignal | null } & IModifiedRequestInit;

// signal can be passed as a param to abort requests as a result of a user action
function fetchTimeout(url: string, { signal, ...options }: TFetchTimeoutOptions = {}, ms = 10000) {
  const controller = new AbortController();
  const { credentials: _, ...fetchOptionsWithoutCredentials } = options;
  const fetchOptions: RequestInit = { ...fetchOptionsWithoutCredentials };

  // prevent sending cookies, use headers instead
  if (options && options.credentials === 'header') {
    const maybeAuthCookie: string = getCookieValue('border-patrol-jwt') || '';
    fetchOptions.headers = options.headers || {};
    if (fetchOptions.headers) fetchOptions.headers = { ...options.headers, 'border-patrol-jwt': maybeAuthCookie };
    else fetchOptions.headers = { 'border-patrol-jwt': maybeAuthCookie };
    fetchOptions.credentials = 'omit';
  }

  const promise = fetch(url, { signal: controller.signal, ...fetchOptions });
  if (signal) signal.addEventListener('abort', () => controller.abort());
  const timeout = setTimeout(() => controller.abort(), ms);
  return promise.finally(() => clearTimeout(timeout));
}

type TRequestTextOut = { success: true; status: number; data: string } | { success: false; error: string };

export async function requestText(url: string, options?: IModifiedRequestInit): Promise<TRequestTextOut> {
  try {
    const resp = await fetchTimeout(url, options);
    const data = await resp.text();
    if (resp.status >= 400) return { success: false, error: data };
    if (!data) return { success: false, error: 'Problem parsing data' };
    return { success: true, status: resp.status, data };
  } catch (e: any) {
    return { success: false, error: e.message };
  }
}

type TRequestResult =
  | { success: true; status: number; data: { [key: string]: object | [] | number | string | any } }
  | { success: false; error: string | any };

// export async function requestJSON(url: string, options?: IModifiedRequestInit): Promise<TRequestResult> {
//   try {
//     const resp = await fetchTimeout(url, options);
//     if (resp.status >= 400) return { success: false, error: data.error };
//     const data = await resp.json();

//     //if (!data) return { success: false, error: 'Problem parsing data' };
//     return { success: true, status: resp.status, data };
//   } catch (e) {
//     return { success: false, error: e.message };
//   }
// }

export async function requestJSON(url: string, options?: IModifiedRequestInit): Promise<TRequestResult> {
  try {
    const resp = await fetchTimeout(url, options);
    try {
      const data = await resp.json();

      if (resp.status >= 400) return { success: false, error: data.message || data.error };
      if (!data) return { success: false, error: 'Problem parsing data' };
      return { success: true, status: resp.status, data };
    } catch (err) {
      if (resp.status >= 400) return { success: false, error: 'unknown error' };
      else return { success: true, status: resp.status, data: {} };
    }
  } catch (e: any) {
    return { success: false, error: e.message };
  }
}

// import React from 'react';
import { useLocation } from 'react-router-dom';

// import useStore from 'globalstate';

import { cleanURL } from 'utils/url';

export default function MaybeRedirect() {
  // const [State] = useStore();
  const location = useLocation();
  const currentPath = cleanURL(location.pathname);

  if (!currentPath) return null;
  // if (!State.course) return <Redirect to={`/?from=${currentPath}`} />;
  return null;
}

import React from 'react';
import { FaRegCalendarTimes } from 'react-icons/fa';

import { ActionIcon } from './actions-style';

type TProps = {
  onClick: Function;
  tooltip: string;
};

export function ActionFailure(props: TProps) {
  return (
    <div data-tooltip={props.tooltip}>
      <ActionIcon color="red">
        <FaRegCalendarTimes
          onClick={() => {
            props.onClick();
            //toggleModal(row);
          }}
        ></FaRegCalendarTimes>
      </ActionIcon>
    </div>
  );
}

import React, { ChangeEventHandler } from 'react';
import { FilterPropertyLookUpProps } from './types';

type FilterByPropertyPropsType = {
  label?: string;
  filterHandler: ChangeEventHandler<HTMLSelectElement>;
  data: FilterPropertyLookUpProps;
};

export const FilterByProps = (props: FilterByPropertyPropsType) => {
  const { filterHandler, data, label } = props;
  return (
    <div className="flex flex-col flex-grow">
      {label !== undefined && <p className="p-2">Filter by {label}</p>}
      <div className="mr-2">
        <select onChange={filterHandler}>
          {data.map(d => (
            <option key={d.id} value={d.id}>
              {d.shownValue}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

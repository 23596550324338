/** @jsxImportSource @emotion/react */
import styled from 'styled-components';

export const ActionIcon = styled.div`
cursor: pointer;&:hover {
    color: ${props => (props.color ? props.color : 'grey')};;
    
    transition: font-size 0.2s;
  }
`;

/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import jwtDecode from 'jwt-decode';

import useStore from 'globalstate';

import { getCookieValue } from 'utils/cookies';

import { APP_BASENAME, getAuthRefreshURL, IsTA_URL } from 'constants/app';
import Unauth from 'components/unauth';
import { requestJSON } from 'utils/request';

type AuthCookie = {
  aud: string;
  dashboard: string;
  exp: number;
  full: string;
  iat: number;
  iss: string;
  roles: string;
  sub: string;
  userId: number;
  username: string;
  name: string;
};

interface IProps {
  children: JSX.Element;
}

export default function Preloader(props: IProps) {
  const [resettingToken, setResettingToken] = useState<boolean>(false);
  const [hasPreloaded, sethasPreloaded] = useState<boolean>(false);
  const [State, Actions] = useStore();
  const { isAuthenticated, isAdmin } = State;
  const [isTA, setIsTA] = useState(false);
  const pathname = window.location.pathname;
  const TA_ROUTES = ['/', '/cms', '/courses', '/sessions', '/questions', '/exercise', '/student-management-dashboard'];
  const checkIfTA = async () => {
    const response = await requestJSON(IsTA_URL, { credentials: 'header' });
    if (!response.success) {
      return setIsTA(false);
    }
    setIsTA(true);
  };

  const resetCookie = async () => {
    setResettingToken(true);
    localStorage.setItem('lastReset', Date.now().toString());
    window.location.replace(getAuthRefreshURL(window.location.href));
  };

  const maybeResetCookie = () => {
    const lastResetRaw = localStorage.getItem('lastReset');
    if (!lastResetRaw) return resetCookie();

    const lastReset = parseInt(lastResetRaw);
    const oneMinute = 1000 * 60;
    if (lastReset < Date.now() + oneMinute) return;

    resetCookie();
  };

  const checkAuthentication = () => {
    const maybeAuthCookie = getCookieValue('border-patrol-jwt');
    if (!maybeAuthCookie) return Actions.setState({ isAuthenticated: false });

    const authInfo: AuthCookie = jwtDecode(maybeAuthCookie);
    const userDetails = {
      username: authInfo.username,
      name: authInfo.name,
      roles: authInfo.roles,
    };
    const cookieHasExpired = authInfo.exp > Date.now();
    const isAuthenticated = authInfo.username && !cookieHasExpired;
    const isAdmin = authInfo.roles.split(',').includes('admin');
    Actions.setState({ isAuthenticated, userDetails, isAdmin });
  };

  const loadState = () => {
    const course = localStorage.getItem('course');
    const exercise = localStorage.getItem('exercise');
    const exerciseCreate = localStorage.getItem('exerciseCreate');
    // const user = localStorage.getItem('user');
    Actions.setState({ course, exercise, exerciseCreate });
  };

  useEffect(() => {
    maybeResetCookie();
    checkAuthentication();
    loadState();
    checkIfTA();
    sethasPreloaded(true);
  }, []);

  if (!isAuthenticated) return <Unauth kind="unauth"></Unauth>;

  if (!isTA && !isAdmin) return <Unauth kind="not TA"></Unauth>;

  if (
    !isAdmin &&
    !TA_ROUTES.some(path => {
      if (pathname == '/') return true;
      return '/' + pathname.split('/')[1] == path;
    })
  )
    return <Unauth kind="not admin"></Unauth>;
  else {
    if (!hasPreloaded)
      return (
        <div
          css={css`
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
        >
          <img
            src={`${APP_BASENAME}/logo.svg`}
            css={css`
          width: 6vw;
          height: auto;
          @media (max-width: 768px) {
            width: 66vw;
          }
        `}
          />
        </div>
      );

    if (resettingToken)
      return (
        <div
          css={css`
        display: fixed;
        height: 100vh;
        width: 100vw;
      `}
        >
          <div
            css={css`
          height: 100vh;
          width: 100vw;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
          >
            <img
              src={`${APP_BASENAME}/logo.svg`}
              css={css`
            width: 6vw;
            height: auto;
            @media (max-width: 768px) {
              width: 66vw;
            }
          `}
            />
          </div>
        </div>
      );
  }

  return props.children;
}

import React, { useEffect, useState } from 'react';
import TimePicker from 'react-time-picker';
// import TimePicker from 'rc-time-picker';
// import 'rc-time-picker/assets/index.css';

import moment from 'moment';
type TProps = {
  defaultValue?: moment.Moment;
  onChangeTime: Function;
  [key: string]: any;
};

export function Time(props: TProps) {
  const { defaultValue, onChangeTime } = props;

  const [value, setValue] = useState(defaultValue || moment());

  const onTimeChange = (time: any) => {
    if (onChangeTime) {
      setValue(moment(time, 'HH:mm'));
      onChangeTime(time);
    }
  };

  return <TimePicker onChange={onTimeChange} value={value.toDate()} format="HH:mm" disableClock />;
}

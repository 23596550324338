/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';

type TProps = {
  Icon?: any;
  inputRef: any;
  refGenerator: any;
  label: string;
  defaultValue?: any;
  info?: string;
  name: string;
  options?: { [key: string]: any }[];
  placeholder?: string;
  error?: string;
  errors?: any;
  defaultOtherValue?: any;
  onChangeHandler?: any;
  shownValue?: string;
};

export function FormSelect(props: TProps) {
  const { onChangeHandler, shownValue = 'shownValue', defaultValue, errors, name, options, inputRef } = props;
  if (!options) return null;
  const [selectedOption, setSelectedOption] = useState<string | number>(defaultValue);
  const dropdownError = errors[`${name}`];
  const error = dropdownError?.message;
  const opts = [...options];

  const onChange = (e: any) => {
    const selected_class = e.target.value;
    if (onChangeHandler) onChangeHandler(selected_class);
    setSelectedOption(selected_class);
  };

  return (
    <div className="control column is-6">
      <div className="select">
        <label className={`radio ${error && 'is-danger'}`}>
          <select onChange={onChange} ref={inputRef} name={name} css={css`min-width:20em;`} value={selectedOption}>
            {opts.map((option: { [key: string]: any }) => {
              return (
                <option key={option.id} value={option.id}>
                  {option['shownValue']}
                </option>
              );
            })}
          </select>
        </label>
      </div>
    </div>
  );
}

import { TAQuestion } from '@univ-ai/proctor';
import { STUDENT_EXERCISE_DETAILS_URL } from 'constants/app';
import moment from 'moment';
import React, { useEffect } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { useParams } from 'react-router-dom';
import { Question, TStudentExerciseResponses } from 'types';
import { requestJSON } from 'utils/request';
type Tprops = {
  courseId: string;
  studentUserId: string;
  exerciseId: string;
  studentName: string;
};

function StudentEvaluationDetail() {
  const { courseId, studentUserId, exerciseId, studentName } = useParams<Tprops>();

  const [studentEvaluationDetail, setStudentEvaluationDetail] = React.useState<TStudentExerciseResponses>();
  const [error, setError] = React.useState('');
  //fetch api to get user details for specific course and exercise
  async function getStudentEvaluationDetail(courseId: string, studentUserId: string, exerciseId: string) {
    //fetch
    //add URL search params to URI
    const STUDENT_URL = `${STUDENT_EXERCISE_DETAILS_URL}?courseId=${courseId}&studentId=${studentUserId}&exerciseId=${exerciseId}`;
    const jsonRes = await requestJSON(STUDENT_URL, {
      method: 'GET',
      credentials: 'header',
      headers: { 'content-type': 'application/json' },
    });
    if (!jsonRes.success) {
      setError('Error fetching student evaluation details');
      return;
    }

    setStudentEvaluationDetail(jsonRes.data.responses);
  }

  useEffect(() => {
    getStudentEvaluationDetail(courseId, studentUserId, exerciseId);
  }, [courseId, studentUserId, exerciseId]);

  return (
    <div className="flex flex-row  w-full">
      <div className="flex flex-col border border-2 h-screen p-10 w-96 min-w-[35rem]">
        <div className="grid grid-cols-2 ">
          <strong>Student Name</strong>
          <span>{studentName.replace('_', ' ')}</span>

          <strong>Marks</strong>
          <span>
            {studentEvaluationDetail?.evaluation.points_scored}/{studentEvaluationDetail?.evaluation.max_points}
          </span>

          <strong>Percentage</strong>
          <span>{studentEvaluationDetail?.evaluation.percent}</span>

          <strong>Started At</strong>
          <span>{moment(studentEvaluationDetail?.startTime).format('DD MMM YY HH:mm:ss')}</span>

          <strong>Ended At</strong>
          <span>{moment(studentEvaluationDetail?.endTime).format('DD MMM YY HH:mm:ss')}</span>

          <strong>Duration</strong>
          <span>
            {moment
              .utc(moment(studentEvaluationDetail?.endTime).diff(moment(studentEvaluationDetail?.startTime)))
              .format('HH [hrs] mm [min] [and] ss [seconds]')}
          </span>

          <strong>Timeout</strong>
          <span>{studentEvaluationDetail?.endedGracefully ? 'No' : 'Yes'}</span>
        </div>
      </div>
      <div className=" max-h-screen p-8">
        {studentEvaluationDetail?.questions &&
          Object.values(studentEvaluationDetail?.questions).map((question: Question, index: number) => (
            <div className="p-8 w-full">
              <div className=" min-w-full">
                <TAQuestion question={question} />
              </div>
              <div className="grid grid-cols-10">
                <strong>Correct</strong>
                <span>
                  {question.correct ? <FaCheck className="bg-green"></FaCheck> : <FaTimes className="bg-red" />}
                </span>
                <strong>Points Scored</strong>
                <span>{question.correctResponse ? question.correctResponse.evaluation.points : 'NA'}</span>
                <strong>Award Points</strong>
                <span>{question.award_points}</span>
                <strong>User Response</strong>
                <span>
                  {question.type == 'mcq' || question.type == 'radio'
                    ? question.responses
                        .filter(res => res.locked)
                        .map(res => {
                          if (!question.options) return null;
                          const selectedOpt = [];
                          for (let i = 0; i < question.options?.length; i++) {
                            const q = question.options[i];
                            if (res.selected_option.includes(q._id)) {
                              selectedOpt.push(q.text);
                            }
                          }
                          return selectedOpt.join(', ');
                        })
                    : question.type == 'text'
                    ? question.responses.filter(res => res.locked).map(res => res.text)
                    : 'NA'}
                </span>
                <strong>Attempts</strong>
                <span>{question.responses.length}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default StudentEvaluationDetail;

import React from 'react';

type TSearchLookUpProps = {
  product?: string;
  description?: string;
  name?: string;
  cp_id?: string;
  class_name?: string;
  class_description?: string;
  class_page_link?: string;
};

type TSearchComponentProps<T> = {
  data: Array<T & TSearchLookUpProps>;
  filteredData: Function;
};

export const Search = <T,>({ data, filteredData }: TSearchComponentProps<T>) => {
  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText: string = e.target.value
      .toLowerCase()
      .trim()
      .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    let filterData: T[] = [];
    if (searchText.length > 0) {
      const regexp = new RegExp(searchText, 'gi');

      const matchingData = data.filter(value => {
        if (
          ('name' in value && value.name !== null && value.name?.toLowerCase().search(regexp) != -1) ||
          ('description' in value &&
            value.description !== null &&
            value.description?.toLowerCase().search(regexp) != -1) ||
          ('cp_id' in value && value.cp_id !== null && value.cp_id?.toLowerCase().search(regexp) != -1) ||
          ('product' in value && value.product !== null && value.product?.toLowerCase().search(regexp) != -1) ||
          ('class_name' in value &&
            value.class_name !== null &&
            value.class_name?.toLowerCase().search(regexp) != -1) ||
          ('class_description' in value &&
            value.class_description !== null &&
            value.class_description?.toLowerCase().search(regexp) != -1) ||
          ('class_page_link' in value &&
            value.class_page_link !== null &&
            value.class_page_link?.toLowerCase().search(regexp) != -1)
        )
          return true;
      });
      filterData = matchingData;
    } else {
      filterData = data;
    }
    filteredData(filterData);
  };
  return (
    <div className="flex-grow">
      <p className="p-2">Search</p>
      <div>
        <input type="text" placeholder="Search" onChange={searchHandler}></input>
      </div>
    </div>
  );
};

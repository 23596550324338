import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

interface IProps {
  value: string;
}

export default function (props: IProps) {
  return (
    <ReactMarkdown plugins={[remarkMath]} rehypePlugins={[rehypeKatex]} components={{ p: 'p' }}>
      {props.value}
    </ReactMarkdown>
  );
}

import React from 'react';
import DataTable, { ChangePage, ChangeRowsPerPage, RowState, TableColumn } from 'react-data-table-component';

type Tprops<TItem> = {
  key?: number;
  columns: TableColumn<TItem>[];
  data: TItem[];
  title?: string;
  style?: React.CSSProperties;
  pagination?: boolean;
  paginationServer?: boolean;
  paginationTotalRows?: number;
  paginationPerPage?: number;
  onChangeRowsPerPage?: ChangeRowsPerPage;
  onChangePage?: ChangePage;
  noRowsPerPage?: boolean;
  progressPending?: boolean;
  paginationResetDefaultPage?: boolean;
  showSelectableRows?: boolean;
  onSelectedRowsChange?: (selected: { allSelected: boolean; selectedCount: number; selectedRows: TItem[] }) => void;
  clearSelectedRows?: boolean;
  selectableRowSelected?: RowState<TItem>;
  conditionalRowStyles?: any;
  onRowClicked?: any;
};

// const customStyles = {
//   rows: {
//     style: {
//       minHeight: '72px', // override the row height
//     },
//   },
//   headCells: {
//     style: {
//       paddingLeft: '8px', // override the cell padding for head cells
//       paddingRight: '8px',
//       backgroundColor: '#0ea5e9',
//     },
//   },
//   cells: {
//     style: {
//       paddingLeft: '8px', // override the cell padding for data cells
//       paddingRight: '8px',
//     },
//   },
// };

export function SimpleTable<T>(props: Tprops<T>) {
  return (
    <div style={props.style}>
      <DataTable
        key={props.key}
        title={props.title}
        columns={props.columns}
        data={props.data}
        pagination={props.pagination}
        progressPending={props.progressPending}
        paginationServer={props.paginationServer}
        paginationTotalRows={props.paginationTotalRows}
        paginationPerPage={props.paginationPerPage}
        paginationComponentOptions={{
          noRowsPerPage: props.noRowsPerPage ? true : false,
        }}
        onChangePage={props.onChangePage}
        paginationResetDefaultPage={props.paginationResetDefaultPage}
        selectableRows={props.showSelectableRows}
        onSelectedRowsChange={props.onSelectedRowsChange}
        clearSelectedRows={props.clearSelectedRows}
        selectableRowSelected={props.selectableRowSelected}
        onRowClicked={props.onRowClicked}
        dense={true}
        conditionalRowStyles={props.conditionalRowStyles}
      />
    </div>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import * as Sentry from '@sentry/browser';

import App from './App';
// import reportWebVitals from './reportWebVitals';

ReactModal.setAppElement('body');

if (process.env.NODE_ENV === 'production')
  Sentry.init({ dsn: 'https://409fe32525724dce935292bd68d214af@debug.univ.ai/3' });

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

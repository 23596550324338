import React from 'react';

type Props = {
  inputRef: React.Ref<HTMLInputElement>;
  name: string;
  defaultValue: string;
  error?: string;
  disabled?: boolean;
};

export function Color(props: Props) {
  const { defaultValue, error, name, inputRef, disabled = false } = props;

  return (
    <input
      className={` input ${error ? 'is-danger' : ''}`}
      name={name}
      ref={inputRef}
      type="color"
      defaultValue={defaultValue}
      disabled={disabled}
      style={{ width: '20%', height: '50px' }}
    />
  );
}
